/**
 *
 * @param {string} name
 * @param {number} i
 * @param {string} field
 * @returns {string}
 */

export function getFieldArrayName(name, i, field) {
  return `${name}.${i}.${field}`
}

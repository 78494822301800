import { connect } from '@seedcloud/stateless'

import { SearchInput } from 'components/common/SearchInput'
import { productModule, filterQuery$ } from 'modules/product'

const ConnectedFilterInput = connect(() => ({
  filterQuery: filterQuery$,
}))(FilterInput)

const { filterProducts } = productModule

function FilterInput({ filterQuery }) {
  return (
    <SearchInput
      value={filterQuery}
      placeholder="Search by name"
      onChange={filterProducts}
    />
  )
}

export { ConnectedFilterInput as FilterInput }

import { propOr } from '@seedcloud/ramda-extra'

import { FieldGroup, Row, TextField } from 'components/company/common'

const BillingContact = ({ title, billing }) => (
  <FieldGroup title={title}>
    <Row>
      <TextField
        label="First Name"
        value={propOr(null, 'firstName', billing)}
        name="billing.firstName"
        required
      />
      <TextField
        label="Last Name"
        value={propOr(null, 'lastName', billing)}
        name="billing.lastName"
        required
      />
    </Row>
    <Row>
      <TextField
        label="Phone"
        value={propOr(null, 'phoneNumber', billing)}
        name="billing.phoneNumber"
        required
      />
      <TextField
        label="Email"
        value={propOr(null, 'email', billing)}
        name="billing.email"
        required
      />
    </Row>
  </FieldGroup>
)

export { BillingContact }

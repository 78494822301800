/* eslint-disable complexity */

import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Columns } from './Columns'
import { Rows } from './Rows'
import { Tabs } from './Tabs'

import { Spinner } from 'components/common/Spinner'
import { Table } from 'components/common/withTable'
import { JOB_STATUSES } from 'constants/jobs'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import {
  jobModule,
  jobList$,
  isLoadingJobs$,
  jobFilters$,
  selectedTab$,
} from 'modules/job'

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const { setSelectedTab, fetchJobs, filterJobs, fetchEngagedJobs } = jobModule

const ConnectedJobTable = connect(() => ({
  selectedTab: selectedTab$,
  jobList: jobList$,
  jobFilters: jobFilters$,
  isLoadingJobs: isLoadingJobs$,
}))(JobTable)

function JobTable({ selectedTab, jobList, jobFilters, isLoadingJobs }) {
  const {
    sortBy,
    paging: { startRow, next },
    limit,
  } = jobFilters
  const { role } = useIdentity()
  const history = useHistory()

  useLayoutEffect(() => {
    switch (selectedTab) {
      case 'published':
        filterJobs(null, {
          statuses: [JOB_STATUSES.PUBLISHED],
          sortBy: 'publishedAt',
          sortOrder: 'asc',
        })
        break
      case 'engaged':
        fetchEngagedJobs(null, {
          status: JOB_STATUSES.ENGAGED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
        break
      case 'delivered':
        fetchEngagedJobs(null, {
          status: JOB_STATUSES.DELIVERED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
        break
      case 'in_review':
        fetchEngagedJobs(null, {
          status: JOB_STATUSES.IN_REVIEW,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
        break
      case 'completed':
        fetchEngagedJobs(null, {
          status: JOB_STATUSES.COMPLETED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
        break
      case 'cancelled':
        fetchEngagedJobs(null, {
          status: JOB_STATUSES.CANCELLED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
        break
      case 'disputed':
        fetchEngagedJobs(null, {
          status: JOB_STATUSES.DISPUTED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
        break
      default:
        break
    }
  }, [])

  const columns = Columns(selectedTab, role)

  const data = Rows(jobList)

  const onSort = (sortBy, sortOrder) => filterJobs(null, { sortBy, sortOrder })

  const onTurnPage = ({ turnNext }) => {
    if (selectedTab === 'published') {
      fetchJobs(null, { turnPage: true, turnNext })
    } else {
      fetchEngagedJobs(null, {
        turnPage: true,
        turnNext,
      })
    }
  }

  const onRowClicked = (jobId) => history.push(`/job/${jobId}`)

  return (
    <Container>
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        filterJobs={filterJobs}
        fetchEngagedJobs={fetchEngagedJobs}
      />

      {isLoadingJobs && (
        <Spinner size={24} thickness={4} style={{ margin: 'auto 0' }} />
      )}

      <Table
        columns={columns}
        data={data}
        onClick={onRowClicked}
        sortBy={sortBy}
        startRow={startRow}
        next={next}
        limit={limit}
        onSort={onSort}
        turnPage={onTurnPage}
        domain={'jobs'}
      />
    </Container>
  )
}

export { ConnectedJobTable as JobTable }

import { createTheme, ThemeProvider } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import { isNotNilOrEmpty, isNotNil, noop } from '@seedcloud/ramda-extra'
import { useField } from 'formik'

import { ErrorMessage } from './Form/ErrorMessage'
import { Label, NewLabel } from './Form/Label'

import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('flex-1 flex flex-column mx-3'), ({ newStyle }) => ({
  gap: newStyle ? '.5em' : undefined,
  marginLeft: newStyle ? 0 : undefined,
  marginRight: newStyle ? 0 : undefined,
}))

const Input = styled(TextField, { shouldForwardProp: (p) => p !== 'inputStyles' })(
  apply(
    'bg-grey-100 text-lg text-black border-0 border-b-2 border-grey rounded-md px-3 py-2'
  ),
  ({ disabled }) =>
    disabled ? apply('bg-gray-400 border-gray-700 text-gray-600') : {},
  ({ readOnly }) =>
    readOnly
      ? apply('bg-gray-400 border-gray-700', {
          '& .MuiInputBase-input': apply('text-gray-800'),
        })
      : {},
  ({ inputStyles }) =>
    apply('', {
      '& .MuiInputBase-root': apply('', {
        lineHeight: 'inherit',
        fontFamily: 'unset',
      }),
      '& .MuiInputBase-input': apply('text-lg py-0', {
        height: 'inherit',
        ...inputStyles,
      }),
      '& .MuiIconButton-edgeEnd': {
        padding: '6px',
        marginRight: '-8px',
      },
    }),
  {
    '& .MuiFormHelperText-root': apply('hidden'),
    '& .MuiInputBase-root::before': apply('hidden'),
    '& .MuiInputBase-root::after': apply('hidden'),
  }
)

const NewInput = styled(TextField, { shouldForwardProp: (p) => p !== 'inputStyles' })(
  apply('border text-black rounded-lg w-full', {
    border: '1px solid #CCCCCC',
    padding: '.5rem 1rem',
    // NOTE: The :read-only pseudo-selector in CSS
    //       will match both `readonly` and `disabled` elements.
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
    },
  }),
  ({ disabled }) =>
    disabled ? apply('bg-gray-400 border-gray-700 text-gray-600') : {},
  ({ readOnly }) =>
    readOnly
      ? apply('bg-gray-400 border-gray-700', {
          '& .MuiInputBase-input': apply('text-gray-800'),
        })
      : {},
  ({ inputStyles }) =>
    apply('', {
      '& .MuiInputBase-root': apply('', {
        lineHeight: 'inherit',
        fontFamily: 'unset',
      }),
      '& .MuiInputBase-input': apply('py-0', {
        height: 'inherit',
        fontSize: '0.9375rem',
        ...inputStyles,
      }),
      '& .MuiIconButton-edgeEnd': {
        padding: '6px',
        marginRight: '-8px',
      },
    }),
  {
    '& .MuiFormHelperText-root': apply('hidden'),
    '& .MuiInputBase-root::before': apply('hidden'),
    '& .MuiInputBase-root::after': apply('hidden'),
  }
)

const datePickerTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      day: {
        '&:focus': apply('text-white'),
      },
      daySelected: {
        ...apply('text-white'),
        '&:hover': {
          backgroundColor: '#9E2AA4',
        },
      },
    },
  },
})

// eslint-disable-next-line complexity
function DateField({
  disabled,
  readOnly,
  name,
  id,
  label,
  labelPosition,
  containerProps,
  pickerProps,
  minDate,
  rootStyle,
  inputStyles,
  onChange = noop,
  testId,
  withTimePicker = false,
  placeholder,
  required,
  newStyle,
  ...props
}) {
  const [{ value }, { touched, error }, { setValue: setFieldValue, setTouched }] =
    useField({
      name,
    })

  function handleChange(dateObject, dateString) {
    setTouched(true)
    setFieldValue(dateObject)
    onChange(dateObject, dateString)
  }

  const Picker = withTimePicker ? DateTimePicker : DatePicker

  const overridePlaceholder = (inputProps = {}) => {
    if (isNotNil(placeholder)) {
      return { ...inputProps, placeholder }
    }

    return inputProps
  }

  const LabelComp = newStyle ? NewLabel : Label
  const PickerInputComp = newStyle ? NewInput : Input

  return (
    <Root style={rootStyle} newStyle={newStyle}>
      <ThemeProvider theme={datePickerTheme}>
        {isNotNilOrEmpty(label) && (
          <LabelComp htmlFor={name}>
            {label} {required && '*'}
          </LabelComp>
        )}

        <Picker
          minDate={minDate}
          value={value ?? null}
          disabled={disabled || readOnly}
          inputFormat={withTimePicker ? 'dd/MM/yyyy p' : 'dd/MM/yyyy'}
          onChange={handleChange}
          renderInput={({ inputProps, ...params }) => (
            <PickerInputComp
              {...params}
              inputStyles={inputStyles}
              onBlur={() => setTouched(true)}
              data-test-id={testId}
              inputProps={overridePlaceholder(inputProps)}
              id={id ?? name}
            />
          )}
          {...props}
        />
      </ThemeProvider>
      {error && touched && <ErrorMessage>{error}</ErrorMessage>}
    </Root>
  )
}

export { DateField }

import { connect } from '@seedcloud/stateless'
import { saveAs } from 'file-saver'
import { useFormikContext } from 'formik'
import { useState } from 'react'

import { DeleteFileModal } from 'components/common/DeleteFileModal'
import { FilePanel } from 'components/common/FilePanel'
import { DOCUMENT_TYPES } from 'constants/documents'
import { styled, apply } from 'lib/styled'
import { documentModule, isOperatingFile$ } from 'modules/document'
import { useFileModal } from 'utils/hooks'

const Container = styled.div(apply('flex flex-row my-2 items-center justify-between'))

const ConnectedJobDocuments = connect(() => ({
  isOperatingFile: isOperatingFile$,
}))(JobDocuments)

function JobDocuments({ type, downloadJobDocument, jobReference, isOperatingFile }) {
  const { setFieldError } = useFormikContext()
  const [operatingFile, setOperatingFile] = useState(null)
  const [multiOperatingDocumentType, setMultiOperatingDocumentType] = useState(null)
  const [deleteFileModal, setDeleteFileModal] = useFileModal()

  const onFileDeleted = ({ id: selectedDocId, fileName }) => {
    setDeleteFileModal({ isOpened: true, fileName, fileId: selectedDocId })
  }
  const onFileDownload = async ({ id: selectedDocId, fileName }) => {
    setOperatingFile(selectedDocId)
    saveAs(await downloadJobDocument(selectedDocId), fileName)
    setOperatingFile(null)
  }

  const onMultiDownload = async (documents, fileType) => {
    setMultiOperatingDocumentType(fileType)
    const files = await Promise.all(
      documents.map(async ({ id, fileName }) => {
        const data = await downloadJobDocument(id)
        return {
          fileName,
          data,
        }
      })
    )
    await Promise.all(files.map((file) => saveAs(file.data, file.fileName)))
    setMultiOperatingDocumentType(null)
  }

  const onFileUpload = async ([file], fieldName) => {
    setMultiOperatingDocumentType(DOCUMENT_TYPES.JOB_ASSET)
    await documentModule.uploadJobDocument(null, {
      file,
      documentType: DOCUMENT_TYPES.JOB_ASSET,
    })
    setFieldError(fieldName, undefined)
    setMultiOperatingDocumentType(null)
  }

  const onRejected = (fieldName, errorMsg) => setFieldError(fieldName, errorMsg)

  return (
    <Container>
      {type === 'pilot' && (
        <FilePanel
          entity="Pilot"
          containerStyle={apply('flex-1')}
          type="download"
          documentType={DOCUMENT_TYPES.JOB_ASSET}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          name="documents.uploadedByPilot"
          onDownload={onFileDownload}
          onMultiDownload={onMultiDownload}
          onDelete={onFileDeleted}
          itemPrefix={jobReference}
          isLoading={isOperatingFile}
        />
      )}
      {type === 'staff' && (
        <FilePanel
          entity="Staff"
          containerStyle={apply('flex-1')}
          type="upload"
          name="documents.uploadedByOrganization"
          documentType={DOCUMENT_TYPES.JOB_ASSET}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          onMultiDownload={onMultiDownload}
          onDelete={onFileDeleted}
          onUpload={onFileUpload}
          onDownload={onFileDownload}
          onRejected={onRejected}
          itemPrefix={jobReference}
          isLoading={isOperatingFile}
          canDelete={false}
          canUpload={false}
        />
      )}
      <DeleteFileModal
        isOpen={deleteFileModal.isOpened}
        setIsOpen={(open) => setDeleteFileModal({ isOpened: open })}
        fileName={deleteFileModal.fileName}
        deleteHandler={async () => {
          setOperatingFile(deleteFileModal.fileId)
          await documentModule.deleteJobDocument(deleteFileModal.fileId)
          setOperatingFile(null)
        }}
      />
    </Container>
  )
}

export { ConnectedJobDocuments as JobDocuments }

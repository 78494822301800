import CONSTANTS from '@seedcloud/constants'
import { isNil, values, prop, isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { PersonalDetails } from './PersonalDetails'
import { ServiceArea } from './ServiceArea'
import { SkillsAndAbilities } from './SkillsAndAbilities'

import { Button } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { LICENCE_TYPES } from 'constants/licenceTypes'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'
import { companyModule, inspectedPilot$ } from 'modules/legacy-company'
import { mapboxResultSchema } from 'utils/hooks/preProcessResults'

const { object, string, array, number, date } = Validator
const optionalString = (label) => string().optional().label(label)
const requiredString = (label) => string().required().label(label)

const validationSchema = object({
  userDetails: object({
    firstName: requiredString('First name'),
    lastName: requiredString('Last name'),
    email: requiredString('Email').email('Not a valid email address'),
    phoneNumber: requiredString('Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Not a valid phone number'
    ),
  }),
  pilotDetails: object().shape(
    {
      address: mapboxResultSchema.required(),
      licenceType: requiredString('Licence type').oneOf(values(LICENCE_TYPES)),
      licenceNumber: requiredString('Licence number'),
      licenceExpiredAt: date().min(new Date()).label('Licence expiry').required(),
      insuranceNumber: string().when('insuranceExpiredAt', {
        is: isNotNilOrEmpty,
        then: requiredString('Insurance number'),
        otherwise: optionalString('Insurance number'),
      }),
      insuranceExpiredAt: date()
        .min(new Date())
        .label('Insurance expiry')
        .when('insuranceNumber', {
          is: isNotNilOrEmpty,
          then: date().min(new Date()).required().nullable(),
          otherwise: date().min(new Date()).optional().nullable(),
        }),
      serviceCategories: array()
        .of(string().oneOf(SERVICE_CATEGORIES.map(prop('value'))))
        .min(1)
        .label('Service categories'),
      serviceRadius: number().required().label('Radius'),
    },
    [['insuranceNumber', 'insuranceExpiredAt']]
  ),
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const BackButton = styled(Button)(apply('bg-transparent shadow-none text-grey-400 p-0'))

const BackButtonContainer = styled.div(apply('pt-4'))

const ConnectedPilotDetails = connect(() => ({
  inspectedPilot: inspectedPilot$,
}))(PilotDetails)

function PilotDetails({ isInspectingPilot, inspectedPilot, ...props }) {
  const { id } = useParams()
  // useEffect(() => {
  //   pilotModule.inspectPilot(id)
  // }, [id])

  const [isEditing, setIsEditing] = useState(false)

  if (isNil(inspectedPilot)) return null

  const {
    userDetails,
    companyName,
    licenceType,
    licenceNumber,
    licenceExpiredAt,
    insuranceNumber = '',
    insuranceExpiredAt = null,
    serviceCategories,
    isBanned,
    serviceRadius,
  } = inspectedPilot

  const { firstName, lastName, phoneNumber, email, avatar } = userDetails

  const address = {
    geometry: inspectedPilot.addressLocation,
    // eslint-disable-next-line camelcase
    place_name: inspectedPilot.address,
  }

  return (
    <Container {...props}>
      <FormProvider
        id="pilotDetails"
        enableReinitialize
        initialValues={{
          userDetails: {
            firstName,
            lastName,
            phoneNumber,
            email,
            avatarSrc: avatar,
          },
          pilotDetails: {
            companyName,
            address,
            isBanned,
            licenceType,
            licenceNumber,
            licenceExpiredAt,
            insuranceNumber,
            insuranceExpiredAt,
            serviceCategories,
            serviceRadius,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={async () => {
          setIsEditing(false)
        }}
      >
        <Loader
          loading={isInspectingPilot}
          renderPlaceholder={
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          }
        >
          <Scrollable>
            <Form>
              <BackButtonContainer>
                <BackButton
                  onClick={() => companyModule.inspectCompanyPilot(null)}
                >{`< Back`}</BackButton>
              </BackButtonContainer>
              <PersonalDetails
                id={id}
                avatarSrc={avatar}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />

              <SkillsAndAbilities isEditing={isEditing} />

              <ServiceArea isEditing={isEditing} />
            </Form>
          </Scrollable>
        </Loader>
      </FormProvider>
    </Container>
  )
}

export { ConnectedPilotDetails as PilotDetails }

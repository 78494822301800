/* eslint-disable complexity */

import { connect } from '@seedcloud/stateless'
import { useFormikContext as useFormContext } from 'formik'

import { Button as ButtonBase } from 'components/common/Button'
import { JOB_STATUSES } from 'constants/jobs'
import { apply, styled } from 'lib/styled'
import toaster from 'lib/toaster'
import { isFinalizingJob$, jobModule, inspectedJob$ } from 'modules/job'

const ActionContainer = styled.div(apply('my-8 flex justify-center'))

const btnStyle = apply('w-12 py-4 px-4 flex items-center justify-center')

const Button = styled(ButtonBase)(btnStyle)

const ConnectedActions = connect(() => ({
  isFinalizingJob: isFinalizingJob$,
  inspectedJob: inspectedJob$,
}))(Actions)

function Actions({ inspectedJob = {} }) {
  const {
    values: {
      jobInfo: {
        startedAt,
        finishedAt,
        equipmentUsed,
        equipmentFee,
        calloutFee,
        marketFee,
      },
      documents: { uploadedByPilot },
    },
  } = useFormContext()

  switch (inspectedJob.status) {
    case JOB_STATUSES.PUBLISHED:
      return (
        <ActionContainer>
          <Button type="button" onClick={async () => jobModule.engageJob()}>
            Engage Job
          </Button>
        </ActionContainer>
      )
    case JOB_STATUSES.ENGAGED:
      return (
        <ActionContainer>
          <Button
            type="button"
            onClick={async () =>
              jobModule.updateJob(inspectedJob.id, {
                status: JOB_STATUSES.DELIVERED,
                jobInfo: {},
                contactInfo: {},
              })
            }
          >
            Deliver Job
          </Button>
        </ActionContainer>
      )
    case JOB_STATUSES.DELIVERED:
      return (
        <ActionContainer>
          <Button
            type="button"
            onClick={async () => {
              if (uploadedByPilot.length === 0) {
                toaster.error(
                  'Please upload the deliverables in Job Assets before submit for review'
                )
                return
              }

              jobModule.updateJob(inspectedJob.id, {
                status: JOB_STATUSES.IN_REVIEW,
                jobInfo: {
                  startedAt,
                  finishedAt,
                  equipmentUsed,
                  equipmentFee,
                  calloutFee,
                  marketFee,
                },
              })
            }}
          >
            Submit for review
          </Button>
        </ActionContainer>
      )
    default:
      return null
  }
}

export { ConnectedActions as Actions }

/* eslint-disable no-unused-vars */
import { connect } from '@seedcloud/stateless'
import { FieldArray } from 'formik'
import { useContext } from 'react'

import { Button } from 'components/common/Button'
import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { DocumentField, EmptyFileItem, FileItem } from 'components/company/common'
import { TrashIcon } from 'components/icons'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { companyModule, isUploading$ } from 'modules/company'
import { getDocumentNumbers } from 'utils/getDocumentNumbering'

const DocumentContainer = styled.div(apply('flex flex-column'), {
  flex: '1',
  gap: '1rem',
})

const ExtraActionContainer = styled.div(apply('flex'), {
  gap: '1rem',
})

const ContentContainer = styled.div(apply('flex-1'))

const ExtraButton = styled(Button)(({ isFirstElement, theme }) => ({
  aspectRatio: '1 / 1',
  padding: 0,
  width: 40,
  height: 40,
  marginTop: isFirstElement ? '28px' : '0',
  background: isFirstElement ? theme.colors.primary : '#555555',
  color: 'white',
}))

const Document = ({ values, isUploading }) => {
  const { editing } = useContext(FormLayoutContext)

  const {
    user: { sub: auth0UserId },
  } = useIdentity()

  const handleUploadDocument = async (files, currentFiles) => {
    try {
      const entities = await companyModule.uploadAviationDocuments(null, {
        files,
        auth0UserId,
      })

      const documentNumber = getDocumentNumbers(currentFiles)

      return files.map((_, idx) => ({
        name: `Document ${documentNumber()}`,
        url: entities[idx].entity.fileName,
        id: entities[idx].entity.id,
      }))
    } catch (error) {
      return []
    }
  }

  return (
    <DocumentContainer>
      <ExtraActionContainer>
        <ContentContainer>
          <DocumentField
            label="Supporting Documents"
            name="documents"
            tooltip="Upload license, insurance, and other supporting documents here"
            isUploading={isUploading}
            handleOnFileDrop={handleUploadDocument}
          />
        </ContentContainer>
      </ExtraActionContainer>
      <FieldArray
        name="documents"
        render={(arrayHelpers) => (
          <>
            {values.length > 0 ? (
              values.map(({ url, id }, idx) => (
                <ExtraActionContainer key={idx}>
                  <ContentContainer>
                    <FileItem
                      type="file"
                      name={`documents.${idx}`}
                      url={url}
                      editing={editing}
                      handleClick={async () => {
                        const file = await companyModule.downloadAviationDocuments(id)
                        window.open(URL.createObjectURL(file))
                      }}
                    />
                  </ContentContainer>
                  {editing ? (
                    <ExtraButton
                      type="button"
                      onClick={() => {
                        arrayHelpers.remove(idx)
                      }}
                    >
                      <TrashIcon />
                    </ExtraButton>
                  ) : null}
                </ExtraActionContainer>
              ))
            ) : (
              <EmptyFileItem type="file" />
            )}
          </>
        )}
      />
    </DocumentContainer>
  )
}

const ConnectedDocument = connect(() => ({
  isUploading: isUploading$,
}))(Document)

export { ConnectedDocument as Document }

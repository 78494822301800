import { baseApi } from 'lib/http-client'

const OrganizationService = {
  async read(orgId, companyId) {
    return baseApi
      .get(`organizations/${orgId}`, {
        searchParams: {
          companyId,
        },
      })
      .json()
  },
  async update(orgId, companyId, payload) {
    return baseApi
      .patch(`organizations/${orgId}`, {
        json: payload,
        searchParams: {
          companyId,
        },
      })
      .json()
  },
}

export { OrganizationService }

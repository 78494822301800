import { useFormikContext } from 'formik'

import { Button } from 'components/common/Button'
import { FieldGroup, Row, TextField, SelectField } from 'components/company/common'
import { SUPPLIER_TYPES_DISPLAY_NAMES } from 'constants/supplier'

const Details = ({ title, setEditing, editing, values }) => {
  const { setFieldValue } = useFormikContext()

  const pilotServiceOptions = Object.entries(SUPPLIER_TYPES_DISPLAY_NAMES).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  return (
    <FieldGroup
      title={title}
      extra={!editing ? <Button onClick={() => setEditing(true)}>Edit</Button> : null}
    >
      <Row>
        <TextField
          label="Company Name"
          value={values.companyName}
          name="companyName"
          required
        />
        <TextField label="ABN" value={values.abn} name="abn" required />
      </Row>
      <Row>
        <TextField
          label="Phone"
          value={values.phoneNumber}
          name="phoneNumber"
          required
        />
        <TextField label="Website" value={values.website} name="website" required />
      </Row>
      <Row>
        <TextField label="Address" value={values.address} name="address" required />
      </Row>
      <Row>
        <SelectField
          disabled={true}
          label="Supplier Type"
          value={SUPPLIER_TYPES_DISPLAY_NAMES[values.supplierType]}
          name="supplierType"
          options={pilotServiceOptions}
          emptyOption={{ value: undefined, label: '' }}
          onChange={({ value }) => {
            setFieldValue('supplierType', value)
          }}
          isSearchable={false}
          required
        />
      </Row>
    </FieldGroup>
  )
}

export { Details }

import {
  map,
  omit,
  identity,
  ifElse,
  isArray,
  all,
  isEmpty,
  filter,
  isNotNilOrEmpty,
  values,
} from '@seedcloud/ramda-extra'

const filterNonEmptyObjects = filter(isNotNilOrEmpty)

/**
 * Filters out all empty fields from the object includes within arrays.
 *
 * @param {T} payload - The object to filter.
 * @returns {T|null} - The filtered object or null if all fields are empty.
 */
export const checkAndFilterEmptyFields = (payload) =>
  filterNonEmptyObjects(
    map(
      ifElse(
        isArray,
        (x) => {
          const mapToUndefined = map(
            ifElse(
              (x) => all(isEmpty, values(x)),
              () => undefined,
              identity
            )
          )
          const res = filterNonEmptyObjects(mapToUndefined(x))
          return res
        },
        identity
      ),
      payload
    )
  )

/**
 * Transforms an object or array by removing the `_id` property from all objects within arrays.
 * If the input is an array, each object within the array is transformed.
 * If the input is an object, each array property within the object is transformed.
 * @template T
 * @param {T} payload - The input object or array to transform.
 * @returns {T} - A new object or array with `_id` property removed from all objects within arrays.
 *
 */
export const transformArrayFields = (payload) => {
  const nonEmptyPayload = checkAndFilterEmptyFields(payload)

  if (!nonEmptyPayload.links) {
    nonEmptyPayload.links = []
  }

  if (!nonEmptyPayload.documents) {
    nonEmptyPayload.documents = []
  }

  return map(ifElse(isArray, map(omit(['_id'])), identity), nonEmptyPayload)
}

export const transformEquipmentFields = (payload) => {
  const nonEmptyPayload = checkAndFilterEmptyFields(payload)

  if (!nonEmptyPayload.equipments) {
    nonEmptyPayload.equipments = []
  }

  return map(ifElse(isArray, map(omit(['_id'])), identity), nonEmptyPayload)
}

/* eslint-disable import/no-cycle */
import CONSTANTS from '@seedcloud/constants'
import { omit } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useLayoutEffect, useState } from 'react'
import * as Validator from 'yup'

import { BillingContact } from './Fields/BllingContact'
import { CommercialContact } from './Fields/CommercialContact'
import { Details } from './Fields/Details'
import { HeroPilotContact } from './Fields/HeroPilotContact'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { CompanyLayout, FormAction } from 'components/company/common'
import { SUPPLIER_TYPES } from 'constants/supplier'
import {
  companyDetail$,
  companyModule,
  invitationStatus$,
  isInspectingCompany$,
  isSubmitting$,
} from 'modules/company'
import { inspectedOrganization$, isInspectingOrganization$ } from 'modules/organization'

const { object, string } = Validator
const requiredString = (label) => string().required().label(label)
const optionalString = (label) => string().optional().label(label)

const validationSchema = object({
  companyName: requiredString('Company Name'),
  abn: requiredString('ABN').matches(/^\d+$/, 'ABN should only contain number'),
  phoneNumber: requiredString('Phone number').matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64213112)'
  ),
  website: requiredString('Company Website').url(
    'Please input full url (e.g https://example.com)'
  ),
  address: requiredString('Company Address'),
  commercial: object().shape({
    firstName: requiredString('Commercial Contact First Name'),
    lastName: requiredString('Commercial Contact Last Name'),
    phoneNumber: requiredString('Commercial Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString('Commercial Email').email('Not a valid email address'),
  }),
  billing: object().shape({
    firstName: requiredString('Billing Contact First Name'),
    lastName: requiredString('Billing Contact Last Name'),
    phoneNumber: requiredString('Billing Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Please include a country code (e.g +64213112)'
    ),
    email: requiredString('Billing Email').email('Not a valid email address'),
  }),
  heroPilot: object().when('supplierType', {
    is: (value) => value === SUPPLIER_TYPES.PROFESSIONAL,
    then: object().shape({
      firstName: optionalString('Hero Pilot First Name'),
      lastName: optionalString('Hero Pilot Last Name'),
      phoneNumber: optionalString('Please include a country code (e.g +64213112)'),
      email: optionalString('Hero Pilot Emil'),
    }),
    otherwise: object().shape({
      firstName: requiredString('Hero Pilot First Name'),
      lastName: requiredString('Hero Pilot Last Name'),
      phoneNumber: requiredString('Hero Pilot Phone number').matches(
        CONSTANTS.REGEX.E164_PHONE_FORMAT,
        'Please include a country code (e.g +64213112)'
      ),
      email: requiredString('Hero Pilot Emil'),
    }),
  }),
})

const CompanyDetail = ({
  isInspectingCompany,
  isInspectingOrganization,
  isSubmitting,
  companyDetail = {},
  organization = {},
  status,
}) => {
  useLayoutEffect(() => {
    companyModule.inspectCompany()
  }, [])

  const [editing, setEditing] = useState(false)

  const {
    companyName,
    abn,
    website,
    address,
    phoneNumber,
    commercial,
    billing,
    heroPilot,
    supplierType,
  } = companyDetail

  const initialValues = {
    companyName,
    abn,
    phoneNumber,
    website,
    address,
    supplierType,
    commercial,
    billing,
    heroPilot,
  }

  const loading = isInspectingCompany && isInspectingOrganization

  const handleSubmit = async (values) => {
    const payload = omit(['supplierType'], values)
    if (values.supplierType === SUPPLIER_TYPES.PROFESSIONAL) {
      payload.heroPilot = undefined
    }
    await companyModule.updateCompany(null, payload)
    setEditing(false)
  }

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm, values }) => (
          <CompanyLayout
            loading={loading}
            status={status}
            type={supplierType}
            company={organization.name ?? ''}
            footer={
              editing && (
                <FormAction
                  loading={isSubmitting}
                  okText="Update"
                  loadingText="Updating..."
                  onCancel={() => {
                    setEditing(false)
                    resetForm()
                  }}
                />
              )
            }
          >
            <Details
              title="Company Details"
              editing={editing}
              setEditing={setEditing}
              values={{
                companyName,
                abn,
                website,
                address,
                phoneNumber,
                supplierType,
              }}
            />
            <CommercialContact title="Commercial Contact" commercial={commercial} />
            <BillingContact title="Billing Contact" billing={billing} />
            {values.supplierType !== SUPPLIER_TYPES.PROFESSIONAL ? (
              <HeroPilotContact title="Hero Pilot" heroPilot={heroPilot} />
            ) : null}
          </CompanyLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

const ConnectedCompanyDetail = connect(() => ({
  companyDetail: companyDetail$,
  isInspectingCompany: isInspectingCompany$,
  isSubmitting: isSubmitting$,
  status: invitationStatus$,
  organization: inspectedOrganization$,
  isInspectingOrganization: isInspectingOrganization$,
}))(CompanyDetail)

export { ConnectedCompanyDetail as CompanyDetail }

export const getDocumentNumbers = (files) => {
  let number = 0
  return () => {
    do {
      number += 1
      // eslint-disable-next-line no-loop-func
    } while (files.find((file) => file.name === `Document ${number}`))
    return number
  }
}

import { Route, Switch } from 'react-router-dom'

import { ChangePassword } from 'components/claim/ChangePassword'
import { ClaimCompany } from 'components/claim/ClaimCompany'
import { ClaimedCompany } from 'components/claim/ClaimedCompany'
import { NotFoundPage } from 'components/common/NotFoundPage'

const ClaimCompanyManager = () => (
  <Switch>
    <Route exact path="/claim/:token" component={ClaimCompany} />
    <Route exact path="/claim/:token/change-password" component={ChangePassword} />
    <Route exact path="/claim/:token/claimed" component={ClaimedCompany} />
    <Route component={NotFoundPage} />
  </Switch>
)

export { ClaimCompanyManager }

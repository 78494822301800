import { isNil } from '@seedcloud/ramda-extra'

import { Button as ButtonBase } from 'components/common/Button'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('justify-end flex m-4 mr-0 items-center'))
const Button = styled(ButtonBase)(apply('mx-1 justify-end flex items-center'))

export function CustomPagination({
  next,
  startRow,
  limit,
  goToNextPage,
  goToPreviousPage,
}) {
  const canNextPage = !isNil(next)
  const canPreviousPage = next !== limit && (!isNil(next) || !isNil(startRow))

  return (
    <Container>
      <Button onClick={() => goToPreviousPage()} disabled={!canPreviousPage}>
        {'<'}
      </Button>
      <Button onClick={() => goToNextPage()} disabled={!canNextPage}>
        {'>'}
      </Button>
    </Container>
  )
}

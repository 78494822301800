import { reduceBy, prop, path, pipe, mergeRight } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { saveAs } from 'file-saver'
import { useFormikContext as useFormContext } from 'formik'
import { useLayoutEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'

import { ComplianceDocumentsPanel } from './ComplianceDocumentsPanel'

import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg'
import { DeleteFileModal } from 'components/common/DeleteFileModal'
import { DOCUMENT_TYPES } from 'constants/documents'
import { apply, styled } from 'lib/styled'
import { documentModule, jobDocumentList$, isOperatingFile$ } from 'modules/document'
import { useFileModal } from 'utils/hooks'

const Header = styled.div(apply('flex flex-row py-6 items-center'))
const Title = styled.h2(apply('m-0 text-xl font-light mr-1'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const Connected = connect(() => ({
  isOperatingFile: isOperatingFile$,
  jobDocumentList: jobDocumentList$,
}))(ComplianceSection)

const { uploadJobDocument, downloadJobDocument, deleteJobDocument } = documentModule

function ComplianceSection({ isOperatingFile, jobDocumentList }) {
  const [deleteFileModal, setDeleteFileModal] = useFileModal()
  const { touched, setFieldValue, setFieldTouched, setFieldError } = useFormContext()
  const [operatingFile, setOperatingFile] = useState(null)
  const [multiOperatingDocumentType, setMultiOperatingDocumentType] = useState(null)
  const isComplianceDocumentsTouched = path(['jobInfo', 'complianceDocuments'], touched)

  // we hydrate/update the compliance documents here instead of other parent components because we don't want the entire form to subscribe to the document list, which will make the form re-render when a document is uploaded/deleted, thereby resetting other form fields while creating/editing a job
  useLayoutEffect(() => {
    const complianceDocuments = pipe(
      reduceBy((acc, doc) => acc.concat(doc), [], prop('documentType')),
      mergeRight({
        [DOCUMENT_TYPES.JOB_JSEA]: [],
        [DOCUMENT_TYPES.JOB_TERMS]: [],
        [DOCUMENT_TYPES.JOB_CASA]: [],
      })
    )(jobDocumentList)

    setFieldValue('jobInfo.complianceDocuments', complianceDocuments, false)
    if (isComplianceDocumentsTouched)
      setTimeout(() => setFieldTouched('jobInfo.complianceDocuments', true))
  }, [setFieldValue, jobDocumentList])

  const [docType, setDocType] = useState('')

  const onUpload =
    (documentType) =>
    async ([file]) => {
      setMultiOperatingDocumentType(documentType)
      setDocType(documentType)
      await uploadJobDocument(null, {
        file,
        documentType,
      })
      setMultiOperatingDocumentType(null)
    }

  const onDelete = ({ id: selectedDocId, fileName }) => {
    setDeleteFileModal({ isOpened: true, fileName, fileId: selectedDocId })
  }
  const onDownload = async ({ id: selectedDocId, fileName }) => {
    setOperatingFile(selectedDocId)
    saveAs(await downloadJobDocument(selectedDocId), fileName)
    setOperatingFile(null)
  }

  const onMultiDownload = async (documents, fileType) => {
    setMultiOperatingDocumentType(fileType)
    const files = await Promise.all(
      documents.map(async ({ id, fileName }) => {
        const data = await downloadJobDocument(id)
        return {
          fileName,
          data,
        }
      })
    )
    await Promise.all(files.map((file) => saveAs(file.data, file.fileName)))
    setMultiOperatingDocumentType(null)
  }

  const onRejected = (fieldName, errorMsg) => setFieldError(fieldName, errorMsg)

  return (
    <>
      <Header>
        <Title>Job Files</Title>
        <span>
          <ExclamationCircleIcon
            {...apply('h-1 w-1', {
              stroke: '#334155',
            })}
            data-tip="Compliance documents are only editable before a job is published. <br /> Please create a new job if you wish to do so."
            data-type="info"
          />
        </span>
      </Header>
      <Row style={apply('justify-between')}>
        <ComplianceDocumentsPanel
          containerStyle={apply('flex-grow-1 w-0 mr-10')}
          fileType="Staff Job Materials"
          name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_JSEA}`}
          documentType={DOCUMENT_TYPES.JOB_JSEA}
          onUpload={onUpload(DOCUMENT_TYPES.JOB_JSEA)}
          onDelete={onDelete}
          onDownload={onDownload}
          onMultiDownload={onMultiDownload}
          onRejected={onRejected}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          isLoading={
            isOperatingFile && docType.localeCompare(DOCUMENT_TYPES.JOB_JSEA) === 0
          }
          testId="job-jsea-panel"
          canDelete={false}
        />
        <ComplianceDocumentsPanel
          containerStyle={apply('flex-grow-1 w-0 mr-10')}
          fileType="Pilot Attachments"
          name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_TERMS}`}
          documentType={DOCUMENT_TYPES.JOB_TERMS}
          onUpload={onUpload(DOCUMENT_TYPES.JOB_TERMS)}
          onDelete={onDelete}
          onDownload={onDownload}
          onMultiDownload={onMultiDownload}
          onRejected={onRejected}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          isLoading={
            isOperatingFile && docType.localeCompare(DOCUMENT_TYPES.JOB_TERMS) === 0
          }
          testId="job-terms-panel"
          canDelete={false}
        />
        <ComplianceDocumentsPanel
          containerStyle={apply('flex-grow-1 w-0')}
          fileType="Regulation Documents"
          name={`jobInfo.complianceDocuments.${DOCUMENT_TYPES.JOB_CASA}`}
          documentType={DOCUMENT_TYPES.JOB_CASA}
          onUpload={onUpload(DOCUMENT_TYPES.JOB_CASA)}
          onDelete={onDelete}
          onDownload={onDownload}
          onMultiDownload={onMultiDownload}
          onRejected={onRejected}
          operatingFile={operatingFile}
          multiOperatingDocumentType={multiOperatingDocumentType}
          isLoading={
            isOperatingFile && docType.localeCompare(DOCUMENT_TYPES.JOB_CASA) === 0
          }
          testId="job-casa-panel"
          canDelete={false}
        />
      </Row>
      <DeleteFileModal
        isOpen={deleteFileModal.isOpened}
        setIsOpen={(open) => setDeleteFileModal({ isOpened: open })}
        fileName={deleteFileModal.fileName}
        deleteHandler={async () => {
          setOperatingFile(deleteFileModal.fileId)
          await deleteJobDocument(deleteFileModal.fileId)
          setOperatingFile(null)
        }}
      />
      <ReactTooltip html />
    </>
  )
}

export { Connected as ComplianceSection }

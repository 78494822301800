import { Form } from 'formik'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

import CloudLayoutImage from 'assets/clouds_container.svg'
import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { Spinner } from 'components/common/Spinner'
import { StatusBar } from 'components/common/StatusBar'
import { Tabs } from 'components/common/Tabs'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { styled, apply } from 'lib/styled'

const Layout = styled.div(
  apply('flex flex-column w-full bg-white rounded-xl shadow-sm relative'),
  {
    overflow: 'hidden',
  }
)

const LayoutForm = Layout.withComponent(Form)

const CloudBackground = styled.div({
  height: '220px',
  backgroundImage: `url('${CloudLayoutImage}')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'bottom right',
  marginRight: '-24px',
  marginBottom: '-24px',
})

const Container = styled.div(apply('py-8 px-6 relative'), {
  overflowY: 'auto',
  height: '100%',
})

const SpinnerContainer = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

// eslint-disable-next-line complexity
const CompanyLayout = ({ children, footer, company, loading, type, status }) => {
  const { editing } = useContext(FormLayoutContext)
  const { search } = useLocation()

  const LayoutComp = editing ? LayoutForm : Layout

  const tabsBase = [
    {
      title: 'Company Details',
      path: `/company/details${search}`,
      id: 'details',
    },
    {
      title: 'Company Rate Card',
      path: `/company/ratecard${search}`,
      id: 'ratecard',
    },
  ]

  const tabs = [
    ...tabsBase,
    {
      title: 'Aviation Details',
      path: `/company/aviation${search}`,
      id: 'aviation',
    },
  ]

  return (
    <LayoutComp>
      {status && !loading ? <StatusBar status={status} company={company} /> : null}

      <Tabs tabs={type === SUPPLIER_TYPES.PILOT ? tabs : tabsBase} />
      <Container>
        {loading ? (
          <SpinnerContainer>
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          </SpinnerContainer>
        ) : (
          children
        )}
        {!loading ? <CloudBackground /> : null}
      </Container>
      {footer || null}
    </LayoutComp>
  )
}

export { CompanyLayout }

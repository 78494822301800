import { styled, apply } from 'lib/styled'

const Container = styled.button(
  apply(
    'tracking-wide uppercase rounded-md shadow-sm h-2 px-4 text-xs bg-blue-100 border-0 border-solid border-blue-lighter font-normal'
  ),
  ({ disabled }) => ({
    color: disabled ? 'gray' : '',
  })
)
const CancelButton = styled(Button)(apply('bg-transparent shadow-none text-grey-400'))
const CreateButton = styled(Button)()

function Button({ ...props }) {
  return <Container {...props} />
}

export { Button, CancelButton, CreateButton }

import { isNotNil, prop, defaultTo } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi } from 'lib/http-client'

const productEntity = new schema.Entity('products')

const ProductService = {
  async list({ query = '', limit = 50, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }
    const { items, paging } = await adminApi.get('products', config).json()
    const normalized = normalize(items, [productEntity])
    const entities = defaultTo({}, prop('products', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },

  async read(id) {
    return adminApi.get(`products/${id}`).json()
  },

  async update(id, payload) {
    const config = {
      json: payload,
    }

    return adminApi.patch(`products/${id}`, config).json()
  },

  async create(payload) {
    return adminApi.post('products', { json: payload }).json()
  },
}

export { ProductService }

import { FieldArray } from 'formik'

import { Button } from 'components/common/Button'
import { TrashIcon } from 'components/icons'
import { styled, apply } from 'lib/styled'

const ExtraActionContainer = styled.div(apply('flex'))
const ContentContainer = styled.div(apply('flex-1 mr-4'))
const ExtraButton = styled(Button)(({ isFirstElement, labelFirstFieldOnly }) => ({
  aspectRatio: '1 / 1',
  padding: 0,
  width: 40,
  height: 40,
  marginTop: labelFirstFieldOnly && !isFirstElement ? 0 : '28px',
  background: '#555555',
  color: 'white',
}))
const AddFieldButton = styled(Button)(
  apply('w-full h-full bg-transparent shadow-none rounded-lg py-2'),
  {
    border: `1px solid #009DE4`,
    color: '#009DE4',
  }
)

function DynamicFields({
  children,
  name,
  emptyValues,
  values,
  editing,
  labelFirstFieldOnly,
  addButtonText = 'Add Field',
}) {
  if (!values) return null

  const mappedValues = values.length < 0 ? [emptyValues] : values

  return (
    <FieldArray
      name={name}
      render={(fields) => (
        <div>
          {mappedValues.map((value, i) => (
            <ExtraActionContainer key={i}>
              <ContentContainer>{children(value, i)}</ContentContainer>
              {Boolean(editing && mappedValues.length > 1) && (
                <ExtraButton
                  type="button"
                  onClick={() => fields.remove(i)}
                  labelFirstFieldOnly={labelFirstFieldOnly}
                  isFirstElement={i === 0}
                >
                  <TrashIcon />
                </ExtraButton>
              )}
            </ExtraActionContainer>
          ))}
          {Boolean(editing) && (
            <AddFieldButton type="button" onClick={() => fields.push(emptyValues)}>
              {addButtonText}
            </AddFieldButton>
          )}
        </div>
      )}
    />
  )
}

export { DynamicFields }

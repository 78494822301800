import { Status } from 'components/applications/Status'
import { styled, apply } from 'lib/styled'

const Container = styled.li(
  apply(
    'relative flex flex-row items-center px-4 py-2 border-0 border-b-1 border-solid border-grey-lighter',
    {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    }
  ),
  ({ isInspected }) => apply(`${isInspected ? 'bg-blue-lightest' : 'bg-white'}`)
)
const Details = styled.div(apply('flex-1 mb-1'))

const FullName = styled.span(apply('font-semibold text-lg text-near-black block'))
const PhoneNumber = styled.span(
  apply('font-light text-sm block', {
    color: '#C8C8C8',
  })
)

function ApplicationListItem({
  fullName,
  phoneNumber,
  status,
  avatarSrc,
  onClick,
  ...props
}) {
  return (
    <Container onClick={onClick} {...props}>
      <Details>
        <FullName>{fullName}</FullName>
        <PhoneNumber>{phoneNumber}</PhoneNumber>
      </Details>

      <Status value={status} style={apply('inline-block')} />
    </Container>
  )
}

export { ApplicationListItem }

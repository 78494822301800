import ToggleBase from 'react-toggle'

import { styled, apply } from 'lib/styled'
import 'react-toggle/style.css'

const StyledToggle = styled(ToggleBase)({
  '&.react-toggle--focus .react-toggle-thumb': apply('shadow-none'),
})

export const Toggle = ({ testId, children, ...props }) => (
  <StyledToggle data-test-id={testId} {...props} />
)

import { styled, apply } from 'lib/styled'

const HitSlop = styled.div(
  apply('p-3', {
    cursor: 'pointer',
  })
)

const Container = styled.button(
  apply('flex flex-column items-center justify-between border-0 p-0 bg-transparent'),
  ({ width, height, color }) => ({
    width,
    height,
    '& > div': {
      backgroundColor: color,
      width,
    },
  })
)
const Line = styled.div(apply('bg-black rounded-full', { height: 3 }))

function Hamburger({ width = 21, height = 15, color = '#333', onClick }) {
  return (
    <HitSlop onClick={onClick}>
      <Container width={width} height={height} color={color}>
        <Line />
        <Line />
        <Line />
      </Container>
    </HitSlop>
  )
}

export { Hamburger }

import {
  all,
  isNilOrEmpty,
  path,
  pathOr,
  values as rValues,
} from '@seedcloud/ramda-extra'
import { useFormikContext } from 'formik'
import { useContext, useEffect, useState } from 'react'

import { ServiceCategories } from './ServiceCategories'

import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { NewLabel as Label } from 'components/common/Form/Label'
import { LocationField } from 'components/common/LocationField'
import { CircledMarkerLayer, Map } from 'components/common/Map'
import { SelectField } from 'components/common/SelectField'
import { FieldGroup, Row } from 'components/company/common'
import { styled, apply } from 'lib/styled'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

const defaultValue = {
  // eslint-disable-next-line camelcase
  place_name: '',
  geometry: {
    type: 'Point',
    coordinates: [151.2093, -33.8688],
  },
}

function ServiceArea() {
  const { values, setFieldValue } = useFormikContext()
  const { editing } = useContext(FormLayoutContext)

  const isEmpty = all(isNilOrEmpty, rValues(path(['address'], values)))

  const [address, setAddress] = useState(isEmpty ? defaultValue : values.address)

  useEffect(() => {
    setAddress(isEmpty ? defaultValue : values.address)
  }, [values])

  const { coordinates } = address.geometry
  const serviceRadius = pathOr(isEmpty ? null : 50, ['serviceRadius'], values)

  return (
    <FieldGroup title="Service">
      <Details>
        <Column>
          <Label style={apply('mb-3')}>Service Categories*</Label>
          <Row>
            <ServiceCategories name="serviceCategories" readOnly={!editing} />
          </Row>
          <Row>
            <LocationField
              disabled={!editing}
              name="address"
              label="Service Location"
              containerProps={{ style: { flexGrow: 3 } }}
              testId="pilot-address"
              newStyle
              required
            />
            <SelectField
              name="serviceRadius"
              label="Survey Radius"
              onChange={({ value }) => {
                setFieldValue('serviceRadius', value)
              }}
              options={[
                { value: 50, label: '50 km' },
                { value: 100, label: '100 km' },
                { value: 150, label: '150 km' },
                { value: 200, label: '200 km' },
              ]}
              isDisabled={!editing}
              newStyle
              required
            />
          </Row>

          <Row style={apply('flex-column')}>
            <Map
              centerCoords={coordinates}
              style="mapbox://styles/mapbox/streets-v9"
              containerStyle={apply(
                'rounded-lg border-0 border-b-2 border-solid border-grey',
                { height: '60vh' }
              )}
              zoom={{ 50: [8], 100: [7], 150: [6], 200: [6] }[serviceRadius]}
              type="circled-marker"
            >
              {serviceRadius ? (
                <CircledMarkerLayer
                  units="kilometres"
                  markerCoords={coordinates}
                  radius={serviceRadius}
                  visible={coordinates}
                />
              ) : null}
            </Map>
          </Row>
        </Column>
      </Details>
    </FieldGroup>
  )
}

export { ServiceArea }

import { isNotNil } from '@seedcloud/ramda-extra'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'

import { PilotBadge } from './PilotBadge'

import { Avatar as BaseAvatar } from 'components/common/Avatar'
import { Card } from 'components/common/Card'
import { styled, apply } from 'lib/styled'
import { formatPilotCategories } from 'utils/formatPilotCategories'

const Space = styled.div(apply('flex'), ({ direction }) => ({
  flexDirection: direction,
}))

const Text = styled.span(
  apply('text-sm', {
    color: '#555555',
    marginBottom: '.2rem',
  })
)

const PendingActivationSpan = styled.span(apply('text-red-lighter'))

const BannedPill = styled.span(
  apply('uppercase font-medium tracking-wide px-2 rounded-lg bg-red-lighter', {
    height: 16,
    fontSize: 10,
    paddingBottom: 2,
  })
)

const Avatar = styled(BaseAvatar)({
  marginRight: '1rem',
  flex: '1 0 40px',
})

const InfoItem = ({ text }) => <Text>{text}</Text>

function PilotListItem({
  isBanned,
  isDeleted,
  fullName,
  phoneNumber,
  activatedAt,
  avatarSrc,
  href,
  company,
  category,
  onDelete,
  id,
  ...props
}) {
  const { id: inspectedId } = useParams()
  const isInspected = inspectedId ? inspectedId === id : false

  return (
    <Card href={href} isInspected={isInspected} {...props}>
      <Space>
        <Avatar size={40} placeholderSize={26} src={avatarSrc} />
        <Card.Head>
          <Space direction="column">
            <Card.Title style={{ marginBottom: '1rem' }}>{fullName}</Card.Title>
            <InfoItem text={phoneNumber} />
            {/* TODO: Uncomment this when Company to each pilot is resolved */}
            {/* <InfoItem text={company} /> */}
            <InfoItem text={formatPilotCategories(category)} />
            <InfoItem
              text={
                isNotNil(activatedAt) ? (
                  <>
                    Since <b>{format(new Date(activatedAt), 'MMM yyyy')}</b>
                  </>
                ) : (
                  <PendingActivationSpan>
                    Pending pilot activation
                  </PendingActivationSpan>
                )
              }
            />
          </Space>
          <PilotBadge isActive={isNotNil(activatedAt)} />
        </Card.Head>

        {isBanned && <BannedPill>Deactivated</BannedPill>}
      </Space>
    </Card>
  )
}

export { PilotListItem }

import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useLocation, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import { ClaimCompanyLayout, ClaimCompanyCard, Field } from 'components/claim/common'
import { useIdentity } from 'lib/solta-id-react'
import { companyModule, isClaimingCompany$ } from 'modules/company'
import { getTokenInformation } from 'utils/getTokenInformation'

const { Image, Title, Description, Button } = ClaimCompanyCard

const validationSchema = Yup.object({
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const ChangePassword = ({ isClaiming }) => {
  const initialValues = {
    password: '',
    confirmPassword: '',
  }
  const { token } = useParams()
  const { search } = useLocation()
  const { loginWithRedirect } = useIdentity()

  const { organizationName } = getTokenInformation(token)

  const handleSubmit = async ({ password }) => {
    const payload = {
      password,
    }
    const { sig } = Object.fromEntries(new URLSearchParams(search))
    const isClaimed = await companyModule.claimCompany(token, { sig, payload })
    if (isClaimed) {
      loginWithRedirect()
    }
  }

  return (
    <FormProvider
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {() => (
        <ClaimCompanyLayout>
          <Form>
            <ClaimCompanyCard>
              <Image />
              <Title>Welcome to {organizationName} Portal</Title>
              <Description>
                To activate your account, please setup your password.
              </Description>
              <Field
                autoComplete="new-password"
                name="password"
                id="password"
                label="Password"
                type="password"
              />
              <Field
                autoComplete="new-password"
                name="confirmPassword"
                id="confirmPassword"
                label="Confirm Password"
                type="password"
              />
              <Button type="submit" disabled={isClaiming}>
                {isClaiming ? 'Claiming...' : 'Create Password'}
              </Button>
            </ClaimCompanyCard>
          </Form>
        </ClaimCompanyLayout>
      )}
    </FormProvider>
  )
}

const ConnectedChangePassword = connect(() => ({
  isClaiming: isClaimingCompany$,
}))(ChangePassword)

export { ConnectedChangePassword as ChangePassword }

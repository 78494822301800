import {
  assoc,
  map,
  noop,
  pipe,
  project,
  renameKeys,
  replace,
} from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useState } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'

import { Button as ButtonBase } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import {
  pilotCategoryModule,
  pilotCategoriesList$,
  isFetchingPilotCategories$,
} from 'modules/pilotCategory'

const styles = (selectStyles = {}) => ({
  control: (_, state) => {
    const basicStyle = apply(
      'flex flex-row border-solid border-0 rounded-md bg-grey-100 text-lg',
      { ...selectStyles.control }
    )
    const disabledStyle = state.isDisabled
      ? apply('bg-grey-100', {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        })
      : {}

    return { ...basicStyle, ...disabledStyle }
  },

  option: (provided) => ({
    ...provided,
    ...apply('bg-grey-lightest text-black', {
      '&:hover': apply('bg-grey-light'),
    }),
  }),

  dropdownIndicator: () => apply('p-0 px-2 relative', { top: 1 }),

  valueContainer: (provided) => ({
    ...provided,
    ...apply('px-3 py-2'),
  }),

  indicatorSeparator: () => apply('hidden'),

  singleValue: (provided) => ({
    ...provided,
    ...apply('text-black'),
  }),

  placeholder: (provided) => ({
    ...provided,
    ...apply('text-grey'),
  }),

  menuList: (provided) => ({
    ...provided,
    maxHeight: '25vh',
  }),
})

const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))

const ModalTitle = styled.h2(apply('text-xl font-semibold mb-4'))
const ModalMessage = styled.div(apply('text-sm mb-8'))
const ButtonContainer = styled.div(
  apply('w-full flex justify-end', { marginTop: 'auto' })
)
const CancelButton = styled(ButtonBase)(apply('w-5 bg-grey-lighter'))
const ConfirmButton = styled(ButtonBase)(
  apply('mx-4 border-green-light', {
    backgroundColor: '#43F58233',
  }),
  ({ disabled }) => (disabled ? apply('bg-grey-lighter border-grey-light') : {})
)

const ConnectedApproveApplicationModal = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  isFetchingPilotCategories: isFetchingPilotCategories$,
}))(ApproveApplicationModal)

function ApproveApplicationModal({
  onConfirm = noop,
  isModalOpen = false,
  closeModal = noop,
  pilotCategoriesList,
  isFetchingPilotCategories,
  selectStyles,
}) {
  useLayoutEffect(() => {
    if (isModalOpen) pilotCategoryModule.fetchPilotCategories(null, { turnPage: false })
  }, [isModalOpen])

  const [selectedPilotCategoryId, setSelectedPilotCategoryId] = useState()

  const formatLabel = ({ description }) => `${replace(/_/g, ' ', description)}`

  const mapToSelectOptions = (categories = []) =>
    pipe(
      map((category) => assoc('label', formatLabel(category), category)),
      project(['label', 'id']),
      map(renameKeys({ id: 'value' }))
    )(categories)

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        overlay: apply('z-1 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: apply(
          `border-0 bg-white rounded-lg p-10 flex flex-column ${
            isFetchingPilotCategories ? '' : 'min-w-32 min-h-32'
          }`,
          {
            inset: 'unset',
            overflow: 'hidden',
          }
        ),
      }}
    >
      {isFetchingPilotCategories ? (
        <Spinner />
      ) : (
        <>
          <ModalTitle>Pilot Category Selection</ModalTitle>
          <ModalMessage>
            Please assign a pilot category before approving the application
          </ModalMessage>
          <Label htmlFor="pilot-category">Pilot Category</Label>
          <Select
            label="Pilot Category"
            type="select"
            onChange={(selected, { action }) => {
              setSelectedPilotCategoryId(
                action === 'clear' ? undefined : selected.value
              )
            }}
            options={mapToSelectOptions(pilotCategoriesList)}
            isClearable
            menuPlacement="auto"
            styles={styles(selectStyles)}
            isSearchable
            placeholder="Select Pilot Category"
            classNamePrefix="pilot-category"
            instanceId="pilot-category"
            inputId="pilot-category"
          />
          <ButtonContainer>
            <ConfirmButton
              disabled={!selectedPilotCategoryId}
              data-test-id="confirm-application-btn"
              onClick={() => onConfirm(selectedPilotCategoryId)}
            >
              Approve
            </ConfirmButton>
            <CancelButton type="button" onClick={closeModal} variant="secondary">
              Cancel
            </CancelButton>
          </ButtonContainer>
        </>
      )}
    </Modal>
  )
}

export { ConnectedApproveApplicationModal as ApproveApplicationModal }

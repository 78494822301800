import { connect } from '@seedcloud/stateless'
import { useEffect, useRef, useState } from 'react'

import { Button } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import { staffModule, canResendInviteAt$, isResendingInvite$ } from 'modules/staff'

const Container = styled.div(
  apply('my-6 rounded-lg bg-blue-lightest p-6 flex justify-between items-center')
)
const Title = styled.h3(apply('mb-2 text-lg font-light'))
const Description = styled.p(apply('m-0 text-sm font-light'))

const ConnectedResendInvite = connect(() => ({
  canResendInviteAt: canResendInviteAt$,
  isResendingInvite: isResendingInvite$,
}))(ResendInvite)

const timeUntil = (time = Date.now()) => Math.ceil((time - Date.now()) / 1000, 1)

function ResendInvite({ id, canResendInviteAt, isResendingInvite }) {
  const [secondsRemaining, setSecondsRemaining] = useState(0)
  const intervalRef = useRef()

  useEffect(() => {
    setSecondsRemaining(timeUntil(canResendInviteAt))

    if (canResendInviteAt) {
      intervalRef.current = setInterval(() => {
        const timeRemaining = timeUntil(canResendInviteAt)

        setSecondsRemaining(timeRemaining)

        if (timeRemaining === 0) {
          clearInterval(intervalRef.current)
        }
      }, 1000)
    }

    return () => clearInterval(intervalRef.current)
  }, [canResendInviteAt])

  return (
    <Container>
      <div>
        <Title>Resend Invite</Title>
        <Description>
          The invitation link will be sent to the user&apos;s email address
        </Description>
      </div>

      <Loader
        loading={isResendingInvite}
        renderPlaceholder={<Spinner size={32} thickness={6} />}
      >
        <Button
          type="button"
          onClick={() => staffModule.resendInvite(id)}
          disabled={secondsRemaining > 0}
        >
          Resend {secondsRemaining > 0 && `in ${secondsRemaining}`}
        </Button>
      </Loader>
    </Container>
  )
}
export { ConnectedResendInvite as ResendInvite }

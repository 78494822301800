import { DateField, FieldGroup, Row, TextField } from 'components/company/common'
import { formatDate } from 'utils/formatDate'

const Insurance = ({
  title,
  values: { insuranceProvider, insuranceNumber, insuranceIssuedAt, insuranceExpiredAt },
}) => (
  <FieldGroup title={title}>
    <Row>
      <TextField
        label="Policy Provider"
        value={insuranceProvider}
        name="insuranceProvider"
        required
      />
      <TextField
        label="Policy Number"
        value={insuranceNumber}
        name="insuranceNumber"
        required
      />
    </Row>
    <Row>
      <DateField
        label="Issue Date"
        value={insuranceIssuedAt ? formatDate(insuranceIssuedAt) : ''}
        name="insuranceIssuedAt"
      />
      <DateField
        label="Expiry Date"
        value={insuranceExpiredAt ? formatDate(insuranceExpiredAt) : ''}
        name="insuranceExpiredAt"
      />
    </Row>
  </FieldGroup>
)

export { Insurance }

import { isNil } from '@seedcloud/ramda-extra'
import moment from 'moment'

function safelyParseDate(date) {
  if (isNil(date)) {
    return 'Unspecified'
  }

  return moment(date)
}

export { safelyParseDate }

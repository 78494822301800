import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import * as Validator from 'yup'

import { InvitePilotForm } from './InvitePilotForm'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { FormAction } from 'components/company/common'
import { styled, apply } from 'lib/styled'
import { companyModule } from 'modules/legacy-company'
import { isUploadingAvatar$, pilotModule } from 'modules/pilot'
import { pilotCategoryModule, pilotCategoriesList$ } from 'modules/pilotCategory'
import { getCompanyAndOrganizationId } from 'utils/getCompanyId'

const { string, object } = Validator
const requiredString = string().required('This field is required')

const validationSchema = object({
  avatar: string(),
  firstName: requiredString,
  lastName: requiredString,
  phoneNumber: requiredString.matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64)'
  ),
  email: requiredString.email('Not a valid email address'),
  pilotCategoryId: requiredString,
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)

const Scrollable = styled.div(
  apply('flex flex-column p-6', {
    minHeight: 'min-content',
  })
)

const { inviteCompanyPilot } = companyModule

const ConnectedInvitePilot = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  isUploadingAvatar: isUploadingAvatar$,
}))(InvitePilot)

function InvitePilot({ pilotCategoriesList, isUploadingAvatar }) {
  const { companyId } = useParams()
  const [companyName, setCompanyName] = useState('')
  const [isInvitingPilot, setIsInvitingPilot] = useState(false)
  const history = useHistory()

  async function getCompanyName() {
    const { companyName } = await getCompanyAndOrganizationId()
    setCompanyName(companyName)
  }

  useEffect(() => {
    pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
    pilotCategoryModule.fetchPilotCategories(null, {
      turnPage: false,
    })
    getCompanyName()
    pilotModule.resetUploadAvatar()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [])

  return (
    <Container>
      <FormLayoutProvider value={{ editing: true }}>
        <FormProvider
          id="createPilot"
          enableReinitialize
          initialValues={{
            avatar: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            pilotCategoryId: '',
            companyName,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setIsInvitingPilot(true)
            const company = await inviteCompanyPilot(null, {
              companyId,
              userDetails: values,
            })
            if (company) {
              history.push(`/pilot/${company._id}`)
            }
            setIsInvitingPilot(false)
          }}
        >
          {({ resetForm, values, submitForm }) => (
            <>
              <Scrollable>
                <InvitePilotForm
                  pilotCategoriesList={pilotCategoriesList}
                  values={values}
                />
              </Scrollable>
              <FormAction
                loading={isInvitingPilot || isUploadingAvatar}
                loadingText={isInvitingPilot ? 'Inviting...' : 'Uploading...'}
                okText="Create"
                onCancel={() => {
                  resetForm()
                  history.push('/pilots')
                }}
                onOK={() => {
                  submitForm()
                }}
              />
            </>
          )}
        </FormProvider>
      </FormLayoutProvider>
    </Container>
  )
}

export { ConnectedInvitePilot as InvitePilot }

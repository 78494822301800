import { useHistory, Link as LinkBase } from 'react-router-dom'

import { styled, apply } from 'lib/styled'

const TabsWrapper = styled.div(apply('flex'))

const TabSyle = ({ selected, theme }) => ({
  borderBottom: '1px solid #CCCCCC',
  color: selected ? `${theme.colors.primary}` : '#AAAAAA',
  cursor: 'pointer',
  '&:hover': {
    color: `${theme.colors.primary}`,
    textDecoration: 'none',
  },
  '&::after': {
    content: selected ? '""' : 'unset',
    position: 'absolute',
    bottom: '0',
    left: '0',
    height: '4px',
    backgroundColor: `${theme.colors.primary}`,
    borderRadius: '.25em .25em 0 0',
    width: '100%',
  },
})

const TabItemDisabled = styled.div(
  apply('w-full text-center py-4 font-black relative'),
  TabSyle
)

const TabItem = styled(LinkBase)(
  apply('w-full text-center py-4 font-black relative'),
  TabSyle
)

const Tabs = ({
  disabled,
  tabs,
  pathLength = 3,
  defaultSelectedId = 'details',
  ...props
}) => {
  const history = useHistory()
  const pathArray = history.location.pathname.split('/')

  const renderTabs = () =>
    tabs.map((tab) => {
      const tabSelected =
        pathArray.length >= pathLength
          ? pathArray.includes(tab.id)
          : tab.id === defaultSelectedId

      if (disabled) {
        return (
          <TabItemDisabled key={tab.id} to={tab.path} selected={tabSelected}>
            {tab.title}
          </TabItemDisabled>
        )
      }
      return (
        <TabItem key={tab.id} to={tab.path} selected={tabSelected}>
          {tab.title}
        </TabItem>
      )
    })

  return <TabsWrapper {...props}>{renderTabs()}</TabsWrapper>
}

export { Tabs }

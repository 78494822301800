import { companyModule } from 'modules/company'

export const getCompanyAndOrganizationId = async () => {
  const { companyId, organizationId, companyName } = companyModule.getState()

  if (!companyId || !organizationId) {
    return companyModule.getUserCompanyAndOrganizationId()
  }

  return { companyId, organizationId, companyName }
}

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { isEmpty, isNil, equals } from '@seedcloud/ramda-extra'
import Crypto from 'crypto-js'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FileUploaderFileTypeValidator from 'filepond-plugin-file-validate-type'
import { memo, forwardRef } from 'react'
import { FilePond as FileUploader, registerPlugin } from 'react-filepond'

import { SHAPEFILE_CONTENT_TYPE, parseContentType } from './parseContentType'

import { CONFIG } from 'config'
import { apply } from 'lib/styled'
import { documentModule } from 'modules/document'
import { UploadService } from 'modules/upload'

import 'filepond/dist/filepond.min.css'

registerPlugin(FileUploaderFileTypeValidator)
registerPlugin(FilePondPluginFileValidateSize)

// apparently the plugin config doesn't support number & GB unit
const S3_PUT_OBJECT_SIZE_LIMIT_IN_BYTES = '5000MB'

const computeBearerToken = (referenceNo, uploadCode = '') =>
  Crypto.HmacSHA256(referenceNo, uploadCode)

// TODO: should add e2e test to cover file upload logics since react-testing-lib doesn't support testing useRef yet
const UploadPanel = memo(
  forwardRef(({ jobId, reference, uploadCode, files }, ref) => {
    const defaultHeaders = {
      authorization: `Bearer ${computeBearerToken(reference, uploadCode)}`,
    }

    function uploadFile(
      _fieldName,
      file,
      _metadata,
      onUploaded,
      onError,
      onProgress,
      onAbort
    ) {
      const { name: fileName, size: fileSize, type: contentType } = file
      let req

      UploadService.createSignedUrl(fileName, reference, defaultHeaders).then(
        (response) => {
          const { signedUrl, documentId } = response
          if (isEmpty(signedUrl) || isNil(signedUrl)) {
            onError('File failed to upload. Try again in a little bit.')
          }

          req = prepareUploadRequest({
            signedUrl,
            contentType,
            onProgress: (e) => onProgress(e.lengthComputable, e.loaded, e.total),
            onResolved: () =>
              documentModule.confirmJobDocumentUpload(jobId, {
                req,
                reference,
                defaultHeaders,
                documentId,
                fileSize,
                onUploaded,
                onError,
              }),
            onError,
          })

          req.send(file)
        }
      )

      return {
        abort: () => {
          req.abort()
          onAbort()
        },
      }
    }

    function prepareUploadRequest({ signedUrl, contentType, onProgress, onResolved }) {
      // We need to use XMLHttpRequest to allow us to track the upload progress.
      const req = new XMLHttpRequest()

      req.open('PUT', signedUrl, true)
      req.setRequestHeader('content-type', contentType)

      req.upload.onprogress = onProgress
      req.onload = onResolved

      return req
    }

    function deleteFile(id, onDone, onError) {
      return documentModule.deleteJobDocument(id, onDone, onError)
    }

    return (
      <FileUploader
        id="file-uploader-input"
        ref={ref}
        styles={apply('w-full')}
        files={files.map(({ id, fileName, fileSize, contentType, ...metadata }) => ({
          source: id,
          options: {
            type: 'local', // Is actually on the server.
            metadata,
            file: {
              name: fileName,
              type: contentType,
              size: fileSize,
            },
          },
        }))}
        allowMultiple
        maxFileSize={S3_PUT_OBJECT_SIZE_LIMIT_IN_BYTES}
        acceptedFileTypes={[
          // tiff + geotiff are included
          'image/*',
          'video/*',
          'application/pdf',

          // kml
          'application/vnd.google-earth.kml+xml',

          // catchall for all shapefile exts
          SHAPEFILE_CONTENT_TYPE,
        ]}
        // see https://pqina.nl/filepond/docs/api/plugins/file-validate-type/
        fileValidateTypeDetectType={(source, type) =>
          new Promise((resolve) => {
            resolve(parseContentType(source, type))
          })
        }
        server={{
          url: `${CONFIG.API.URL}/upload-jobs/${reference}/documents`,
          headers: defaultHeaders,
          process: uploadFile,
          revert: deleteFile,
          remove: deleteFile,
        }}
      />
    )
  }),
  equals
)

export { UploadPanel }

const PILOT_STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
}

const PILOT_STATUS_DISPLAY = {
  [PILOT_STATUS.ACTIVE]: 'Active',
  [PILOT_STATUS.PENDING]: 'Pending',
}

export { PILOT_STATUS, PILOT_STATUS_DISPLAY }

function Loader({ loading, renderPlaceholder, children }) {
  if (loading) {
    return (
      <>
        {typeof renderPlaceholder === 'function'
          ? renderPlaceholder()
          : renderPlaceholder}
      </>
    )
  }

  return children
}

export { Loader }

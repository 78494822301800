/* eslint-disable react-hooks/rules-of-hooks */
import { useField, Field as FormikField } from 'formik'

import { ReactComponent as OnboardingImage } from 'assets/onboarding.svg'
import { Button as BaseButton } from 'components/common/Button'
import { ErrorMessage } from 'components/common/Form/ErrorMessage'
import { Input } from 'components/common/Form/Input'
import { NewLabel as Label } from 'components/common/Form/Label'
import { styled, apply } from 'lib/styled'

const Card = styled.div(
  apply('flex flex-column bg-white items-center justify-center rounded-lg relative'),
  {
    gap: '2em',
    padding: '64px 40px',
    boxShadow: '0px 0px 10px rgba(0, 50, 111, 0.05)',
  }
)

const Image = styled(OnboardingImage)({
  position: 'relative',
  right: '-20px',
})

const Title = styled.h1({
  fontSize: '2.25rem',
  fontWeight: 700,
  margin: 0,
})

const Description = styled.p({
  fontSize: '0.9375rem',
  textAlign: 'center',
  marginBottom: 0,
})

const Button = styled(BaseButton)(
  apply('rounded-lg text-white w-full py-3'),
  ({ disabled }) => ({
    background: disabled ? '#CBCBCB' : '#009DE4',
    height: 'auto',
  })
)

const CompanyField = styled.div(apply('flex flex-column w-full'), {
  minWidth: '566px',
  gap: '.5em',
  marginLeft: 0,
  marginRight: 0,
})

const ClaimCompanyCard = Card

const InputControlled = Input.withComponent(FormikField)

ClaimCompanyCard.Image = Image
ClaimCompanyCard.Title = Title
ClaimCompanyCard.Description = Description
ClaimCompanyCard.Button = Button

const Field = ({ name, id, ...props }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, { touched, error }] = useField({ name, id, ...props })

  return (
    <BaseField
      name={name}
      id={id}
      Comp={InputControlled}
      error={touched && error && <ErrorMessage>{error}</ErrorMessage>}
      {...props}
    />
  )
}

const BaseField = ({
  label,
  id,
  name,
  disabled,
  type,
  inputProps,
  error,
  Comp = Input,
  ...props
}) => (
  <CompanyField>
    <Label htmlFor={id}>{label}</Label>
    <Comp id={id ?? name} name={name} type={type} disabled={disabled} {...props} />
    {error}
  </CompanyField>
)

ClaimCompanyCard.Field = BaseField

export { ClaimCompanyCard, Field }

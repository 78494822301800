import { isNilOrEmpty } from '@seedcloud/ramda-extra'

import { styled, apply } from 'lib/styled'

const Message = styled.div(apply('text-red text-sm'))

function ErrorMessage({ error, visible, ...otherProps }) {
  if (!visible || isNilOrEmpty(error)) return null

  return <Message {...otherProps}>{error}</Message>
}

export { ErrorMessage }

/* eslint-disable complexity */
import {
  head,
  isNilOrEmpty,
  isNotNil,
  values,
  isNotNilOrEmpty,
} from '@seedcloud/ramda-extra'

import { ORGANIZATION_ROLES, ROLES, roleKey } from 'constants/roles'

export const getRole = (user) => {
  // TODO: if Auth0 role data can't be found
  //       show a toast
  if (isNilOrEmpty(user?.[roleKey])) return null

  const roles = JSON.parse(user?.[roleKey])

  const isBillingOrCommercial =
    isNotNilOrEmpty(roles?.contactType) &&
    [ROLES.BILLING, ROLES.COMMERCIAL, ROLES.KEY_CONTACT].includes(roles.contactType)

  // If user is a platform admin
  // treat them as an organization admin
  if (roles?.admin) return ORGANIZATION_ROLES.ADMIN

  if (isBillingOrCommercial) return ROLES[roles?.contactType.toUpperCase()]

  if (isNotNilOrEmpty(roles?.pilotOnboarding)) return ROLES.ONBOARDING_PILOT

  if (
    isNotNil(roles?.hero_pilot) ||
    (isNotNilOrEmpty(roles?.contactType) && roles?.contactType === ROLES.HERO_PILOT)
  )
    return ROLES.HERO_PILOT

  if (isNotNil(roles?.pilot)) return ROLES.PILOT

  const role = head(values(roles?.staff))

  // TODO: if a user isn't a platform admin
  //       and doesn't have any roles
  //       show a toast
  if (isNilOrEmpty(role) || !values(ROLES).includes(role)) {
    return null
  }

  return role
}

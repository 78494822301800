import { baseApi } from 'lib/http-client'

const AuthService = {
  async fetchCurrentAccount() {
    const response = await baseApi.get(`my-account`).json()
    return response
  },
}

export { AuthService }

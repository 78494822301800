import { useLayoutEffect } from 'react'

import { Avatar } from 'components/common/Avatar'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { ImageUpload } from 'components/common/ImageUpload'
import { TabEditing } from 'components/common/TabEditing'
import { styled, apply } from 'lib/styled'
import { pilotModule } from 'modules/pilot'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-normal'))
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

function PersonalDetails({ id, avatarSrc, isEditing, setIsEditing }) {
  useLayoutEffect(() => {
    setIsEditing(false)
    pilotModule.resetUploadAvatar()
  }, [id])

  function onUploadAvatar([file]) {
    pilotModule.uploadAvatar(null, file, id)
  }

  return (
    <Container>
      <Header>
        <Title>Personal Details</Title>
        <TabEditing
          style={{ marginLeft: 'auto' }}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </Header>

      <Details>
        <Column style={apply('items-center w-10 mr-6', { flex: 'none' })}>
          <ImageUpload
            imageSrc={avatarSrc}
            name="userDetails.avatarSrc"
            disabled={!isEditing}
            ImageComponent={Avatar}
            imageProps={{
              size: 96,
              placeholderSize: 80,
              style: apply('m-0'),
            }}
            onUpload={onUploadAvatar}
          />
        </Column>

        <Column>
          <Row>
            <FormInput
              disabled={!isEditing}
              name="userDetails.firstName"
              type="text"
              label="First name"
            />

            <FormInput
              disabled={!isEditing}
              name="userDetails.lastName"
              type="text"
              label="Last name"
            />
          </Row>

          <Row style={apply('w-1/2')}>
            <FormInput
              disabled={!isEditing}
              name="pilotDetails.companyName"
              type="text"
              label="Company name (optional)"
              containerProps={{ style: apply('mr-3') }}
            />
          </Row>

          <Row>
            <FormInput
              disabled={!isEditing}
              name="userDetails.phoneNumber"
              type="text"
              label="Phone Number"
            />

            <FormInput disabled name="userDetails.email" type="text" label="E-mail" />
          </Row>
        </Column>
      </Details>
    </Container>
  )
}

export { PersonalDetails }

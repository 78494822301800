/* eslint-disable import/no-cycle */
import { head, path } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'
import { toast } from 'react-toastify'

import { CompanyService } from './service'

import { SUPPLIER_LABELS } from 'constants/supplier'
import { organizationModule } from 'modules/organization'
// eslint-disable-next-line import/no-cycle
import { getCompanyAndOrganizationId } from 'utils/getCompanyId'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  companyId: null,
  organizationId: null,
  companyName: null,
  inspectedEntity: undefined,
  token: undefined,
})

const getUserCompanyAndOrganizationId = (module, { setError }) =>
  withTryCatch(
    async () => {
      const companies = await CompanyService.list()
      const companyId = path(['id'], head(companies.items))
      const companyName = path(['companyName'], head(companies.items))
      const organizationId = path(['userId', 'organizationId'], head(companies.items))

      module.setState({
        companyName,
        companyId,
        organizationId,
        inspectedEntity: companyId,
        entities: { [companyId]: head(companies.items) },
      })

      return { companyId, organizationId, companyName }
    },
    {
      onError: (err) => setError(null, err),
    }
  )

const inspectCompany = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { companyId: id } = await getCompanyAndOrganizationId()

      module.setState({
        inspectedEntity: id,
      })

      await organizationModule.inspectOrganization()
      const entity = await CompanyService.read(id)

      module.setState({
        entities: { [id]: entity },
      })

      return entity
    },
    { errHandler: setError }
  )

const updateCompany = (module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      const { companyId: id } = await getCompanyAndOrganizationId()

      await CompanyService.update(id, payload)

      const entity = await CompanyService.read(id)

      toast.success('Company details has been updated!')

      module.setState({
        entities: { [id]: { ...entity } },
      })
    },
    { errHandler: setError }
  )

const submitCompany = (module, { setError }) =>
  withTryCatch(
    async (_, status) => {
      const { inspectedEntity, entities } = module.getState()

      const { companyId: id } = await getCompanyAndOrganizationId()
      let updatedStatus = SUPPLIER_LABELS.PENDING_PARTNERSHIP

      if (
        [SUPPLIER_LABELS.PENDING_ACTIVATION, SUPPLIER_LABELS.INVITED].includes(status)
      ) {
        await CompanyService.submit(id)
      } else if (status === SUPPLIER_LABELS.ACTIVE) {
        await CompanyService.update(id, {
          invitationStatus: SUPPLIER_LABELS.UPDATED_DETAILS,
        })
        updatedStatus = SUPPLIER_LABELS.UPDATED_DETAILS
      }

      module.setState({
        entities: {
          [id]: {
            ...entities[inspectedEntity],
            invitationStatus: updatedStatus,
          },
        },
      })
    },
    { errHandler: setError }
  )

const uploadAviationDocuments = (module, { setError }) =>
  withTryCatch(
    async (_, { files, auth0UserId }) => {
      const { companyId: id } = await getCompanyAndOrganizationId()

      const entities = await Promise.all(
        files.map((file) =>
          CompanyService.upload(id, file, {
            fileName: file.name,
            fileSize: file.size,
            authId: auth0UserId,
          })
        )
      )

      return entities
    },
    { errHandler: setError }
  )

const downloadAviationDocuments = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const { companyId } = await getCompanyAndOrganizationId()
      return CompanyService.download(id, companyId)
    },
    { errHandler: setError }
  )

const updateCompanyCurrency = (module, { setError }) =>
  withTryCatch(
    async (id, currency) => {
      const payload = {
        currency,
      }
      const updatedPilotCategories = await CompanyService.update(id, payload)

      module.setState({
        entities: { [id]: { ...updatedPilotCategories } },
      })
    },
    { errHandler: setError }
  )

const claimCompany = (module, { setError }) =>
  withTryCatch(
    async (token, { sig, payload }) => {
      module.setState({
        token,
      })
      await CompanyService.claim(token, sig, payload)
      return true
    },
    { errHandler: setError }
  )

const companyModule = createModule({
  name: 'company',
  initialState: INITIAL_STATE,
  decorators: {
    inspectCompany,
    updateCompanyCurrency,
    updateCompany,
    uploadAviationDocuments,
    downloadAviationDocuments,
    getUserCompanyAndOrganizationId,
    submitCompany,
    claimCompany,
  },
})

export { companyModule }

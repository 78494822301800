import { pathOr } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { FilterInput } from './FilterInput'
import { PilotListItem } from './PilotListItem'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { Button } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import { inspectedCompany$ } from 'modules/company'
import {
  pilotModule,
  pilotList$,
  inspectedEntity$,
  isLoadingPilots$,
} from 'modules/pilot'
import { pilotCategoryModule } from 'modules/pilotCategory'
import { useDebounce } from 'utils/hooks/useDebounce'
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect'

const Container = styled.div(
  {
    '--sidebar-width': '384px',
  },
  apply(
    'flex flex-column max-w-1/3 min-w-10 max-w-24 mr-6 bg-white rounded-lg shadow-sm',
    {
      width: 'var(--sidebar-width)',
      minWidth: 'var(--sidebar-width)',
      maxWidth: 'var(--sidebar-width)',
      overflow: 'hidden',
    }
  )
)

const OuterInputContainer = styled.div(
  apply('p-4 border-0 border-b-1 border-solid border-grey-lighter')
)

const FilterInputContainer = styled.div(apply('mb-4'))

const CreatePilotButton = styled(Button)(
  apply('w-full mb-4 flex items-center rounded-lg justify-center', {
    boxSizing: 'border-box',
    height: '48px',
    gap: '.5rem',
    background: '#009DE4',
    color: 'white',
  })
)

const List = styled.ol(
  apply('p-2 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const onSearchChanged = (value) => {
  pilotModule.filterPilots(null, { filterQuery: value || '' })
}

const ConnectedPilotList = connect(() => ({
  pilotList: pilotList$,
  isLoadingPilots: isLoadingPilots$,
  inspectedEntity: inspectedEntity$,
  inspectedCompany: inspectedCompany$,
}))(PilotList)

const { fetchPilots } = pilotModule

function PilotList({
  isLoadingPilots,
  pilotList,
  inspectedEntity,
  inspectedCompany = {},
  ...props
}) {
  useLayoutEffect(() => {
    pilotCategoryModule.fetchPilotCategories(null, { turnPage: false })
    fetchPilots(null)
  }, [])

  const history = useHistory()

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  useUpdateEffect(() => {
    onSearchChanged(debouncedSearch)
  }, [debouncedSearch])

  const { id } = inspectedCompany

  return (
    <Container {...props}>
      <OuterInputContainer>
        <CreatePilotButton
          disabled={isLoadingPilots}
          onClick={() => history.push(`/pilot/${id}/invite-pilot`)}
          data-test-id="invite-pilot-btn"
        >
          <PlusIcon /> ADD PILOT
        </CreatePilotButton>
        <FilterInputContainer>
          <FilterInput value={search} onChange={setSearch} />
        </FilterInputContainer>
      </OuterInputContainer>

      <Loader
        loading={isLoadingPilots}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {pilotList.map(
            ({
              id,
              activatedAt,
              isBanned,
              isDeleted,
              userDetails,
              companyName,
              pilotCategory,
            }) => {
              const { firstName, lastName, phoneNumber, avatar } = userDetails
              const category = pathOr('No Category', ['description'], pilotCategory)

              return (
                <PilotListItem
                  key={id}
                  id={id}
                  avatarSrc={avatar}
                  fullName={`${firstName} ${lastName}`}
                  phoneNumber={phoneNumber}
                  company={companyName}
                  category={category}
                  isBanned={isBanned}
                  isDeleted={isDeleted}
                  activatedAt={activatedAt}
                  href={`/pilot/${id}`}
                />
              )
            }
          )}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedPilotList as PilotList }

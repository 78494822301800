/* eslint-disable complexity */
import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { PersonalDetails } from './Fields/PersonalDetails'
import { PilotDetailsLayout } from './PilotDetailsLayout'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { FormAction } from 'components/company/common'
import { roleKey } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import { inspectedCompany$ } from 'modules/company'
import {
  pilotModule,
  inspectedPilot$,
  isInspectingPilot$,
  isUpdatingIndividualPilot$,
  isUploadingAvatar$,
} from 'modules/pilot'
import { pilotCategoryModule, pilotCategoriesList$ } from 'modules/pilotCategory'

const { object, string } = Validator
const requiredString = (label) => string().required().label(label)

const validationSchema = object({
  userDetails: object({
    firstName: requiredString('First name'),
    lastName: requiredString('Last name'),
    phoneNumber: requiredString('Phone number').matches(
      CONSTANTS.REGEX.E164_PHONE_FORMAT,
      'Not a valid phone number'
    ),
    pilotCategory: requiredString('Pilot category').matches(
      CONSTANTS.REGEX.MONGO_OBJECT_ID
    ),
  }),
})

function useParamsOrRegisteredPilotID(user, id) {
  const pilotId = JSON.parse(user?.[roleKey]).pilot
  if (!id && pilotId) {
    return pilotId
  }
  return id
}

const ConnectedPilotDetails = connect(() => ({
  isInspectingPilot: isInspectingPilot$,
  inspectedPilot: inspectedPilot$,
  pilotCategoriesList: pilotCategoriesList$,
  companyDetail: inspectedCompany$,
  isUpdatingIndividualPilot: isUpdatingIndividualPilot$,
  isUploadingAvatar: isUploadingAvatar$,
}))(PilotDetails)

function PilotDetails({
  inspectedPilot,
  pilotCategoriesList,
  companyDetail,
  isUpdatingIndividualPilot,
  isUploadingAvatar,
}) {
  const { user } = useIdentity()
  const { id: paramsId } = useParams()
  const [loading, setLoading] = useState(true)
  const [editing, setEditing] = useState(false)

  const id = useParamsOrRegisteredPilotID(user, paramsId)

  useEffect(() => {
    const fetchPilotAndSetDefaultCompany = async () => {
      setLoading(true)
      pilotCategoryModule.filterPilotCategories(null, { filterQuery: { limit: 1000 } })
      await Promise.all([
        pilotCategoryModule.fetchPilotCategories(null, {
          turnPage: false,
        }),
        pilotModule.inspectPilot(id),
      ])
      setLoading(false)
    }
    fetchPilotAndSetDefaultCompany()
    return () => {
      pilotCategoryModule.resetPagination()
    }
  }, [id])

  if (loading) return <PilotDetailsLayout disableTabs loading />

  const { userDetails, pilotCategory, userId } = inspectedPilot

  const { companyName } = companyDetail

  const { firstName, lastName, phoneNumber, avatar } = userDetails

  const initialValues = {
    userDetails: {
      firstName,
      lastName,
      phoneNumber,
      email: paramsId ? userDetails?.email : user.email,
      avatar,
      pilotCategory: pilotCategory?.id,
      pilotCategoryName: pilotCategory
        ? `${pilotCategory?.description} A$${pilotCategory?.rate} / hr`
        : '',
      companyName,
    },
  }

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        id="pilotDetails"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await pilotModule.updateIndividualPilot(id, values)
          toaster.success('Update Success!')
          setEditing(false)
        }}
      >
        {({ values, resetForm }) => (
          <PilotDetailsLayout
            disableTabs={editing}
            footer={
              editing && (
                <FormAction
                  loading={isUpdatingIndividualPilot || isUploadingAvatar}
                  loadingText={
                    isUpdatingIndividualPilot ? 'Updating...' : 'Uploading...'
                  }
                  okText="Save"
                  onCancel={() => {
                    resetForm()
                    setEditing(false)
                  }}
                />
              )
            }
          >
            <PersonalDetails
              id={id}
              userId={userId}
              avatarSrc={avatar}
              pilotCategoriesList={pilotCategoriesList}
              values={values}
              setEditing={setEditing}
              showButtonEdit={!paramsId}
            />
          </PilotDetailsLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

const PaddedContainer = styled.div(apply('flex-1 flex flex-row p-6'))

function PilotDetailsWithContainer() {
  return (
    <PaddedContainer>
      <ConnectedPilotDetails />
    </PaddedContainer>
  )
}

export { ConnectedPilotDetails as PilotDetails, PilotDetailsWithContainer }

import { isNotNil, path, omit, isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'
import ms from 'ms'

import { UploadService } from '../upload'

import { StaffService } from './service'

import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
  filterQuery: '',
  filterRole: undefined,
  order: [],
  paging: {
    next: undefined,
  },
  avatar: {
    file: undefined,
    filename: undefined,
  },
  canResendInviteAt: undefined,
})

const fetchStaff = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = false } = {}) => {
      const { filterQuery, filterRole: role, paging } = module.getState()
      const next = turnPage ? paging.next : undefined

      const {
        entities,
        order,
        next: newNext,
      } = await StaffService.list({
        query: filterQuery,
        role,
        next,
      })

      module.setState({
        entities,
        order,
        paging: {
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const createStaff = (module, { setError }) =>
  withTryCatch(
    async (_, { organizationId, role, userDetails }) => {
      const { avatar } = module.getState()
      const address = path(['location', 'place_name'], userDetails)

      const updatedUserDetails = omit(['address', 'location'], userDetails)

      let user = await StaffService.create({
        userDetails: { avatar: avatar?.file?.name, address, ...updatedUserDetails },
        role,
        organizationId,
      })

      const { id, userId } = user

      if (isNotNil(avatar.file)) {
        await module.uploadImage(null, avatar.file, userId)

        const {
          avatar: { filename },
        } = module.getState()

        user = await StaffService.update(id, { userDetails: { avatar: filename } })
      }

      return user
    },
    { errHandler: setError }
  )

const uploadImage = (module, { setError }) =>
  withTryCatch(
    async (_, file, userId) => {
      const { filename } = await UploadService.uploadFile(
        file,
        `users/${userId}/avatar-upload`,
        { fileName: file.name, fileSize: file.size }
      )
      module.setState({ avatar: { file, filename } })
    },
    { errHandler: setError }
  )

const setAvatar = (module) => (_, file) => {
  module.setState({ avatar: { file, filename: undefined } })
}

const clearAvatar = (module) => () => {
  module.setState({ avatar: { file: undefined, filename: undefined } })
}

const updateStaff = (module, { setError }) =>
  withTryCatch(
    async (id, payload) => {
      const { userDetails, role, isBanned } = payload
      const { avatar } = module.getState()

      const address = path(['location', 'place_name'], userDetails)

      if (isNotNilOrEmpty(avatar.filename)) {
        userDetails.avatar = avatar.filename
      }

      const userUpdateProps = omit(['location'], userDetails)

      const staffUpdateProps = {
        userDetails: { address, ...userUpdateProps },
        role,
        isBanned,
      }

      const updatedStaff = await StaffService.update(id, staffUpdateProps)

      module.setState({ entities: { [id]: updatedStaff } })

      module.inspectStaff(id)
    },
    { errHandler: setError }
  )

const filterStaff = (module) => (_, filter) => {
  module.setState({
    ...filter,
  })

  module.fetchStaff(null, { turnPage: false })
}

const resetStaffFilter = (module) => () => {
  module.setState({
    filterQuery: '',
    filterRole: undefined,
    paging: { next: undefined },
  })
}

const inspectStaff = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })

      const entity = await StaffService.read(id)

      module.setState({
        entities: { [id]: entity },
      })

      return entity
    },
    { errHandler: setError }
  )

const resendInvite = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const { canResendInviteAt } = module.getState()

      if (Date.now() < canResendInviteAt) {
        throw new Error('Wait before resending invite')
      }

      await StaffService.reinvite(id)

      // Due to `ky` throwing an error if the `reinvite` endpoint fails
      // this means this line only executes when `reinvite` succeeds
      // meaning that users only get a delay if it succeeds otherwise
      // they can retry again immediately, which is what we want :smile:
      module.setState({ canResendInviteAt: Date.now() + ms('30s') })
    },
    { errHandler: setError }
  )

const staffModule = createModule({
  name: 'staff',
  initialState: INITIAL_STATE,
  decorators: {
    fetchStaff,
    filterStaff,
    inspectStaff,
    createStaff,
    uploadImage,
    setAvatar,
    clearAvatar,
    updateStaff,
    resetStaffFilter,
    resendInvite,
  },
})

export { staffModule }

import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex justify-between items-center bg-blue-lightest')
)
const TabsContainer = styled.div(apply('flex'))
const Tab = styled.div(
  apply('flex justify-center py-4 w-12', {
    cursor: 'pointer',
  }),
  ({ selected }) => ({
    ...apply(
      selected
        ? 'bg-white font-semibold'
        : 'bg-blue-lightest font-regular text-grey-darkest'
    ),
    '&:hover': selected ? apply('bg-white') : { backgroundColor: '#f7fcff' },
  })
)

function Tabs({ selectedTab, setSelectedTab }) {
  const tabs = [
    {
      title: 'Company Details',
      id: 'company_details',
      onClick: () => {
        setSelectedTab('company_details')
      },
    },
    {
      title: 'Pilots',
      id: 'pilots',
      onClick: () => {
        setSelectedTab('pilots')
      },
    },
  ]
  return (
    <Container>
      <TabsContainer>
        {tabs.map((tab) => (
          <Tab key={tab.id} selected={selectedTab === tab.id} onClick={tab.onClick}>
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export { Tabs }

import { useFormikContext as useFormContext } from 'formik'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { SelectField } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0 ml-0'),
})

const formatCurrency = (value, ...opts) =>
  new Intl.NumberFormat('au-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...opts,
  }).format(value)

function InvitePilotForm({ isCreatingCompany, pilotCategoriesList, ...props }) {
  const { setFieldValue } = useFormContext()

  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${description} ${formatCurrency(rate)} / hr`,
  }))

  return (
    <Container {...props}>
      <Details>
        <Column style={apply('flex-1')}>
          <Row>
            <FormInput name="firstName" type="text" label="First Name" />
            <FormInput name="lastName" type="text" label="Last Name" />
          </Row>
          <Row>
            <FormInput
              name="phoneNumber"
              type="text"
              label="Mobile"
              placeholder="+61412341234"
            />
            <FormInput name="email" type="text" label="Email" />
          </Row>
          <Row>
            <SelectField
              name="pilotCategoryId"
              label="Pilot Category"
              emptyOption={{
                id: undefined,
                value: undefined,
              }}
              onChange={({ id }) => {
                setFieldValue('pilotCategoryId', id)
              }}
              options={pilotCategoryOptions}
              placeholder="Select Pilot Category"
            />
          </Row>
        </Column>
      </Details>
    </Container>
  )
}

export { InvitePilotForm }

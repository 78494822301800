import { useField, Field } from 'formik'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('flex flex-wrap'))
const Container = styled.label(({ selected }) =>
  apply(
    'flex items-center jusitify-between text-lg mr-4 px-3 py-2 pb-3 bg-grey-lighter rounded-lg',
    {
      cursor: 'pointer',
      transition: 'all 250ms ease-in-out',
      background: !selected && 'none',
      border: !selected ? '1px solid rgba(0, 0, 0, .2)' : '1px solid rgba(0, 0, 0, 0)',
    }
  )
)
const Icon = styled.i(apply('mr-2 text-blue-400'))
const CheckBoxInput = styled(Field)(apply('', { opacity: '0' }))

function ServiceCategories({ name, readOnly, ...props }) {
  const [fieldProps, { touched, error }] = useField({ name })
  const { value: fieldValue } = fieldProps

  return (
    <Root>
      {SERVICE_CATEGORIES.map(({ value, readableValue, iconClass }) => (
        <Container
          hidden={readOnly && !fieldValue.includes(value)}
          key={value}
          selected={fieldValue.includes(value)}
          {...props}
        >
          <Icon className={`fas fa-${iconClass}`} />
          {readableValue}
          <CheckBoxInput
            disabled={readOnly}
            value={value}
            name={name}
            type="checkbox"
            id={readableValue}
          />
        </Container>
      ))}

      <ErrorMessage error={error} visible={touched} />
    </Root>
  )
}

export { ServiceCategories }

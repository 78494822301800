import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { apply } from 'lib/styled'
import { jobModule } from 'modules/job'

function SendUploadReminder({ status, jobId, ...props }) {
  return (
    <div {...props}>
      <Loader
        loading={status === 'pending'}
        renderPlaceholder={<Spinner size={20} thickness={4} />}
      >
        <span
          style={apply('underline text-blue-400 text-sm block', {
            cursor: 'pointer',
          })}
          onClick={() => jobModule.sendUploadReminder(jobId)}
        >
          {
            {
              idle: '(Send reminder to pilot)',
              fulfilled: '(Reminder sent! Click to send again)',
              rejected: '(Retry sending reminder)',
            }[status]
          }
        </span>
      </Loader>
    </div>
  )
}

export { SendUploadReminder }

/* eslint-disable react/jsx-key */
import { SortIcon } from './SortIcon'

import { styled, apply } from 'lib/styled'

const Tr = styled.tr(apply('bg-gray-300'), {
  '&:last-child': {
    '& td': {
      borderBottom: 0,
    },
  },
})

const Th = styled.th(apply('sticky px-3 pb-4'))

const Head = styled.div(
  apply('flex flex-row uppercase tracking-wide text-xs font-medium text-grey-darker')
)

function TableHeader({ headerGroups, sortBy, onSort }) {
  return (
    <>
      <thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Th {...column.getHeaderProps()}>
                <Head>
                  {column.render('Header')}
                  {column.canSort ? (
                    <SortIcon column={column} onSort={onSort} sortBy={sortBy} />
                  ) : (
                    ''
                  )}
                </Head>
              </Th>
            ))}
          </Tr>
        ))}
      </thead>
    </>
  )
}

export { TableHeader }

/** !
 * This file was automatically generated with @twilio-labs/svg-to-react
 */
import { memo } from 'react'
import { useUID } from 'react-uid'

function UserGroupIconBase({
  title = 'User Group Icon',
  decorative = true,
  className,
  color,
  size,
}) {
  const uid = useUID()
  return (
    <span
      style={{ color, width: size, height: size, display: 'inline-block' }}
      className={className}
    >
      <svg
        role="img"
        aria-hidden={decorative}
        aria-labelledby={uid}
        height="100%"
        width="100%"
        viewBox="0 0 20 20"
      >
        {title ? <title id={uid}>{title}</title> : null}
        <path
          fill="currentColor"
          d="M7 8a4 4 0 110-8 4 4 0 010 8zm0 1c2.15 0 4.2.4 6.1 1.09L12 16h-1.25L10 20H4l-.75-4H2L.9 10.09A17.93 17.93 0 017 9zm8.31.17c1.32.18 2.59.48 3.8.92L18 16h-1.25L16 20h-3.96l.37-2h1.25l1.65-8.83zM13 0a4 4 0 11-1.33 7.76 5.96 5.96 0 000-7.52C12.1.1 12.53 0 13 0z"
        />
      </svg>
    </span>
  )
}

const UserGroupIcon = memo(UserGroupIconBase)

export { UserGroupIcon }

const LinkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1252_51197)">
      <path
        d="M14.2697 10.5153C13.5196 9.76537 12.5024 9.34411 11.4417 9.34411C10.3811 9.34411 9.36384 9.76537 8.61373 10.5153L4.61373 14.5153C4.23169 14.8842 3.92696 15.3256 3.71733 15.8136C3.50769 16.3017 3.39734 16.8265 3.39273 17.3577C3.38811 17.8888 3.48932 18.4155 3.69044 18.9071C3.89157 19.3987 4.18858 19.8453 4.56415 20.2208C4.93972 20.5964 5.38633 20.8934 5.87791 21.0945C6.3695 21.2957 6.89622 21.3969 7.42734 21.3923C7.95845 21.3876 8.48333 21.2773 8.97135 21.0677C9.45937 20.858 9.90074 20.5533 10.2697 20.1713L11.3717 19.0703M10.6137 14.1713C11.3638 14.9211 12.3811 15.3424 13.4417 15.3424C14.5024 15.3424 15.5196 14.9211 16.2697 14.1713L20.2697 10.1713C20.9984 9.41685 21.4015 8.40644 21.3924 7.35765C21.3833 6.30887 20.9626 5.30562 20.221 4.56398C19.4794 3.82235 18.4761 3.40168 17.4273 3.39256C16.3785 3.38345 15.3681 3.78663 14.6137 4.51526L13.5137 5.61526"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1252_51197">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export { LinkIcon }

import { replace } from '@seedcloud/ramda-extra'
import { useMemo } from 'react'

import { Button } from 'components/common/Button'
import { Table } from 'components/common/withTable'
import { EditIcon, TrashIcon } from 'components/icons'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { styled, apply } from 'lib/styled'
import { pilotCategoryModule } from 'modules/pilotCategory'

const Space = styled.div(apply('flex'), {
  gap: '1rem',
  flex: '0 0 300px',
})

const BaseActionButton = styled(Button)({
  background: 'transparent',
  boxShadow: 'none',
})

const EditButton = styled(BaseActionButton)({
  color: '#00326F',
})

const DeleteButton = styled(BaseActionButton)({
  color: '#D42C16',
})

const PilotTable = ({
  currency,
  values,
  handleEdit,
  handleDelete,
  next,
  limit,
  startRow,
  type,
}) => {
  const formatLabel = ({ description }) => `${replace(/_/g, ' ', description)}`

  const onTurnPage = ({ turnNext }) =>
    pilotCategoryModule.fetchPilotCategories(null, { turnPage: true, turnNext, type })

  const PILOT_CATEGORY_COLUMNS = useMemo(
    () => [
      {
        Header: 'No',
        Cell: (prop) => prop.row.index + 1 + (startRow || 0),
        disableSortBy: true,
      },
      {
        Header:
          type === SUPPLIER_TYPES.PILOT ? `Pilot Category` : 'Professional Category',
        accessor: 'description',
        Cell: ({ value }) => formatLabel({ description: String(value) }),
        disableSortBy: true,
      },
      { Header: `Rate  (${currency}/hr)`, accessor: 'rate', disableSortBy: true },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value: id }) => (
          <Space>
            <EditButton onClick={() => handleEdit(id)}>
              <EditIcon />
            </EditButton>
            <DeleteButton onClick={() => handleDelete(id)}>
              <TrashIcon />
            </DeleteButton>
          </Space>
        ),
        disableSortBy: true,
      },
    ],
    [currency, values, type, startRow]
  )

  return (
    <Table
      columns={PILOT_CATEGORY_COLUMNS}
      data={values}
      next={next}
      limit={limit}
      turnPage={onTurnPage}
      startRow={startRow}
    />
  )
}

export { PilotTable }

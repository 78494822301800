/* eslint-disable*/
import { useRef, useEffect } from 'react'

const useIsFirstRender = () => {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false
    return true
  }

  return isFirst.current
}

export const useUpdateEffect = (callback, deps = []) => {
  const isFirst = useIsFirstRender()

  useEffect(() => {
    if (!isFirst) {
      callback()
    }
  }, deps)
}

import { connect } from '@seedcloud/stateless'
import { useState, useLayoutEffect } from 'react'

import { CURRENCY_VALUE } from '../common/constants'

import { PilotCategoriesLayout } from './common/PilotCategoriesLayout'
import { CurrencySelectField } from './CurrencySelectField'
import { PilotCategoryModal } from './PilotCategoryModal'
import { PilotTable } from './PilotTable'

import { Button } from 'components/common/Button'
import { ConfirmModal } from 'components/common/ConfirmModal/ConfirmModal'
import { FieldGroup } from 'components/company/common'
import { SUPPLIER_TYPES } from 'constants/supplier'
import { styled, apply } from 'lib/styled'
import {
  companyDetail$,
  companyModule,
  currency$,
  invitationStatus$,
  isInspectingGetCompanyAndOrganizationId$,
  isUpdatingCurrency$,
  supplierType$,
} from 'modules/company'
import {
  inspectedOrganization$,
  isInspectingOrganization$,
  organizationModule,
} from 'modules/organization'
import {
  pilotCategoryModule,
  pilotCategoriesList$,
  isFetchingPilotCategories$,
  pilotCategoriesEntities$,
  pilotCategoriesFilters$,
} from 'modules/pilotCategory'

const Space = styled.div(apply('flex'), {
  gap: '1rem',
  flex: '0 0 300px',
})

const PilotCategories = ({
  pilotCategoriesList,
  pilotCategoriesEntities,
  isFetchingPilotCategories,
  isInspectingCompany,
  type,
  status,
  pilotCategoriesFilters,
  isInspectingOrganization,
  organization = {},
}) => {
  useLayoutEffect(() => {
    if (!type) {
      companyModule.inspectCompany()
    }
  }, [])

  useLayoutEffect(() => {
    if (type) {
      pilotCategoryModule.fetchPilotCategories(null, { turnPage: false, type })
    }
  }, [type])

  const {
    paging: { startRow, next },
    limit,
  } = pilotCategoriesFilters

  // eslint-disable-next-line no-unused-vars
  const currencyOptions = Object.entries(CURRENCY_VALUE).map(([_, value]) => ({
    value,
    label: value,
  }))

  const { currency } = organization

  const [selected, setSelected] = useState(CURRENCY_VALUE.AUD)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCurrencyModal, setCurrencyModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [deletedCategoryId, setDeletedCategoryId] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const handleShowEdit = (id) => {
    setShowEditModal(true)
    setSelectedCategory(pilotCategoriesEntities[id])
  }

  const handleOpenDeleteModal = (id) => {
    setShowDeleteModal(true)
    setDeletedCategoryId(id)
  }

  const handleCloseModal = () => {
    setShowAddModal(false)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedCategory(null)
  }

  const handleCreateCategory = async (values) => {
    setSubmitting(true)
    await pilotCategoryModule.publishPilotCategories(null, { type, payload: values })
    setSubmitting(false)
    setShowAddModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, { turnPage: false, type })
  }

  const handleEditPilotCategory = async (values) => {
    setSubmitting(true)
    await pilotCategoryModule.updatePilotCategories(selectedCategory.id, {
      type,
      payload: values,
    })
    setSubmitting(false)
    setShowEditModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, { turnPage: false, type })
  }

  const handleDeletePilotCategory = async () => {
    setSubmitting(true)
    await pilotCategoryModule.deletePilotCategory(deletedCategoryId)
    setSubmitting(false)
    setShowDeleteModal(false)
    await pilotCategoryModule.fetchPilotCategories(null, { turnPage: false, type })
  }

  return (
    <PilotCategoriesLayout
      status={status}
      company={organization.name ?? ''}
      type={type}
      loading={
        isFetchingPilotCategories || isInspectingCompany || isInspectingOrganization
      }
    >
      <FieldGroup
        title={
          type === SUPPLIER_TYPES.PILOT
            ? 'Customize your pilot categories and rates'
            : 'Company Ratecard'
        }
        extra={
          <Space>
            <CurrencySelectField
              label="Currency"
              options={currencyOptions}
              selected={currency}
              name="currency"
              onChange={(option) => {
                setCurrencyModal(true)
                setSelected(option.value)
              }}
            />
            <Button onClick={() => setShowAddModal(true)}>Add New Categories</Button>
          </Space>
        }
      >
        <PilotTable
          currency={currency}
          values={pilotCategoriesList}
          handleEdit={handleShowEdit}
          handleDelete={handleOpenDeleteModal}
          limit={limit}
          next={next}
          startRow={startRow}
          type={type}
        />
      </FieldGroup>

      <PilotCategoryModal
        loading={submitting}
        title="Create New Category"
        currency={currency}
        isOpen={showAddModal}
        setIsOpen={setShowAddModal}
        closeModal={handleCloseModal}
        onSubmit={handleCreateCategory}
      />

      <PilotCategoryModal
        loading={submitting}
        title="Edit Pilot Category"
        initialValues={selectedCategory}
        currency={currency}
        isOpen={showEditModal}
        setIsOpen={setShowEditModal}
        closeModal={handleCloseEditModal}
        onSubmit={handleEditPilotCategory}
      />

      <ConfirmModal
        title="Delete Category"
        subtitle="Are you sure you want to delete this pilot category?"
        isOpen={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        confirmLoadingText="Deleting..."
        loading={submitting}
        onConfirm={handleDeletePilotCategory}
      />

      <ConfirmModal
        title="Change Currency"
        subtitle={
          <span>
            Are you sure you want to apply the new currency <b>({selected})</b> to pilot
            categories?
          </span>
        }
        isOpen={showCurrencyModal}
        closeModal={() => setCurrencyModal(false)}
        onConfirm={async () => {
          // eslint-disable-next-line no-undef
          await organizationModule.updateOrganization(null, { currency: selected })
          setCurrencyModal(false)
        }}
      />
    </PilotCategoriesLayout>
  )
}

const ConnectedPilotCategories = connect(() => ({
  pilotCategoriesList: pilotCategoriesList$,
  pilotCategoriesEntities: pilotCategoriesEntities$,
  isFetchingPilotCategories: isFetchingPilotCategories$,
  isInspectingCompany: isInspectingGetCompanyAndOrganizationId$,
  isUpdatingCurrency: isUpdatingCurrency$,
  companyCurrency: currency$,
  pilotCategoriesFilters: pilotCategoriesFilters$,
  companyDetails: companyDetail$,
  type: supplierType$,
  status: invitationStatus$,
  organization: inspectedOrganization$,
  isInspectingOrganization: isInspectingOrganization$,
}))(PilotCategories)

export { ConnectedPilotCategories as PilotCategories }

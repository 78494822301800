const EditIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3033 4.30325C16.5247 4.07402 16.7895 3.89119 17.0823 3.7654C17.3751 3.63962 17.6901 3.57342 18.0087 3.57065C18.3274 3.56788 18.6434 3.6286 18.9384 3.74928C19.2333 3.86995 19.5013 4.04816 19.7266 4.2735C19.952 4.49884 20.1302 4.76681 20.2509 5.06176C20.3715 5.35671 20.4323 5.67274 20.4295 5.99141C20.4267 6.31008 20.3605 6.62501 20.2347 6.91782C20.109 7.21063 19.9261 7.47546 19.6969 7.69685L18.7453 8.64845L15.3517 5.25485L16.3033 4.30325ZM13.6549 6.95165L3.6001 17.0064V20.4H6.9937L17.0497 10.3452L13.6549 6.95165Z"
      fill="currentColor"
    />
  </svg>
)

export { EditIcon }

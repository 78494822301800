import Modal from 'react-modal'

import { CompanyDetails } from 'components/legacy-company/CompanyDetails'
import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('text-black bg-white rounded-lg'))

Modal.setAppElement('#root')

function CompanyModal({ isOpen, setIsOpen, companyId }) {
  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      id="special"
      style={{
        overlay: apply('z-4 p-0 flex items-center justify-center', {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }),
        content: apply('p-0 bg-transparent border-0', {
          inset: 'unset',
          top: '25%',
        }),
      }}
    >
      <Root>
        <CompanyDetails companyId={companyId} isModal />
      </Root>
    </Modal>
  )
}

export { CompanyModal }

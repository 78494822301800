/* eslint-disable camelcase */
import { prop } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useState, useEffect, useLayoutEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { NavigationTab } from './NavigationTab'
import { Actions, JobInfo } from './sections'
import getFormInitialValues from './utils/getFormInitialValues'

import { Spinner } from 'components/common/Spinner'
import { ContactInfo } from 'components/jobs/common'
import radiusValidator from 'components/jobs/common/radiusValidator'
import { ORGANIZATION_ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { apply, styled } from 'lib/styled'
import { jobDocumentList$ } from 'modules/document'
import { inspectedJob$, isInspectingJobs$, selectedTab$, jobModule } from 'modules/job'

const { object, string, array } = Validator
const optionalString = string().nullable().optional()

const validationSchema = object({
  status: optionalString,
  jobInfo: object({
    reference: optionalString,
    createdAt: optionalString,
    scheduledAt: optionalString.label('scheduledAt'),
    startedAt: optionalString.label('startedAt'),
    engagedAt: optionalString.label('engagedAt'),
    description: optionalString,
    instructions: optionalString,
    uploadCode: optionalString,
    product: object({
      name: optionalString,
      id: optionalString,
    }),
    project: object({
      reference: string().optional(),
      id: string().optional(),
    }).optional(),
    location: object().optional().label('location'),
    radius: radiusValidator.optional(),
    finishedAt: optionalString
      .when('startedAt', {
        is: (startedAt) => startedAt !== 'Unspecified',
        then: string().test(
          'is-earlier-than-startedAt',
          'End time should be later than start time',
          (finishedAt, context) => {
            if (finishedAt !== 'Unspecified') {
              return new Date(finishedAt) > new Date(context.parent.startedAt)
            }
            return true
          }
        ),
      })
      .label('finishedAt'),
  }),
  documents: object({
    uploadedByPilot: array().optional().of(object()),
    uploadedByOrganization: array().optional().of(object()),
  }).optional(),
  contactInfo: object({
    client: object({
      id: optionalString,
      name: optionalString,
      mobile: optionalString,
      email: optionalString,
    }),

    pilot: object({
      id: optionalString,
      name: optionalString,
      mobile: optionalString,
      email: optionalString,
    }),
  }),
})

const Container = styled.div(
  apply('flex flex-column w-full bg-white rounded-lg shadow-sm pb-8')
)

const Scrollable = styled.div(
  apply('flex flex-column h-full px-8', {
    overflowY: 'scroll',
  })
)

const ConnectedJobDetails = connect(() => ({
  selectedTab: selectedTab$,
  inspectedJob: inspectedJob$,
  isInspectingJobs: isInspectingJobs$,
  jobDocumentList: jobDocumentList$,
}))(JobDetails)

function JobDetails({ inspectedJob, isInspectingJobs, selectedTab, jobDocumentList }) {
  const { role } = useIdentity()
  const history = useHistory()
  const [isEditing, setIsEditing] = useState(false)
  const { id } = useParams()

  useLayoutEffect(() => {
    if (selectedTab === 'published') {
      jobModule.inspectJob(id)
    } else {
      jobModule.inspectEngagedJob(id)
    }
  }, [])

  useEffect(() => {
    const scroll = document.querySelector('#scroll')
    if (scroll) scroll.scrollTop = 0
  }, [inspectedJob?.status])

  if (!inspectedJob || id !== prop('id', inspectedJob) || isInspectingJobs) {
    return (
      <Container>
        <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
      </Container>
    )
  }

  const { id: jobId, status: jobStatus, reference } = inspectedJob

  const { jobInfo, documents, contactInfo, ratingAndFeedback } = getFormInitialValues(
    inspectedJob,
    jobDocumentList
  )

  const onSubmit = async ({ status, contactInfo, jobInfo }) => {
    await jobModule.updateJob(jobId, { status, contactInfo, jobInfo })
    setIsEditing(false)
  }

  return (
    <Container>
      <Scrollable id="scroll">
        <FormProvider
          id="jobDetails"
          enableReinitialize
          initialValues={{
            status: jobStatus,
            jobInfo,
            contactInfo,
            ratingAndFeedback,
            documents,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <NavigationTab
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onGoBackClicked={() => history.push('/jobs')}
              title={jobStatus}
            />
            <JobInfo
              jobReference={reference}
              isEditing={isEditing}
              jobStatus={jobStatus}
            />
            <ContactInfo
              isEditing={isEditing}
              editableClient={role !== ORGANIZATION_ROLES.STAFF && isEditing}
            />
            <Actions />
          </Form>
        </FormProvider>
      </Scrollable>
    </Container>
  )
}

export { ConnectedJobDetails as JobDetails }

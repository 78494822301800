import CONSTANTS from '@seedcloud/constants'
import { isNilOrEmpty, isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { CompanyDetailsForm } from './CompanyDetailsForm'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { TabEditing } from 'components/common/TabEditing'
import { styled, apply } from 'lib/styled'
import {
  companyModule,
  inspectedCompany$,
  isInspectingCompany$,
  isUploadingImage$,
} from 'modules/legacy-company'

const { object, string, date } = Validator
const requiredString = string().required('This field is required')
const optionalString = string().nullable().optional()

const validationSchema = object().shape(
  {
    companyName: requiredString,
    abn: requiredString,
    location: object().optional().label('location'),
    termsAndConditions: optionalString,
    keyContact: object().shape({
      firstName: requiredString,
      lastName: requiredString,
      phoneNumber: requiredString.matches(
        CONSTANTS.REGEX.E164_PHONE_FORMAT,
        'Not a valid phone number'
      ),
      email: requiredString.email('Not a valid email address'),
    }),
    insuranceNumber: string().when('insuranceExpiredAt', {
      is: isNotNilOrEmpty,
      then: string().required().label('Insurance number'),
      otherwise: string().optional().label('Insurance number'),
    }),
    insuranceExpiredAt: date()
      .min(new Date())
      .label('Insurance expiry')
      .when('insuranceNumber', {
        is: isNotNilOrEmpty,
        then: date().min(new Date()).required().nullable(),
        otherwise: date().min(new Date()).optional().nullable(),
      }),
    type: requiredString,
  },
  [['insuranceNumber', 'insuranceExpiredAt']]
)

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row pt-4'))
const Title = styled.h2(apply('m-0 text-xl font-light py-6'))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const { inspectCompany, resetCompanyFilter, fetchCompanies } = companyModule

const ConnectedCompanyDetails = connect(() => ({
  isInspectingCompany: isInspectingCompany$,
  inspectedCompany: inspectedCompany$,
  isUploadingImage: isUploadingImage$,
}))(CompanyDetails)

function CompanyDetails({
  companyId,
  isInspectingCompany,
  companyEntities,
  isUploadingImage,
  inspectedCompany,
  isModal = false,
  ...props
}) {
  const [isEditing, setIsEditing] = useState(false)
  const [reRenderKey, setReRenderKey] = useState(false)

  const location = useLocation()
  const params = useParams()
  const id = params.companyId || companyId
  const isNew = location?.state?.isNew

  useEffect(() => {
    setIsEditing(false)
  }, [id])

  useEffect(() => {
    inspectCompany(id)
  }, [id])

  useEffect(() => {
    if (isNew) {
      resetCompanyFilter()
      fetchCompanies(null, { turnPage: false })
    }
  }, [isNew])

  if (isNilOrEmpty(inspectedCompany)) return null

  const {
    companyName,
    abn,
    address,
    type,
    userId: keyContact,
    isActive,
    termsAndConditions,
    insuranceNumber,
    insuranceExpiredAt,
  } = inspectedCompany

  return (
    <Container {...props}>
      <FormProvider
        id="companyDetails"
        enableReinitialize
        initialValues={{
          companyName,
          abn,
          // eslint-disable-next-line camelcase
          location: { place_name: address },
          type,
          keyContact,
          termsAndConditions,
          insuranceNumber,
          insuranceExpiredAt,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await companyModule.updateCompany(id, values)
          setIsEditing(false)
        }}
      >
        <Loader
          loading={isInspectingCompany}
          renderPlaceholder={
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          }
        >
          <Scrollable>
            <Form key={reRenderKey}>
              <Header>
                <Title>Company Details</Title>
                {!isModal && (
                  <TabEditing
                    style={{ marginLeft: 'auto' }}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    disabled={isUploadingImage}
                    customHandleCancel={() => setReRenderKey(!reRenderKey)}
                  />
                )}
              </Header>
              <CompanyDetailsForm
                id={id}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                isActive={isActive}
              />
            </Form>
          </Scrollable>
        </Loader>
      </FormProvider>
    </Container>
  )
}

export { ConnectedCompanyDetails as CompanyDetails }

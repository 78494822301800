import { useHistory } from 'react-router-dom'

import { Button } from './Button'

import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('h-full flex bg-grey-lightest w-full', {
    overflow: 'hidden',
  })
)

export const NotFoundPage = (props) => {
  const history = useHistory()
  return (
    <Container {...props}>
      <div
        style={apply('flex flex-column items-center text-lg font-semibold', {
          margin: 'auto',
        })}
      >
        <p>We can&apos;t seem to find that page.</p>
        <Button onClick={() => history.goBack()}>Go Back</Button>
      </div>
    </Container>
  )
}

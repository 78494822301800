import { Switch, Route } from 'react-router-dom'

import { CompanyList } from './CompanyList'
import { CompanyTabs } from './CompanyTabs'
import { CreateCompany } from './CreateCompany'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { ProtectedRoute } from 'components/common/ProtectedRoute'
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

// eslint-disable-next-line react/display-name
const withCompanyList = (Component) => (props) =>
  (
    <>
      <CompanyList />
      <Component {...props} />
    </>
  )

function CompanyManager() {
  const { permissions } = usePermissions()
  const canCreateCompany = permissions.can('create', RESOURCES.COMPANY)

  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/legacy-companies']} component={CompanyList} />
        <ProtectedRoute
          exact
          path="/legacy-companies/create"
          render={withCompanyList(CreateCompany)}
          allowed={canCreateCompany}
        />
        <Route
          exact
          path="/legacy-companies/:companyId"
          render={withCompanyList(CompanyTabs)}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { CompanyManager }

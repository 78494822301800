import { connect } from '@seedcloud/stateless'

import { SearchInput } from 'components/common/SearchInput'
import { applicationModule, filterQuery$ } from 'modules/application'

const ConnectedFilterInput = connect(() => ({
  filterQuery: filterQuery$,
}))(FilterInput)

const { filterApplications } = applicationModule

function FilterInput({ filterQuery }) {
  return (
    <SearchInput
      value={filterQuery}
      placeholder="Reference, name, email or phone number"
      onChange={(filterQuery) => filterApplications(null, { filterQuery })}
    />
  )
}

export { ConnectedFilterInput as FilterInput }

import { isNotEmpty, isNilOrEmpty } from '@seedcloud/ramda-extra'
import { useCallback, useEffect, useState } from 'react'

import { ErrorMessage } from './ErrorMessage'
import { InvalidFileTypeModal } from './InvalidFileTypeModal'

import imagePlaceHolder from 'assets/images/image-placeholder.jpg'
import { styled, apply } from 'lib/styled'
import { useFileUpload } from 'utils/hooks'

const Container = styled.div(apply('relative'))
const PlaceholderContainer = styled.div(
  apply('flex justify-center items-center bg-grey-100 rounded-full')
)
const UploadHoverPrompt = styled.div(
  apply('absolute w-full h-full bg-white z-2 flex items-center justify-center', {
    opacity: 0.8,
    cursor: 'pointer',
  }),
  {},
  ({ isVisible }) => (isVisible ? apply('absolute') : apply('hidden'))
)
const Prompt = styled.h2(apply('text-xs font-semibold text-center'))
const RequiredText = styled.div(apply('mt-2 text-red text-sm'))

/* eslint-disable complexity */
function ImageUpload({
  imageSrc,
  name,
  disabled = false,
  ImageComponent,
  ImagePlaceHolder = imagePlaceHolder,
  imageProps,
  placeholderContainerProps,
  placeholderImageProps,
  onUpload,
  submitCount,
  testId,
  ...props
}) {
  const {
    getRootProps,
    getInputProps,
    fileRejections,
    acceptedFiles,
    isDragActive,
    isDragReject,
  } = useFileUpload({
    accept: 'image/jpeg, image/png',
    noKeyboard: true,
    disabled,
    onDropAccepted: useCallback(onUpload),
  })

  const [preview, setPreview] = useState(imageSrc)
  const [showUploadPrompt, setShowUploadPrompt] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const errorMessage = 'Only JPG/PNG files are supported'

  const onMouseEnter = () => setShowUploadPrompt(true)
  const onMouseLeave = () => setShowUploadPrompt(false)

  useEffect(() => {
    if (!disabled && isNotEmpty(fileRejections)) {
      setIsErrorModalOpen(true)
      fileRejections.pop()
    }
  }, [fileRejections, disabled])

  useEffect(() => {
    if (isNotEmpty(acceptedFiles)) {
      setPreview(URL.createObjectURL(acceptedFiles[0]))
    }

    return () => URL.revokeObjectURL(preview)
  }, [acceptedFiles])

  return (
    <>
      <Container
        {...getRootProps()}
        {...props}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <UploadHoverPrompt isVisible={(!disabled && showUploadPrompt) || isDragReject}>
          {!isDragActive && <Prompt>Drop or click to upload an JPG/PNG image</Prompt>}
          <ErrorMessage visible={isDragReject} error={errorMessage} />
        </UploadHoverPrompt>

        {isNilOrEmpty(preview) ? (
          <PlaceholderContainer {...placeholderContainerProps}>
            <ImageComponent
              src={ImagePlaceHolder}
              {...imageProps}
              {...placeholderImageProps}
            />
          </PlaceholderContainer>
        ) : (
          <ImageComponent src={preview} {...imageProps} />
        )}
        <input {...getInputProps()} data-test-id={testId} />
        <InvalidFileTypeModal
          isOpen={isErrorModalOpen}
          setIsOpen={setIsErrorModalOpen}
          message={errorMessage}
          title="Invalid Image Type"
        />
      </Container>
      {isNilOrEmpty(preview) && submitCount > 0 && (
        <RequiredText>An uploaded file is required</RequiredText>
      )}
    </>
  )
}

export { ImageUpload }

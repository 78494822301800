import { Switch, Route } from 'react-router-dom'

import { InvitePilot } from './InvitePilot'
import { PilotAviationDetails } from './PilotDetails/PilotAviationDetails'
import { PilotDetails } from './PilotDetails/PilotDetails'
import { PilotList } from './PilotList'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

const PilotListWrapper = ({ component }) => (
  <>
    <PilotList />
    {component || null}
  </>
)

function PilotsManager() {
  return (
    <Container>
      <Switch>
        <Route exact path="/pilots" render={() => <PilotList />} />
        <Route
          exact
          path="/pilot/:id"
          render={() => <PilotListWrapper component={<PilotDetails />} />}
        />
        <Route
          exact
          path="/pilot/:id/personal-details"
          render={() => <PilotListWrapper component={<PilotDetails />} />}
        />
        <Route
          exact
          path="/pilot/:id/aviation-details"
          render={() => <PilotListWrapper component={<PilotAviationDetails />} />}
        />
        <Route
          exact
          path="/pilot/:companyId/invite-pilot"
          render={() => <PilotListWrapper component={<InvitePilot />} />}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { PilotsManager }

import CONSTANTS from '@seedcloud/constants'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { InvitePilotForm } from './InvitePilotForm'

import { CancelButton, CreateButton } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import { isInvitingPilot$, companyModule } from 'modules/legacy-company'
import { pilotCategoryModule, pilotCategoriesList$ } from 'modules/pilotCategory'

const { string, object } = Validator
const requiredString = string().required('This field is required')

const validationSchema = object({
  firstName: requiredString,
  lastName: requiredString,
  phoneNumber: requiredString.matches(
    CONSTANTS.REGEX.E164_PHONE_FORMAT,
    'Please include a country code (e.g +64)'
  ),
  email: requiredString.email('Not a valid email address'),
  pilotCategoryId: requiredString,
})

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const ButtonsContainer = styled.div(apply('flex', { marginLeft: 'auto' }))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const { inviteCompanyPilot } = companyModule

const ConnectedInvitePilot = connect(() => ({
  isInvitingPilot: isInvitingPilot$,
  pilotCategoriesList: pilotCategoriesList$,
}))(InvitePilot)

function InvitePilot({ isInvitingPilot, pilotCategoriesList, setIsInvitingPilot }) {
  const { companyId } = useParams()

  useEffect(() => {
    pilotCategoryModule.fetchPilotCategories(null, { turnPage: false })
  })

  return (
    <Container>
      <FormProvider
        id="createPilot"
        enableReinitialize
        initialValues={{
          firstName: undefined,
          lastName: undefined,
          phoneNumber: undefined,
          email: undefined,
          pilotCategoryId: undefined,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await inviteCompanyPilot(null, { companyId, userDetails: values })
          setIsInvitingPilot(false)
        }}
      >
        <Scrollable>
          <Form>
            <Header>
              <Title>Invite Pilot</Title>
              <ButtonsContainer>
                {isInvitingPilot && (
                  <Spinner size={32} thickness={6} style={{ margin: 'auto 32px' }} />
                )}
                <CancelButton
                  onClick={() => setIsInvitingPilot(false)}
                  style={apply('ml-4')}
                  type="button"
                >
                  Cancel
                </CancelButton>
                <CreateButton disabled={isInvitingPilot} type="submit">
                  Create
                </CreateButton>
              </ButtonsContainer>
            </Header>

            <InvitePilotForm pilotCategoriesList={pilotCategoriesList} />
          </Form>
        </Scrollable>
      </FormProvider>
    </Container>
  )
}

export { ConnectedInvitePilot as InvitePilot }

export const locationMarkerSvg = `<svg width="25" height="60" viewBox="0 0 45 73" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f)">
<ellipse cx="22.3486" cy="62.341" rx="11.7625" ry="2.35249" fill="#1D9DDA" fill-opacity="0.4"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.3487 62.257C34.6915 62.257 44.6973 34.6915 44.6973 22.3487C44.6973 10.0058 34.6915 0 22.3487 0C10.0058 0 0 10.0058 0 22.3487C0 34.6915 10.0058 62.257 22.3487 62.257ZM22.3487 31.9266C27.6384 31.9266 31.9267 27.6384 31.9267 22.3486C31.9267 17.0588 27.6384 12.7706 22.3487 12.7706C17.0589 12.7706 12.7707 17.0588 12.7707 22.3486C12.7707 27.6384 17.0589 31.9266 22.3487 31.9266Z" fill="#1D9DDA"/>
<defs>
<filter id="filter0_f" x="2.58618" y="51.9885" width="39.5249" height="20.705" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur"/>
</filter>
</defs>
</svg>
`

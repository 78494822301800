import { Switch, Route } from 'react-router-dom'

import { JobDetails } from './JobDetails'
import { JobTable } from './JobTable'

import { NotFoundPage } from 'components/common/NotFoundPage'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-row p-6'))

function JobsManager() {
  return (
    <Container>
      <Switch>
        <Route exact path={['/', '/jobs']} component={JobTable} />
        <Route exact path="/job/:id" component={JobDetails} />
        <Route component={NotFoundPage} />
      </Switch>
    </Container>
  )
}

export { JobsManager }

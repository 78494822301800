import { connect } from '@seedcloud/stateless'

import { SearchInput } from 'components/common/SearchInput'
import { staffModule, filterQuery$ } from 'modules/staff'

const ConnectedFilterInput = connect(() => ({
  filterQuery: filterQuery$,
}))(FilterInput)

const { filterStaff } = staffModule

const onChange = (value) => {
  filterStaff(null, { filterQuery: value })
}

function FilterInput({ filterQuery, containerProps }) {
  return (
    <SearchInput
      value={filterQuery}
      placeholder="Enter email, name or phone number"
      onChange={onChange}
      containerProps={containerProps}
    />
  )
}

export { ConnectedFilterInput as FilterInput }

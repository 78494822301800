import { useContext } from 'react'

import { FieldGroup, Row, Separator, TextField } from '../../common'

import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { DynamicFields } from 'components/common/Form/DynamicFields'
import { getFieldArrayName } from 'utils/getFieldArrayName'

const Equipment = ({ title, name, values }) => {
  const { editing } = useContext(FormLayoutContext)

  // eslint-disable-next-line no-unreachable
  return (
    <FieldGroup title={title} noMargin>
      <DynamicFields
        name={name}
        values={values}
        emptyValues={{
          category: '',
          make: '',
          model: '',
        }}
        editing={editing}
        labelFirstFieldOnly
        addButtonText="Add Equipment"
      >
        {({ category, make, model }, i) => {
          const isFirstElement = i === 0
          return (
            <>
              <Row>
                <TextField
                  label={isFirstElement ? 'Category' : undefined}
                  value={category}
                  name={getFieldArrayName(name, i, 'category')}
                />
                <TextField
                  label={isFirstElement ? 'Make' : undefined}
                  value={make}
                  name={getFieldArrayName(name, i, 'make')}
                />
                <TextField
                  label={isFirstElement ? 'Model' : undefined}
                  value={model}
                  name={getFieldArrayName(name, i, 'model')}
                />
              </Row>
              {!editing && <Separator noMargin />}
            </>
          )
        }}
      </DynamicFields>
    </FieldGroup>
  )
}

export { Equipment }

export const getTokenInformation = (token) => {
  const buff = Buffer.from(token, 'base64')

  try {
    const { organizationName, userEmail, userContactType, companyName } = JSON.parse(
      buff.toString('ascii')
    )

    return {
      organizationName,
      userEmail,
      userContactType,
      companyName,
    }
  } catch (error) {
    return {}
  }
}

export const RESOURCES = {
  JOB: 'JOB',
  PILOT_APPLICATION: 'PILOT_APPLICATION',
  PILOT_DETAILS: 'PILOT_DETAILS',
  MY_PILOT_INFO: 'MY_PILOT_INFO',
  PRODUCT: 'PRODUCT',
  MY_COMPANY: 'MY_COMPANY',
  FILE: 'FILE',
  STAFF: 'STAFF',
  COMPANY: 'COMPANY',
  CLAIM_COMPANY: 'CLAIM_COMPANY',
}

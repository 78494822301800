import { useState } from 'react'

import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex flex-row items-center justify-center border-blue-400 bg-transparent')
)

const Placeholder = styled.i(apply('self-end text-blue-400'), ({ size }) => ({
  fontSize: size,
}))

const ImageComponent = styled.img()

function Thumbnail({ width = 64, src, ...props }) {
  const [isError, setIsError] = useState(false)

  return (
    <Container {...props}>
      {!isError && (
        <ImageComponent src={src} onError={() => setIsError(true)} width={width} />
      )}

      {isError && <Placeholder className="fas fa-image" size={width} />}
    </Container>
  )
}

export { Thumbnail }

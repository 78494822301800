import { Button } from './Button'

import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('h-full flex bg-grey-lightest w-full', {
    overflow: 'hidden',
  })
)

export const ErrorPage = ({ error, ...props }) => (
  <Container {...props}>
    <div
      style={apply('flex flex-column items-center text-lg font-semibold', {
        margin: 'auto',
      })}
      role="alert"
    >
      <p>Something went wrong.</p>
      <p>Please try again or contact support with this message</p>
      <span style={apply('text-base text-red-light')}>{error?.message}</span>

      <Button
        style={apply('mt-4')}
        onClick={() => {
          window.location.reload(false)
        }}
      >
        Reload page
      </Button>
    </div>
  </Container>
)

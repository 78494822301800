import { ReactComponent as ClaimedImage } from 'assets/claimed.svg'
import { ClaimCompanyLayout, ClaimCompanyCard } from 'components/claim/common'
import { styled } from 'lib/styled'

const { Title, Description, Button } = ClaimCompanyCard

const ClaimedTitle = styled(Title)({
  textAlign: 'center',
  width: '500px',
})

const ClaimedDescription = styled(Description)({
  width: '500px',
})

const ClaimedCompany = () => (
  <ClaimCompanyLayout>
    <ClaimCompanyCard>
      <ClaimedImage />
      <ClaimedTitle>Your company has registered to GHD Portal.</ClaimedTitle>
      <ClaimedDescription>
        Looks like your team already claimed your company. Please continue to activate
        your account.
      </ClaimedDescription>
      <Button onClick={() => {}}>Continue To Activate Your Account</Button>
    </ClaimCompanyCard>
  </ClaimCompanyLayout>
)

export { ClaimedCompany }

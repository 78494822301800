import ky from 'ky'

import { CONFIG } from 'config'
import { adminApi, baseApi } from 'lib/http-client'

const uploadApi = ky.create().extend({
  prefixUrl: `${CONFIG.API.URL}`,
})

const handleUploadToS3 = ({
  presignedUrl,
  file,
  filename,
  entity,
  onProgress,
  onError,
}) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()

    const performAsynchronously = true
    request.open('PUT', presignedUrl, performAsynchronously)
    request.setRequestHeader('content-type', file.type)

    request.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        onProgress(event.loaded, event.total)
      }
    }

    request.onload = () => {
      const success = request.status >= 200 && request.status < 300

      if (!success) {
        reject(new Error('Failed to upload file. Please try again'))
        onError({ filename, entity })
      } else {
        resolve({ filename, entity })
      }
    }

    request.send(file)
  })

const UploadService = {
  async uploadFileNonAdmin(
    file,
    path,
    json,
    onError = () => {},
    onProgress = () => {}
  ) {
    const {
      signedUrl: presignedUrl,
      filename,
      entity,
    } = await baseApi.post(path, { json }).json()

    return handleUploadToS3({
      presignedUrl,
      file,
      onError,
      onProgress,
      filename,
      entity,
    })
  },
  async uploadFile(file, path, json, onError = () => {}, onProgress = () => {}) {
    const {
      signedUrl: presignedUrl,
      filename,
      entity,
    } = await adminApi.post(path, { json }).json()

    return handleUploadToS3({
      presignedUrl,
      file,
      onError,
      onProgress,
      filename,
      entity,
    })
  },
  async createSignedUrl(fileName, reference, defaultHeaders) {
    try {
      const response = await uploadApi
        .post(`upload-jobs/${reference}/documents`, {
          json: { fileName },
          headers: {
            ...defaultHeaders,
            'content-type': 'application/json',
          },
        })
        .json()

      return response
    } catch (error) {
      return {}
    }
  },

  async confirmUpload(
    req,
    reference,
    defaultHeaders,
    documentId,
    fileSize,
    onUploaded,
    onError
  ) {
    const fileUploadedSuccessfully = req.status >= 200 && req.status < 300
    if (!fileUploadedSuccessfully) {
      onError('File failed to upload. Try again in a little bit.')
      return
    }

    try {
      await uploadApi
        .put(`upload-jobs/${reference}/documents/${documentId}`, {
          json: { fileSize },
          headers: {
            ...defaultHeaders,
            'content-type': 'application/json',
          },
        })
        .json()
    } catch (error) {
      onError('File failed to upload. Try again in a little bit.')
      return
    }

    onUploaded(documentId)
  },
}

export { UploadService }

/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import { TableBody } from './TableBody'
import { TableHeader } from './TableHeader'

import { styled } from 'lib/styled'

const Table = styled.table({
  borderSpacing: 0,
  minWidth: '100%',
  boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
})

function TableBase({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  rows,
  onClick,
  sortBy,
  onSort,
  domain,
}) {
  return (
    <>
      <Table {...getTableProps()}>
        <TableHeader headerGroups={headerGroups} sortBy={sortBy} onSort={onSort} />
        <TableBody
          getTableBodyProps={getTableBodyProps}
          prepareRow={prepareRow}
          rows={rows}
          onClick={onClick}
          domain={domain}
        />
      </Table>
    </>
  )
}

export { TableBase }

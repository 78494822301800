import { SearchInput } from 'components/common/SearchInput'

function FilterInput({ value, onChange }) {
  return (
    <SearchInput
      value={value}
      placeholder="Enter email, name or phone number"
      onChange={onChange}
    />
  )
}

export { FilterInput }

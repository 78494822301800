/* eslint-disable import/no-cycle */
import { map, prop, path, pathOr, pick } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { jobModule } from './module'

const { state$, effectState$ } = jobModule

const jobEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))

const filterQuery$ = select(state$, prop('filterQuery'))
const jobFilters$ = select(
  state$,
  pick([
    'filterQuery',
    'statuses',
    'paging',
    'sortBy',
    'sortOrder',
    'limit',
    'from',
    'to',
    'project',
  ])
)

const selectedTab$ = select(state$, prop('selectedTab'))

const inspectedEntity$ = select(state$, prop('inspectedEntity'))

const jobList$ = select(order$, jobEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedJob$ = select(inspectedEntity$, jobEntities$, (id, entities) =>
  prop(id, entities)
)

const fetchJobsStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['fetchJobs', 'status'], effectState)
)

const fetchEngagedJobsStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['fetchEngagedJobs', 'status'], effectState)
)

const inspectJobStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('idle', ['inspectJob', inspectedEntity, 'status'], effectState)
)

const inspectEngagedJobStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('idle', ['inspectEngagedJob', inspectedEntity, 'status'], effectState)
)

const finalizeJobStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('idle', ['inspectJob', inspectedEntity, 'status'], effectState)
)

const sendUploadReminderStatus$ = select(
  inspectedEntity$,
  effectState$,
  (entity, effectState) =>
    pathOr('idle', ['sendUploadReminder', entity, 'status'], effectState)
)

const isLoadingJobs$ = select(
  fetchJobsStatus$,
  fetchEngagedJobsStatus$,
  (status, engagedJobStatus) => status === 'pending' || engagedJobStatus === 'pending'
)

const isInspectingJobs$ = select(
  inspectJobStatus$,
  inspectEngagedJobStatus$,
  (status, engagedJobStatus) => status === 'pending' || engagedJobStatus === 'pending'
)
const isFinalizingJob$ = select(finalizeJobStatus$, (status) => status === 'pending')

export {
  selectedTab$,
  jobList$,
  inspectedEntity$,
  inspectedJob$,
  filterQuery$,
  isLoadingJobs$,
  isInspectingJobs$,
  fetchJobsStatus$,
  isFinalizingJob$,
  sendUploadReminderStatus$,
  jobFilters$,
}

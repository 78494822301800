import { prop, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

// eslint-disable-next-line import/no-cycle
import { organizationModule } from './module'

const { state$, effectState$ } = organizationModule

const organizationEntities$ = select(state$, prop('entities'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))

const inspectedOrganization$ = select(
  inspectedEntity$,
  organizationEntities$,
  (id, entities) => prop(id, entities)
)

const inspectOrganizationStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['inspectOrganization', 'status'], effectState)
)

const isInspectingOrganization$ = select(
  inspectOrganizationStatus$,
  (status) => status === 'pending'
)

export { inspectedOrganization$, inspectOrganizationStatus$, isInspectingOrganization$ }

import { useContext } from 'react'

import { FormLayoutContext } from '../../../common/context/FormLayoutContext'

import { FormFileUploadField } from 'components/common/Form/FormFileUploadField'
import { NewLabel as Label } from 'components/common/Form/Label'
import { FormInput } from 'components/common/FormInput'
import { Tooltip } from 'components/common/Tooltip'
import { InfoIcon } from 'components/icons'
import { styled } from 'lib/styled'

const BaseTextField = styled.div({
  marginBottom: '.5em',
})

const TextTooltip = styled.span({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '.5em',
})

const DocumentField = ({
  label,
  required,
  name,
  type = 'file',
  tooltip,
  handleOnFileDrop = () => {},
  isUploading = false,
  ...props
}) => {
  const { editing } = useContext(FormLayoutContext)

  // eslint-disable-next-line no-nested-ternary
  return editing ? (
    type === 'file' ? (
      <FormFileUploadField
        required={required}
        name={name}
        type={type}
        label={label}
        tooltip={tooltip}
        handleOnFileDrop={handleOnFileDrop}
        isUploading={isUploading}
        {...props}
      />
    ) : (
      <FormInput
        required={required}
        name={name}
        type={type}
        label={label}
        tooltip={tooltip}
        newStyle
        {...props}
      />
    )
  ) : (
    <BaseTextField>
      <Label>
        {label}
        {required && '*'}
        <Tooltip title={tooltip} placement="top">
          <TextTooltip>
            <InfoIcon />
          </TextTooltip>
        </Tooltip>
      </Label>
    </BaseTextField>
  )
}

export { DocumentField }

import { Formik as FormProvider, Form } from 'formik'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Details = styled(Form)(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

function PersonalDetails({ firstName, lastName, companyName, phoneNumber, email }) {
  return (
    <Container>
      <Header>
        <Title>Personal Details</Title>
      </Header>

      <FormProvider
        enableReinitialize
        initialValues={{
          firstName,
          lastName,
          companyName,
          phoneNumber,
          email,
        }}
      >
        <Details>
          <Column>
            <Row>
              <FormInput readOnly name="firstName" type="text" label="First name" />

              <FormInput readOnly name="lastName" type="text" label="Last name" />
            </Row>

            <Row style={apply('w-1/2')}>
              <FormInput
                readOnly
                name="companyName"
                type="text"
                label="Company name (optional)"
                containerProps={{ style: apply('mr-3') }}
              />
            </Row>

            <Row>
              <FormInput readOnly name="phoneNumber" type="text" label="Phone Number" />

              <FormInput readOnly name="email" type="text" label="E-mail" />
            </Row>
          </Column>
        </Details>
      </FormProvider>
    </Container>
  )
}

export { PersonalDetails }

const baseDurationOptions = [
  { id: 30, value: 30, label: '30 mins' },
  { id: 60, value: 60, label: '60 mins' },
  { id: 90, value: 90, label: '90 mins' },
  { id: 120, value: 120, label: '120 mins' },
  { id: 150, value: 150, label: '150 mins' },
]

const incrementDurationOptions = [
  { id: 15, value: 15, label: '15 mins' },
  { id: 30, value: 30, label: '30 mins' },
  { id: 45, value: 45, label: '45 mins' },
  { id: 60, value: 60, label: '60 mins' },
]

export { baseDurationOptions, incrementDurationOptions }

import './polyfills'
import { StylesProvider } from '@material-ui/core/styles'
import { LocalizationProvider } from '@material-ui/pickers'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { createBrowserHistory as createHistory } from 'history'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import App from 'App'
import { CONFIG } from 'config'
import { PermissionsProvider } from 'lib/permissions'
import { IdentityProvider } from 'lib/solta-id-react'

const history = createHistory()

function onRedirectCallback(appState) {
  history.push(
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  )
}

const { IDENTITY } = CONFIG

ReactDOM.render(
  <IdentityProvider
    domain={IDENTITY.DOMAIN}
    clientId={IDENTITY.CLIENT_ID}
    redirectUrl={IDENTITY.REDIRECT_URL}
    onRedirectCallback={onRedirectCallback}
  >
    <Router history={history}>
      <StylesProvider injectFirst>
        <LocalizationProvider dateAdapter={DateFnsAdapter}>
          <PermissionsProvider>
            <App />
          </PermissionsProvider>
        </LocalizationProvider>
      </StylesProvider>
    </Router>
  </IdentityProvider>,
  document.getElementById('root')
)

import {
  prop,
  defaultTo,
  isNotNil,
  isNotNilOrEmpty,
  omitBy,
  isNilOrEmpty,
} from '@seedcloud/ramda-extra'
import { format } from 'date-fns'
import moment from 'moment'
import { normalize, schema } from 'normalizr'

import { adminApi, baseApi } from 'lib/http-client'

const getFormattedDateOrUndefined = (date) => {
  const result = isNotNilOrEmpty(date) ? moment.utc(date).format() : undefined

  return result
}

const omitNilOrEmpty = (payload) => omitBy(isNilOrEmpty, payload)

const jobEntity = new schema.Entity('jobs')

const JobService = {
  async list({ limit = 10, next }) {
    const config = {
      searchParams: {
        limit,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await baseApi.get('available-jobs', config).json()
    const normalized = normalize(items, [jobEntity])
    const entities = defaultTo({}, prop('jobs', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },
  async listEngaged({ status, limit = 10, next }) {
    const config = {
      searchParams: {
        status,
        limit,
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await baseApi.get('engaged-jobs', config).json()
    const normalized = normalize(items, [jobEntity])
    const entities = defaultTo({}, prop('jobs', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      sortBy: paging.sortBy,
      sortOrder: paging.sortOrder,
    }
  },
  async read(id) {
    return baseApi.get(`available-jobs/${id}`).json()
  },

  async readEngaged(id) {
    return baseApi.get(`engaged-jobs/${id}`).json()
  },

  async publish({
    id,
    productId,
    clientId,
    pilotId,
    address,
    scheduledAt,
    description,
    instructions,
    projectId,
    radius,
  }) {
    const payload = {
      title: prop('place_name')(address),
      description,
      instructions,
      productId,
      scheduledAt: format(scheduledAt, 'yyyy-MM-dd HH:mm'),
      location: prop('geometry')(address),
      address: prop('place_name')(address),
      engagedBy: pilotId,
      orderedBy: clientId,
      projectId,
      radius,
    }

    const config = {
      json: payload,
    }

    return baseApi.patch(`organization-jobs/${id}/publish`, config).json()
  },

  async update(
    id,
    {
      scheduledAt,
      finishedAt,
      engagedAt,
      startedAt,
      location,
      pilotId,
      clientId,
      ...otherData
    }
  ) {
    const payload = {
      scheduledAt: getFormattedDateOrUndefined(scheduledAt),
      finishedAt: getFormattedDateOrUndefined(finishedAt),
      engagedAt: getFormattedDateOrUndefined(engagedAt),
      startedAt: getFormattedDateOrUndefined(startedAt),
      location: prop('geometry')(location),
      address: prop('place_name')(location),
      engagedBy: pilotId,
      orderedBy: clientId,
      ...otherData,
    }

    const config = {
      json: omitNilOrEmpty(payload),
    }

    return adminApi.patch(`organization-jobs/${id}`, config).json()
  },

  async cancel(id) {
    return baseApi.delete(`organization-jobs/${id}`)
  },

  async engage(id) {
    return baseApi.put(`available-jobs/${id}`)
  },

  finalize(id) {
    return adminApi.patch(`organization-jobs/${id}/finalize`)
  },

  sendUploadReminder(id) {
    return adminApi.post(`organization-jobs/${id}/upload-reminder/send`)
  },

  create() {
    return baseApi.post(`organization-jobs`).json()
  },
}

export { JobService }

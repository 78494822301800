import { isNotNil } from '@seedcloud/ramda-extra'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'

import { LocationField } from 'components/common/LocationField'
import { CircledMarkerLayer, Map } from 'components/common/Map'
import { SelectField } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-normal'))
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

function ServiceArea({ isEditing }) {
  const {
    values: { pilotDetails },
  } = useFormikContext()

  const [address, setAddress] = useState(pilotDetails.address)

  useEffect(() => {
    if (isNotNil(pilotDetails.address)) {
      setAddress(pilotDetails.address)
    }
  }, [pilotDetails])

  const { coordinates } = address.geometry
  const { serviceRadius } = pilotDetails

  return (
    <Container>
      <Header>
        <Title>Service Area</Title>
      </Header>

      <Details>
        <Column>
          <Row>
            <LocationField
              disabled={!isEditing}
              name="pilotDetails.address"
              label="Service Location"
              containerProps={{ style: apply('mr-3', { flexGrow: 6 }) }}
              testId="pilot-address"
            />
            <SelectField
              name="pilotDetails.serviceRadius"
              label="Survey Radius"
              options={[
                { value: 50, label: '50 km' },
                { value: 100, label: '100 km' },
                { value: 150, label: '150 km' },
                { value: 200, label: '200 km' },
              ]}
              isDisabled={!isEditing}
              containerProps={{ style: apply('ml-3') }}
            />
          </Row>

          <Row style={apply('flex-column')}>
            <Map
              centerCoords={coordinates}
              style="mapbox://styles/mapbox/streets-v9"
              containerStyle={apply(
                'rounded-lg border-0 border-b-2 border-solid border-grey',
                { height: '60vh' }
              )}
              zoom={{ 50: [8], 100: [7], 150: [6], 200: [6] }[serviceRadius]}
              type="circled-marker"
            >
              <CircledMarkerLayer
                units="kilometres"
                markerCoords={coordinates}
                radius={serviceRadius}
                visible={coordinates}
              />
            </Map>
          </Row>
        </Column>
      </Details>
    </Container>
  )
}

export { ServiceArea }

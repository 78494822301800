/* eslint-disable import/no-cycle */
import { pathOr, prop, path, map } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { documentModule } from './module'

const { state$, effectState$ } = documentModule

const documentEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))

const filterQuery$ = select(state$, prop('filterQuery'))

const sortBy$ = select(state$, prop('sortBy'))

const paging$ = select(state$, prop('paging'))

const limit$ = select(state$, prop('limit'))

const inspectedJob$ = select(state$, prop('inspectedJob'))

const jobDocumentList$ = select(order$, documentEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const fetchJobDocumentsStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchJobDocuments', 'status'], effectState)
)

const downloadJobDocumentStatus$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['downloadJobDocument', 'status'], effectState)
)
const uploadJobDocumentStatus$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['uploadJobDocument', 'status'], effectState)
)
const deleteJobDocumentStatus$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['deleteJobDocument', 'status'], effectState)
)
const renameJobDocumentStatus$ = select(effectState$, (effectState) =>
  pathOr('fulfilled', ['renameJobDocument', 'status'], effectState)
)

const isLoadingJobDocuments$ = select(
  fetchJobDocumentsStatus$,
  (status) => status === 'pending'
)

const isDownloadingJobDocument$ = select(
  downloadJobDocumentStatus$,
  (status) => status === 'pending'
)
const isUploadingJobDocument$ = select(
  uploadJobDocumentStatus$,
  (status) => status === 'pending'
)
const isDeletingJobDocument$ = select(
  deleteJobDocumentStatus$,
  (status) => status === 'pending'
)
const isRenamingJobDocument$ = select(
  renameJobDocumentStatus$,
  (status) => status === 'pending'
)
const isOperatingFile$ = select(
  isDownloadingJobDocument$,
  isUploadingJobDocument$,
  isDeletingJobDocument$,
  isRenamingJobDocument$,
  (
    isDownloadingJobDocument,
    isUploadingJobDocument,
    isDeletingJobDocument,
    isRenamingJobDocument
  ) =>
    isDownloadingJobDocument ||
    isUploadingJobDocument ||
    isDeletingJobDocument ||
    isRenamingJobDocument
)

export {
  jobDocumentList$,
  sortBy$,
  paging$,
  limit$,
  filterQuery$,
  inspectedJob$,
  isLoadingJobDocuments$,
  isDownloadingJobDocument$,
  isUploadingJobDocument$,
  isDeletingJobDocument$,
  isRenamingJobDocument$,
  isOperatingFile$,
}

import { append } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { ApplicationListItem } from './ApplicationListItem'
import { FilterInput } from './FilterInput'
import { StatusFilterSelectField } from './StatusFilterSelectField'

import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { APPLICATION_STATUSES } from 'constants/applications'
import { styled, apply } from 'lib/styled'
import {
  applicationModule,
  applicationList$,
  isFetchingApplications$,
  inspectedApplicationId$,
  filterStatus$,
} from 'modules/application'

const Container = styled.div(
  apply('flex flex-column mr-6 w-1/4 min-w-10 max-w-24 bg-white rounded-lg shadow-sm', {
    overflow: 'hidden',
  })
)

const InputContainer = styled.div(
  apply('p-4 border-0 border-b-1 border-solid border-grey-lighter')
)

const List = styled.ol(
  apply('p-0 m-0', {
    listStyleType: 'none',
    overflow: 'auto',
  })
)

const ConnectedApplicationList = connect(() => ({
  isFetchingApplications: isFetchingApplications$,
  applicationList: applicationList$,
  inspectedApplicationId: inspectedApplicationId$,
  filterStatus: filterStatus$,
}))(ApplicationList)

const { fetchApplications } = applicationModule

const onFilterStatusChanged = ({ value: filterStatus }) => {
  applicationModule.filterApplications(null, { filterStatus })
}

const APPLICATION_STATUS_DISPLAY_NAMES = {
  [APPLICATION_STATUSES.SUBMITTED]: 'Submitted',
  [APPLICATION_STATUSES.ACCEPTED]: 'Approved',
  [APPLICATION_STATUSES.REJECTED]: 'Rejected',
}

const statusOptions = append(
  { value: undefined, label: 'Any status' },
  Object.entries(APPLICATION_STATUS_DISPLAY_NAMES).map(([value, label]) => ({
    label,
    value,
  }))
)

function ApplicationList({
  isFetchingApplications,
  inspectedApplicationId,
  applicationList,
  filterStatus,
  ...props
}) {
  const history = useHistory()

  useLayoutEffect(() => {
    fetchApplications(null)
  }, [])

  return (
    <Container {...props}>
      <InputContainer>
        <FilterInput />
        <StatusFilterSelectField
          options={statusOptions}
          containerStyle={apply('mt-4 w-full')}
          onChange={onFilterStatusChanged}
          selected={filterStatus}
        />
      </InputContainer>

      <Loader
        loading={isFetchingApplications}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {applicationList.map(({ id, firstName, lastName, phoneNumber, status }) => {
            const fullName = `${firstName} ${lastName}`

            return (
              <ApplicationListItem
                key={id}
                fullName={fullName}
                phoneNumber={phoneNumber}
                status={status}
                onClick={() => history.push(`/application/${id}`)}
                isInspected={id === inspectedApplicationId}
              />
            )
          })}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedApplicationList as ApplicationList }

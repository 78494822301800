const SUPPLIER_LABELS = {
  ACTIVE: 'active',
  UPDATED_DETAILS: 'updated-information',
  PENDING_ACTIVATION: 'pending-activation',
  PENDING_PARTNERSHIP: 'pending-partnership',
  INVITED: 'invited',
}

const SUPPLIER_LABEL_DISPLAY = {
  [SUPPLIER_LABELS.ACTIVE]: 'Active',
  [SUPPLIER_LABELS.UPDATED_DETAILS]: 'Pending Updates',
  [SUPPLIER_LABELS.PENDING_ACTIVATION]: 'Pending Company Activation',
  [SUPPLIER_LABELS.INVITED]: 'Pending Company Activation',
  [SUPPLIER_LABELS.PENDING_PARTNERSHIP]: 'Pending Company Partnership',
}

const SUPPLIER_TYPES = {
  PILOT: 'pilot',
  PROFESSIONAL: 'professional',
}

const SUPPLIER_TYPES_DISPLAY_NAMES = {
  [SUPPLIER_TYPES.PILOT]: 'Pilot Services',
  [SUPPLIER_TYPES.PROFESSIONAL]: 'Professional Services',
}

const SUPPLIER_CONTACT_TYPES = {
  KEYCONTACT: 'key_contact',
  COMMERCIAL: 'commercial',
  BILLING: 'billing',
  HEROPILOT: 'hero_pilot',
  PILOT: 'pilot',
}

const SUPPLIER_CONTACT_TYPES_DISPLAY = {
  [SUPPLIER_CONTACT_TYPES.KEYCONTACT]: 'Key Contact',
  [SUPPLIER_CONTACT_TYPES.COMMERCIAL]: 'Commercial',
  [SUPPLIER_CONTACT_TYPES.BILLING]: 'Billing',
  [SUPPLIER_CONTACT_TYPES.HEROPILOT]: 'Hero Pilot',
  [SUPPLIER_CONTACT_TYPES.PILOT]: 'Pilot',
}

export {
  SUPPLIER_LABELS,
  SUPPLIER_LABEL_DISPLAY,
  SUPPLIER_TYPES,
  SUPPLIER_TYPES_DISPLAY_NAMES,
  SUPPLIER_CONTACT_TYPES_DISPLAY,
  SUPPLIER_CONTACT_TYPES,
}

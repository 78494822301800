import { styled, apply } from 'lib/styled'

export const NewLabel = styled.label({
  fontSize: '0.8125rem',
  color: '#555555',
  fontWeight: 400,
  marginBottom: 0,
  display: 'flex',
  alignItems: 'center',
})

export const Label = styled.label(
  apply('mb-2 uppercase tracking-wide text-xs text-grey-dark', {
    whiteSpace: 'nowrap',
  })
)

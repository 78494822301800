import { Route } from 'react-router-dom'

import { NotFoundPage } from './NotFoundPage'

function ProtectedRoute({ allowed, render, component, ...props }) {
  const Component = component

  if (!allowed) return <Route {...props} component={NotFoundPage} />

  return render ? (
    <Route {...props} render={render} />
  ) : (
    <Route {...props} component={Component} />
  )
}

export { ProtectedRoute }

import { map, prop, path, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { pilotModule } from './module'

const { state$, effectState$ } = pilotModule

const pilotEntities$ = select(state$, (state) => prop('entities', state))
const order$ = select(state$, (state) => prop('order', state))
const companyName$ = select(state$, (state) => prop('companyName', state))
const companyId$ = select(state$, (state) => prop('companyId', state))
const inspectedEntity$ = select(state$, (state) => prop('inspectedEntity', state))

const pilotList$ = select(order$, pilotEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedPilot$ = select(inspectedEntity$, pilotEntities$, (id, entities) =>
  prop(id, entities)
)

const filterQuery$ = select(state$, (state) => prop('filterQuery', state))

const fetchPilotsStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchPilots', 'status'], effectState)
)

const inspectPilotStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectPilot', inspectedEntity, 'status'], effectState)
)

const isLoadingPilots$ = select(fetchPilotsStatus$, (status) => status === 'pending')

const isInspectingPilot$ = select(inspectPilotStatus$, (status) => status === 'pending')

const updateIndividualPilotStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('idle', ['updateIndividualPilot', inspectedEntity, 'status'], effectState)
)

const isUpdatingIndividualPilot$ = select(
  updateIndividualPilotStatus$,
  (status) => status === 'pending'
)

const uploadAvatarStatus$ = select(effectState$, (effectState) =>
  pathOr('idle', ['uploadAvatar', 'status'], effectState)
)

const isUploadingAvatar$ = select(uploadAvatarStatus$, (status) => status === 'pending')

export {
  pilotEntities$,
  pilotList$,
  inspectedEntity$,
  inspectedPilot$,
  filterQuery$,
  isLoadingPilots$,
  isInspectingPilot$,
  companyName$,
  companyId$,
  isUpdatingIndividualPilot$,
  isUploadingAvatar$,
}

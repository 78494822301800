import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider, Form } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ProductDetailsForm } from './ProductDetails/ProductDetailsForm'
import { validationSchema } from './validationSchema'

import { CancelButton, CreateButton } from 'components/common/Button'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import { productModule, isCreatingProduct$ } from 'modules/product'

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const ButtonsContainer = styled.div(apply('flex', { marginLeft: 'auto' }))
const Scrollable = styled.div(
  apply('flex flex-column px-6 pb-6', {
    minHeight: 'min-content',
  })
)

const ConnectedCreateProduct = connect(() => ({
  isCreatingProduct: isCreatingProduct$,
}))(CreateProduct)

const { fetchProducts, createProduct } = productModule

function CreateProduct({ isCreatingProduct, ...props }) {
  const history = useHistory()
  const [isEditing, setIsEditing] = useState(true)

  return (
    <Container {...props}>
      <FormProvider
        id="createProduct"
        enableReinitialize
        initialValues={{
          name: '',
          serviceCategory: '',
          description: '',
          pricing: {
            basePrice: '',
            baseDuration: '',
            basePayoutRate: '',
            incrementPrice: '',
            incrementDuration: '',
            incrementPayoutRate: '',
          },
          isActive: true,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const { id } = await createProduct(null, values)

          fetchProducts(null, { turnPage: false })
          history.push(`/product/${id}`)
        }}
      >
        <Scrollable>
          <Form>
            <Header>
              <Title>Create New Product</Title>
              <ButtonsContainer>
                {isCreatingProduct && (
                  <Spinner size={32} thickness={6} style={{ margin: 'auto 32px' }} />
                )}
                <CreateButton disabled={isCreatingProduct} type="submit">
                  Create
                </CreateButton>
                <CancelButton
                  type="button"
                  onClick={() => history.goBack()}
                  style={apply('ml-4')}
                >
                  Cancel
                </CancelButton>
              </ButtonsContainer>
            </Header>

            <ProductDetailsForm
              isNewProduct
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </Form>
        </Scrollable>
      </FormProvider>
    </Container>
  )
}

export { ConnectedCreateProduct as CreateProduct }

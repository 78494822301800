import { APPLICATION_STATUSES } from 'constants/applications'
import { styled, apply } from 'lib/styled'

const Container = styled.span(
  apply('uppercase font-medium tracking-wide rounded-base px-2 rounded-full', {
    height: 16,
    fontSize: 10,
  }),
  ({ color }) => ({
    backgroundColor: color,
  })
)

const statusToDisplayProps = {
  [APPLICATION_STATUSES.INCOMPLETE]: {
    readableValue: 'Incomplete',
    color: '#CCC',
  },
  [APPLICATION_STATUSES.SUBMITTED]: {
    readableValue: 'Submitted',
    color: '#6495ED66',
  },
  [APPLICATION_STATUSES.ACCEPTED]: {
    readableValue: 'Approved',
    color: '#3DE37755',
  },
  [APPLICATION_STATUSES.REJECTED]: {
    readableValue: 'Rejected',
    color: 'rgba(231, 81, 81, 0.4)',
  },
}

function Status({ value, ...props }) {
  const { readableValue, color } = statusToDisplayProps[value]

  return (
    <Container color={color} {...props}>
      {readableValue}
    </Container>
  )
}

export { Status }

import { saveAs } from 'file-saver'

import { DOCUMENT_TYPES } from 'constants/documents'
import { styled, apply } from 'lib/styled'

const IconContainer = styled.a(
  apply(
    'bg-grey-light border-0 border-b-2 border-solid border-grey rounded-lg px-3 pb-1 m-2 text-black',
    { cursor: 'pointer' }
  )
)
const Icon = styled.i(apply('mr-2 text-sm text-blue-400'))

const docTypeToIcon = {
  [DOCUMENT_TYPES.USER_INSURANCE]: 'fas fa-file-contract',
  [DOCUMENT_TYPES.USER_LICENCE]: 'fas fa-id-badge',
  [DOCUMENT_TYPES.JOB_ASSET]: 'fas fa-camera-retro',
  [DOCUMENT_TYPES.USER_MISC]: 'fas fa-file-alt',
  [DOCUMENT_TYPES.USER_AVATAR]: 'fas fa-file-alt',
  [DOCUMENT_TYPES.USER_PHOTO_ID]: 'fas fa-id-badge',
}

function Document({ type, fileName, onClick }) {
  const { icon } = docTypeToIcon[type]

  return (
    <IconContainer onClick={onClick}>
      <Icon className={icon} />
      {fileName}
    </IconContainer>
  )
}

const PanelContainer = styled.section(apply('pb-6'))
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const List = styled.div(
  apply(
    'flex flex-row flex-wrap content-start p-4 max-w-32 bg-grey-lighter rounded-lg text-grey font-normal'
  )
)

function DocumentsPanel({
  documents = [],
  ownerId,
  onDownloadClick,
  title = 'Documents',
  emptyLabel = 'No documents provided',
  ...props
}) {
  return (
    <PanelContainer {...props}>
      <Header>
        <Title>{title}</Title>
      </Header>

      <List>
        {documents.length > 0
          ? documents.map(({ id: docId, fileName, contentType, documentType }) => (
              <Document
                key={docId}
                type={documentType}
                contentType={contentType}
                fileName={fileName}
                onClick={async () => {
                  saveAs(await onDownloadClick(docId, ownerId), fileName)
                }}
              />
            ))
          : emptyLabel}
      </List>
    </PanelContainer>
  )
}

export { DocumentsPanel }

import { propOr } from '@seedcloud/ramda-extra'

import { FieldGroup, Row, TextField } from 'components/company/common'

const HeroPilotContact = ({ title, heroPilot }) => (
  <FieldGroup title={title}>
    <Row>
      <TextField
        label="First Name"
        value={propOr(null, 'firstName', heroPilot)}
        name="heroPilot.firstName"
        required
      />
      <TextField
        label="Last Name"
        value={propOr(null, 'lastName', heroPilot)}
        name="heroPilot.lastName"
        required
      />
    </Row>
    <Row>
      <TextField
        label="Phone"
        value={propOr(null, 'phoneNumber', heroPilot)}
        name="heroPilot.phoneNumber"
        required
      />
      <TextField
        label="Email"
        value={propOr(null, 'email', heroPilot)}
        name="heroPilot.email"
        required
      />
    </Row>
  </FieldGroup>
)

export { HeroPilotContact }

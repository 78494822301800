import { Button } from './Button'

import { NavigationBar } from 'components/common/withNavigation/NavigationBar'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('h-full flex', {
    backgroundColor: '#F8F8F8',
  })
)
export const UnauthorizedErrorPage = () => {
  const { logout } = useIdentity()
  return (
    <div style={apply('h-full', { overflow: 'hidden' })}>
      <NavigationBar>
        <NavigationBar.Title>Pilot Portal</NavigationBar.Title>
        <NavigationBar.Logout />
      </NavigationBar>
      <Container>
        <div
          style={apply('flex flex-column items-center text-lg font-semibold', {
            margin: 'auto',
          })}
        >
          <p>
            It seems that you don&apos;t have permissions to access this application
          </p>
          <p>Please contact your administrator or IT support</p>
          <Button onClick={logout}>Log Out</Button>
        </div>
      </Container>
    </div>
  )
}

const SERVICE_CATEGORIES = [
  {
    value: 'water_service',
    readableValue: 'Water',
  },
  {
    value: 'energy_resource_service',
    readableValue: 'Energy & Resource',
  },
  {
    value: 'environment_service',
    readableValue: 'Environment',
  },
  {
    value: 'property_building_service',
    readableValue: 'Property & Building',
  },
  {
    value: 'transport_service',
    readableValue: 'Transport',
  },
  {
    value: 'photo_video_service',
    readableValue: 'Photo / Video',
  },
  {
    value: 'hd_survey_service',
    readableValue: 'HD / Survey Grade',
  },
  {
    value: 'spatial_digital_twin_service',
    readableValue: 'Spatial / Digital Twin',
  },
  {
    value: 'lidar_service',
    readableValue: 'Lidar',
  },
  {
    value: 'industry_specific_service',
    readableValue: 'Industry Specific',
  },
]

export { SERVICE_CATEGORIES }

import { isNil, insert, prop } from '@seedcloud/ramda-extra'
import { useState, useEffect, useCallback } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Link as LinkBase, useLocation } from 'react-router-dom'

import { ErrorPage } from '../ErrorPage'

import { NavigationBar } from './NavigationBar'

// eslint-disable-next-line import/no-cycle
import { getDefaultResource } from 'components/Dashboards'
import { RESOURCES } from 'constants/resources'
import { usePermissions } from 'lib/permissions'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex-1 flex flex-row h-full', { overflow: 'hidden' })
)
const SideBar = styled.div(
  apply(
    'relative z-1 bg-white flex flex-column border-0 border-r-1 border-solid border-grey-light',
    {
      overflow: 'hidden',
      transition: '0.25s width ease, 0.25s box-shadow ease',
    }
  ),
  ({ isOpen }) => ({
    width: isOpen ? 270 : 0,
    boxShadow: isOpen ? apply('shadow').boxShadow : 'none',
  })
)
const Branding = styled.div(
  apply(
    'flex items-center justify-center p-6 border-0 border-b-1 border-solid border-grey-light'
  )
)
const Logo = styled.img(apply('w-12'))
const Link = styled(LinkBase, { shouldForwardProp: (p) => p !== 'active' })(
  apply(
    'tracking-wide flex flex-row items-center text-sm font-normal p-4 pl-3 text-black border-0 border-b-1 border-solid border-grey-lighter',
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      '&:hover, &:focus': apply('no-decoration'),
    }
  ),
  ({ active }) =>
    active
      ? apply('bg-blue-100 font-semibold')
      : apply('bg-white', {
          '&:hover, &:focus': apply('bg-blue-50', { color: '#115B7E' }),
        })
)

const Icon = styled.i(
  apply('flex flex-row justify-center w-2 mr-2 text-blue-400 text-base')
)
const Content = styled.div(
  apply('flex-1 flex flex-column bg-blue-50', {
    '& > *': {
      overflow: 'hidden',
    },
  })
)

// ATTENTION: paths contain [plural, singular] form and the order is convention only.
const routes = [
  {
    resource: RESOURCES.PILOT_APPLICATION,
    title: 'Applications',
    paths: ['/applications', '/application'],
    icon: 'file-signature',
  },
  {
    resource: RESOURCES.JOB,
    title: 'Jobs',
    paths: ['/jobs', '/job'],
    icon: 'list',
  },
  {
    resource: RESOURCES.PILOT_DETAILS,
    title: 'Pilots',
    paths: ['/pilots', '/pilot'],
    icon: 'users',
  },
  {
    resource: RESOURCES.MY_PILOT_INFO,
    title: 'My Pilot Info',
    paths: ['/my-pilot-info'],
    icon: 'file-signature',
  },
  {
    resource: RESOURCES.MY_COMPANY,
    title: 'My Company',
    paths: ['/company'],
    icon: 'building',
  },
  {
    resource: RESOURCES.PRODUCT,
    title: 'Products',
    paths: ['/products', '/product'],
    icon: 'suitcase',
  },
  {
    resource: RESOURCES.STAFF,
    title: 'Staff',
    paths: ['/staff'],
    icon: 'users',
  },
  {
    resource: RESOURCES.COMPANY,
    title: 'Companies',
    paths: ['/legacy-companies'],
    icon: 'building',
  },
]

// this ensures that the rendered routes are ordered by the order specified in constants/resources
const reorderRoutes = (prev, current) => {
  const order = Object.values(RESOURCES)
  const index = order.findIndex((r) => r === current.resource)

  return insert(index, current, prev)
}

function withNavigation(WrappedComponent) {
  return function NavigationComponent(ownProps) {
    const [showSidebar, setShowSidebar] = useState(true)
    const location = useLocation()
    const { permissions, role } = usePermissions()
    const { organization } = useIdentity()
    const orgLogoSrc = prop('logo', organization) ?? '/nearbysky-logo.png'
    const visibleRoutes = routes
      .filter((r) => permissions.can('read', r.resource))
      .reduce(reorderRoutes, [])

    const defaultRoute = visibleRoutes.find(
      (route) => route.resource === getDefaultResource(role)
    )

    const [currentRoute, setCurrentRoute] = useState(defaultRoute)

    useEffect(() => {
      const newCurrentRoute = visibleRoutes.find((route) =>
        route.paths.some((path) => location.pathname.includes(path))
      )

      if (isNil(newCurrentRoute)) {
        return
      }

      setCurrentRoute(newCurrentRoute)
    }, [location])

    const onSidebarToggle = useCallback(() => {
      setShowSidebar(!showSidebar)
    })

    return (
      <Container>
        <SideBar isOpen={showSidebar}>
          <Branding>
            <Logo src={orgLogoSrc} />
          </Branding>

          {visibleRoutes.map(({ resource, paths, title, icon }) => (
            <Link
              key={resource}
              to={paths[0]}
              active={resource === currentRoute?.resource}
            >
              <Icon className={`fas fa-${icon}`} />
              {title}
            </Link>
          ))}
        </SideBar>

        <Content>
          <NavigationBar>
            <NavigationBar.Hamburger onSidebarToggle={onSidebarToggle} />
            <NavigationBar.Title>{currentRoute.title}</NavigationBar.Title>
            <NavigationBar.Logout />
          </NavigationBar>
          <ErrorBoundary FallbackComponent={ErrorPage} resetKeys={[currentRoute]}>
            <WrappedComponent {...ownProps} />
          </ErrorBoundary>
        </Content>
      </Container>
    )
  }
}

export { withNavigation }

const DOCUMENT_TYPES = {
  USER_INSURANCE: 'document_user_insurance',
  USER_LICENCE: 'document_user_licence',
  USER_AVATAR: 'document_user_avatar',
  USER_MISC: 'document_user_misc',
  USER_PHOTO_ID: 'document_user_photoid',

  JOB_ASSET: 'document_job_asset',
  JOB_JSEA: 'document_job_jsea',
  JOB_TERMS: 'document_job_terms',
  JOB_CASA: 'document_job_casa',
}

export { DOCUMENT_TYPES }

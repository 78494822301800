import { isNotNilOrEmpty, renameKeys } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useFormikContext } from 'formik'
import { useLayoutEffect } from 'react'

import { FormInput as FormInputBase } from 'components/common/FormInput'
import { SelectField } from 'components/common/SelectField'
import { styled, apply } from 'lib/styled'
import { staffModule, staffList$ } from 'modules/staff'

const Container = styled.section()
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(apply('flex-1 flex flex-row'))

const FormInput = styled(FormInputBase)(apply('mt-4 ml-0 mr-4'))

const Connected = connect(() => ({
  staffList: staffList$,
}))(ContactInfo)

const convertUserListToOptions = (list) =>
  list
    .filter(({ isActive, isBanned }) => isActive && !isBanned)
    .filter(({ userDetails: { fullName } }) => isNotNilOrEmpty(fullName))
    .map(({ id, userDetails: { phoneNumber: mobile, email, fullName } }) => ({
      id,
      value: fullName,
      label: fullName,
      mobile,
      email,
    }))

function ContactInfo({ staffList, editableClient }) {
  const { setFieldValue } = useFormikContext()

  useLayoutEffect(() => {
    if (editableClient) {
      staffModule.fetchStaff(null)
    }
  }, [])

  const clientOptions = convertUserListToOptions(
    // As the backend expects `orderedBy` as a `userId`
    // we use the `userId` as the primary identifier
    staffList.map(renameKeys({ id: 'staffId', userId: 'id' }))
  )

  return (
    <Container>
      <Header>
        <Title>Contact Info</Title>
      </Header>

      <Row>
        <Column>
          <SelectField
            name="contactInfo.client.name"
            label="Client Name"
            emptyOption={{ id: undefined, value: undefined, email: '', mobile: '' }}
            onChange={({ id, value: name, email, mobile }) => {
              setFieldValue('contactInfo.client', { id, name, email, mobile })
            }}
            options={clientOptions}
            placeholder="Search and Select Client"
            isDisabled={!editableClient}
            testId="job-client-name"
            containerProps={{ style: apply('mr-4') }}
          />
          <FormInput
            name="contactInfo.client.mobile"
            type="text"
            label="Client Mobile"
            readOnly
          />
          <FormInput
            name="contactInfo.client.email"
            type="email"
            label="Client Email"
            readOnly
          />
        </Column>
      </Row>
    </Container>
  )
}

export { Connected as ContactInfo }

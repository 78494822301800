import { useFormikContext } from 'formik'

import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { SelectField } from 'components/common/SelectField'
import { COMPANY_TYPE_DISPLAY_NAMES } from 'constants/companyTypes'
import { styled, apply } from 'lib/styled'

const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0 ml-0'),
})

const typeOptions = Object.entries(COMPANY_TYPE_DISPLAY_NAMES).map(([id, label]) => ({
  id,
  value: id,
  label,
}))

function DetailsSection({ isEditing }) {
  const { setFieldValue } = useFormikContext()

  const onSelectChange = (name) => (selectedOption) => {
    setFieldValue(name, selectedOption?.value)
  }

  return (
    <>
      <Details>
        <Column style={apply('flex-1')}>
          <Row>
            <FormInput
              name="companyName"
              type="text"
              disabled={!isEditing}
              label="Company Name"
            />
            <FormInput name="abn" type="text" disabled={!isEditing} label="abn" />
          </Row>
          <Row>
            <SelectField
              name="type"
              label="Company Type"
              type="select"
              onChange={onSelectChange('type')}
              isDisabled={!isEditing}
              options={typeOptions}
              placeholder="Select Type"
              isSearchable={false}
              containerProps={{ style: apply('flex-1') }}
            />
          </Row>
          <Row>
            <LocationField name="location" label="Address" disabled={!isEditing} />
          </Row>
          <Row>
            <FormInput
              name="termsAndConditions"
              type="text"
              disabled={!isEditing}
              label="Terms and Conditions Url"
            />
          </Row>
          <Row>
            <FormInput
              name="insuranceNumber"
              type="text"
              disabled={!isEditing}
              label="Insurance Number"
            />
            <DateField
              disabled={!isEditing}
              name="insuranceExpiredAt"
              disablePast
              type="text"
              label="Insurance Expiry"
              autoOk
            />
          </Row>
        </Column>
      </Details>
    </>
  )
}

export { DetailsSection }

import { isNotEmpty, isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { useField } from 'formik'
import { useEffect, useState } from 'react'

import { InvalidFileTypeModal } from '../InvalidFileTypeModal'
import { Spinner } from '../Spinner'
import { Tooltip } from '../Tooltip'

import { ErrorMessage } from './ErrorMessage'
import { NewLabel as Label } from './Label'

import { ReactComponent as UploadCloud } from 'assets/upload_cloud.svg'
import { InfoIcon } from 'components/icons'
import { styled, apply } from 'lib/styled'
import { useFileUpload } from 'utils/hooks'

const Root = styled.div(apply('flex flex-column'), {
  gap: '.5em',
})

const Container = styled.div(
  apply('relative flex flex-column items-center py-6 rounded-lg'),
  ({ theme, isDragActive }) => ({
    background: isDragActive ? '#CCCCCC' : 'white',
    border: '1px solid #CCCCCC',
    '& > p': {
      marginBottom: 0,
      '& > span': {
        cursor: 'pointer',
        fontWeight: 800,
        color: theme.colors.primary,
      },
    },
  })
)

const TextTooltip = styled.span({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '.5em',
})

/* eslint-disable complexity */
function FormFileUploadField({
  name,
  disabled = false,
  onUpload,
  submitCount,
  testId,
  label,
  required,
  tooltip,
  handleOnFileDrop,
  isUploading,
  ...props
}) {
  // eslint-disable-next-line no-unused-vars
  const [{ value }, _, { setValue }] = useField({ name })

  const { getRootProps, getInputProps, fileRejections, isDragActive, isDragReject } =
    useFileUpload({
      noKeyboard: true,
      disabled,
      onDropAccepted: async (files) => {
        const acceptedFiles = await handleOnFileDrop(files, value)
        setValue([...value, ...acceptedFiles])
      },
    })

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const errorMessage = 'Only PDF/DOCX files are supported'

  useEffect(() => {
    if (!disabled && isNotEmpty(fileRejections)) {
      setIsErrorModalOpen(true)
      fileRejections.pop()
    }
  }, [fileRejections, disabled])

  return (
    <Root>
      {isNotNilOrEmpty(label) && (
        <Label htmlFor={name}>
          {label}
          {required && '*'}
          <Tooltip title={tooltip} placement="top">
            <TextTooltip>
              <InfoIcon />
            </TextTooltip>
          </Tooltip>
        </Label>
      )}
      <Container {...getRootProps()} {...props} isDragActive={isDragActive}>
        <ErrorMessage visible={isDragReject} error={errorMessage} />

        {isUploading ? (
          <>
            <Spinner size={64} thickness={7} style={{ margin: 'auto 0' }} />
          </>
        ) : (
          <>
            <UploadCloud />
            <p>
              Drag and Drop, or <span>browse</span> your files
            </p>
            <input {...getInputProps()} data-test-id={testId} />
          </>
        )}

        <InvalidFileTypeModal
          isOpen={isErrorModalOpen}
          setIsOpen={setIsErrorModalOpen}
          message={errorMessage}
          title="Invalid Image Type"
        />
      </Container>
    </Root>
  )
}

export { FormFileUploadField }

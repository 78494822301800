import { baseApi, http, publicApi } from 'lib/http-client'

const CompanyService = {
  async list() {
    const response = await baseApi.get(`companies`).json()
    return response
  },
  async read(companyId) {
    return baseApi.get(`companies/${companyId}`).json()
  },
  async update(companyId, payload) {
    return baseApi.patch(`companies/${companyId}`, { json: payload }).json()
  },
  async submit(companyId) {
    return baseApi.patch(`companies/submit/${companyId}`).json()
  },
  async claim(token, sig) {
    return publicApi.get(`company-claims/${token}`, {
      searchParams: { sig },
    })
  },
  async download(id, companyId) {
    const { signedUrl } = await baseApi
      .get(`companies/${companyId}/document-download/${id}`)
      .json()

    return http.get(signedUrl).blob()
  },
  async upload(companyId, file, json, onError = () => {}, onProgress = () => {}) {
    const { signedUrl: presignedUrl, entity } = await baseApi
      .post(`companies/${companyId}/document-upload`, { json })
      .json()

    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest()

      const performAsynchronously = true
      request.open('PUT', presignedUrl, performAsynchronously)
      request.setRequestHeader('content-type', file.type)

      request.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          onProgress(event.loaded, event.total)
        }
      }

      request.onload = () => {
        const success = request.status >= 200 && request.status < 300

        if (!success) {
          reject(new Error('Failed to upload file. Please try again'))
          onError({ entity })
        } else {
          resolve({ entity })
        }
      }

      request.send(file)
    })
  },
}

export { CompanyService }

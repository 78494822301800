import { useState } from 'react'

import { CompanyDetails } from '../CompanyDetails'
import { PilotsManager } from '../pilots/PilotsManager'

import { Tabs } from './Tabs'

import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex-1 flex flex-column bg-white rounded-lg shadow-sm', {
    overflow: 'auto',
  })
)

function CompanyTabs() {
  const [selectedTab, setSelectedTab] = useState('company_details')

  return (
    <Container>
      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

      {selectedTab === 'company_details' && <CompanyDetails />}
      {selectedTab === 'pilots' && <PilotsManager />}
    </Container>
  )
}

export { CompanyTabs }

import { styled } from 'lib/styled'

export const BaseBadge = styled.span(({ status, variants }) => ({
  height: '30px',
  padding: '4px 8px',
  borderRadius: '4px',
  fontSize: '11px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...variants[status],
}))

import { connect } from '@seedcloud/stateless'
import { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

import { FilterInput } from './FilterInput'
import { PilotListItem } from './PilotListItem'

import { ReactComponent as PlusIcon } from 'assets/feathers/plus.svg'
import { Button } from 'components/common/Button'
import { Loader } from 'components/common/Loader'
import { Spinner } from 'components/common/Spinner'
import { styled, apply } from 'lib/styled'
import {
  companyModule,
  pilotList$,
  inspectedPilot$,
  isLoadingPilots$,
} from 'modules/legacy-company'

const Container = styled.div(
  apply('flex flex-column w-full mr-6 bg-white rounded-lg', {
    overflow: 'hidden',
  })
)

const InputContainer = styled.div(
  apply('flex justify-between p-4 border-0 border-b-1 border-solid border-grey-lighter')
)

const FilterInputContainer = styled.div(apply('flex-grow-1'))

const List = styled.ol(
  apply('p-0 m-0', {
    overflow: 'auto',
    listStyleType: 'none',
  })
)

const CreateButtonContainer = styled.div(apply(''))
const CreatePilotButton = styled(Button)(
  apply('flex justify-center flex-row p-2 items-center h-full ml-4', {
    width: '45px',
  })
)

const ConnectedPilotList = connect(() => ({
  pilotList: pilotList$,
  isLoadingPilots: isLoadingPilots$,
  inspectedPilot: inspectedPilot$,
}))(PilotList)

const { fetchCompanyPilots, inspectCompanyPilot } = companyModule

function PilotList({
  isLoadingPilots,
  pilotList,
  inspectedPilot,
  setIsInvitingPilot,
  ...props
}) {
  const { companyId } = useParams()
  useLayoutEffect(() => {
    fetchCompanyPilots(companyId)
  }, [])

  return (
    <Container {...props}>
      <InputContainer>
        <FilterInputContainer>
          <FilterInput />
        </FilterInputContainer>
        <CreateButtonContainer>
          <CreatePilotButton
            onClick={() => setIsInvitingPilot(true)}
            data-test-id="create-product-btn"
          >
            <PlusIcon width={17} height={17} style={apply('text-blue-700')} />
          </CreatePilotButton>
        </CreateButtonContainer>
      </InputContainer>

      <Loader
        loading={isLoadingPilots}
        renderPlaceholder={<Spinner size={32} thickness={6} style={apply('mt-4')} />}
      >
        <List>
          {pilotList.map(({ id, activatedAt, isBanned, isDeleted, userDetails }) => {
            const { fullName, phoneNumber, avatar } = userDetails
            const isInspected = id === inspectedPilot

            return (
              <PilotListItem
                key={id}
                isInspected={isInspected}
                isBanned={isBanned}
                isDeleted={isDeleted}
                fullName={fullName}
                phoneNumber={phoneNumber}
                activatedAt={activatedAt}
                avatarSrc={avatar}
                onClick={() => inspectCompanyPilot(id)}
              />
            )
          })}
        </List>
      </Loader>
    </Container>
  )
}

export { ConnectedPilotList as PilotList }

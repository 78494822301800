import { prop, defaultTo, pick, isNotNil } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { baseApi } from 'lib/http-client'
import { getCompanyAndOrganizationId } from 'utils/getCompanyId'

const pilotCategoryEntity = new schema.Entity('pilotCategories')

const PilotCategoryService = {
  async list({ next, type, limit }) {
    const { companyId, organizationId } = await getCompanyAndOrganizationId()

    const config = {
      searchParams: {
        limit,
        organizationId,
        companyId,
        type: type !== 'equipment' ? type.toUpperCase() : 'EQUIPMENT',
      },
    }

    if (isNotNil(next)) {
      config.searchParams.next = next
    }

    const { items, paging } = await baseApi.get(`pilot-categories`, config).json()
    const normalized = normalize(items, [pilotCategoryEntity])
    const entities = defaultTo({}, prop('pilotCategories', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
      limit: paging.limit,
      sortBy: paging.sortBy,
    }
  },
  async publish(payload) {
    const { companyId, organizationId } = await getCompanyAndOrganizationId()
    return baseApi
      .post(`pilot-categories`, {
        json: payload,
        searchParams: {
          organizationId,
          companyId,
        },
      })
      .json()
  },
  async update(id, payload) {
    const { companyId } = await getCompanyAndOrganizationId()
    const json = pick(['description', 'rate', 'type'], payload)

    const updatedCategory = baseApi
      .put(`pilot-categories/${id}`, {
        json,
        searchParams: {
          companyId,
        },
      })
      .json()

    return updatedCategory
  },
  async delete(id) {
    const { companyId } = await getCompanyAndOrganizationId()
    return baseApi.delete(`pilot-categories/${id}`, {
      searchParams: {
        companyId,
      },
    })
  },
}

export { PilotCategoryService }

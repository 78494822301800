import { toast, ToastContainer } from 'react-toastify'

function Toaster() {
  return {
    toast,

    error(msg, opts = {}) {
      toast.dismiss()
      return this.toast.error(msg, opts)
    },

    info(msg, opts = {}) {
      toast.dismiss()
      return this.toast.info(msg, opts)
    },

    success(msg, opts = {}) {
      toast.dismiss()
      return this.toast.success(msg, opts)
    },

    warn(msg, opts = {}) {
      toast.dismiss()
      return this.toast.warn(msg, opts)
    },
  }
}

export default new Toaster()

export const ConfigureToast = (props) => (
  <ToastContainer
    autoClose={3000}
    position="top-center"
    hideProgressBar
    newestOnTop
    limit={1}
    {...props}
  />
)

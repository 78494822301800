/* eslint-disable complexity */
import { all, isNotNilOrEmpty, isTrue, or, includes } from '@seedcloud/ramda-extra'
import configureMapbox from 'react-mapbox-gl'

import { MarkerLayer } from './MarkerLayer'

import { CONFIG } from 'config'
import { styled } from 'lib/styled'
import { getSvgMarkerOptions } from 'utils/getSvgMarkerOptions'

const MapBase = configureMapbox({
  accessToken: CONFIG.MAPBOX.ACCESS_TOKEN,
  scrollZoom: false,
})

const StyledMapBase = styled(MapBase)({
  '& canvas': {
    width: '100% !important',
  },
})

const mapTypes = ['geoJson', 'marker', 'circled-marker']

function Map({
  markerCoords = [],
  centerCoords = [],
  containerStyle,
  markerSvg,
  markerName,
  type = 'geoJson',
  children,
  ...props
}) {
  const { layerImagesOption, layoutOption } = getSvgMarkerOptions()

  function bindEventHandlers(map) {
    map.on('wheel', (event) => {
      if (event.originalEvent.ctrlKey) {
        return
      }

      if (event.originalEvent.metaKey) {
        return
      }

      if (event.originalEvent.altKey) {
        return
      }

      event.preventDefault()
    })
    // There is a brief moment between the map mounting and scroll wheel handlers
    // registering, making it possible for the user to accidentally zoom the map with the
    // mouse wheel while browsing the webpage. We handle this by initially disabling zoom,
    // and then enabling it once event handlers have been registered.
    map.scrollZoom.enable()
  }

  const noMarker = markerCoords.length === 0
  const hasMarker = or(
    all(isTrue)([
      isNotNilOrEmpty(layoutOption),
      isNotNilOrEmpty(layerImagesOption),
      !noMarker,
    ])
  )(isNotNilOrEmpty(centerCoords))
  const markerPositions = isNotNilOrEmpty(centerCoords) ? [centerCoords] : markerCoords

  if (!includes(type, mapTypes)) return null

  return (
    <StyledMapBase
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={containerStyle}
      fitBoundsOptions={type === 'geoJson' ? { padding: 24 } : undefined}
      onStyleLoad={bindEventHandlers}
      center={type.includes('marker') ? centerCoords : undefined}
      {...props}
    >
      {type !== 'marker' ? (
        children
      ) : (
        <MarkerLayer
          layoutOption={layoutOption}
          layerImagesOption={layerImagesOption}
          markerPositions={markerPositions}
          visible={hasMarker}
        />
      )}
    </StyledMapBase>
  )
}

export { Map }

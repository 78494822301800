import { JOB_STATUSES } from 'constants/jobs'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('flex justify-between items-center bg-blue-lightest mb-5'),
  {
    borderBottom: '1px #CCCCCC',
    borderStyle: 'solid',
    borderInline: 0,
    borderTop: 0,
  }
)
const TabsContainer = styled.div(apply('flex w-full'))
const Tab = styled.div(
  apply('flex justify-center py-5 w-full', {
    cursor: 'pointer',
  }),
  ({ selected, theme }) => ({
    fontWeight: 'bold',
    backgroundColor: selected ? theme.colors.primary : '#ffffff',
    color: selected ? '#ffffff' : '#555555',
    '&:hover': { backgroundColor: selected ? theme.colors.primary : '#f7fcff' },
  })
)

function Tabs({ selectedTab, setSelectedTab, filterJobs, fetchEngagedJobs }) {
  const defaultFilters = {
    limit: 15,
    paging: {
      startRow: undefined,
      next: undefined,
    },
  }

  const tabs = [
    {
      title: 'Published',
      id: 'published',
      onClick: () => {
        setSelectedTab(null, 'published')
        filterJobs(null, {
          ...defaultFilters,
          statuses: [JOB_STATUSES.PUBLISHED],
          sortBy: 'publishedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-new-tab',
    },
    {
      title: 'Engaged',
      id: 'engaged',
      onClick: () => {
        setSelectedTab(null, 'engaged')
        fetchEngagedJobs(null, {
          ...defaultFilters,
          status: JOB_STATUSES.ENGAGED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-engaged-tab',
    },
    {
      title: 'Delivered',
      id: 'delivered',
      onClick: () => {
        setSelectedTab(null, 'delivered')
        fetchEngagedJobs(null, {
          ...defaultFilters,
          status: JOB_STATUSES.DELIVERED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-delivered-tab',
    },
    {
      title: 'In Review',
      id: 'in_review',
      onClick: () => {
        setSelectedTab(null, 'in_review')
        fetchEngagedJobs(null, {
          ...defaultFilters,
          status: JOB_STATUSES.IN_REVIEW,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-in-review-tab',
    },
    {
      title: 'Completed',
      id: 'completed',
      onClick: () => {
        setSelectedTab(null, 'completed')
        fetchEngagedJobs(null, {
          ...defaultFilters,
          status: JOB_STATUSES.COMPLETED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-completed-tab',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
      onClick: () => {
        setSelectedTab(null, 'cancelled')
        fetchEngagedJobs(null, {
          ...defaultFilters,
          status: JOB_STATUSES.CANCELLED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-cancelled-tab',
    },
    {
      title: 'Disputed',
      id: 'disputed',
      onClick: () => {
        setSelectedTab(null, 'disputed')
        fetchEngagedJobs(null, {
          ...defaultFilters,
          status: JOB_STATUSES.DISPUTED,
          sortBy: 'engagedAt',
          sortOrder: 'asc',
        })
      },
      testId: 'jobs-table-disputed-tab',
    },
  ]
  return (
    <Container>
      <TabsContainer>
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            selected={selectedTab === tab.id}
            onClick={tab.onClick}
            data-test-id={tab.testId}
          >
            {tab.title}
          </Tab>
        ))}
      </TabsContainer>
    </Container>
  )
}

export { Tabs }

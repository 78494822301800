import { Link } from 'react-router-dom'

import { styled, apply } from 'lib/styled'

const LinkStyled = styled(Link)({
  color: 'unset',
  '&:hover': {
    textDecoration: 'none',
    color: 'inherit',
  },
})

const CardStyled = styled.li(
  apply(
    'relative flex flex-column justify-between px-4 py-3 rounded-lg shadow-md mb-3',
    {
      cursor: 'pointer',
      border: '1px solid #F5F5F5',
    }
  ),
  ({ isInspected, isDeleted }) =>
    apply(
      isInspected ? 'bg-blue-lightest' : 'bg-white',
      isDeleted
        ? {
            opacity: 0.2,
            pointerEvents: 'none',
          }
        : {}
    )
)

const HeadStyled = styled.div(apply('flex justify-between w-full'), {
  gap: '6px',
})

const TitleStyled = styled.span(apply('font-semibold text-lg text-near-black'), {
  maxWidth: '200px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

const FooterStyled = styled.div({
  marginTop: '1rem',
  '& > p': {
    marginBottom: 0,
    fontSize: '0.75rem',
  },
})

const Card = ({ children, href, ...props }) => (
  <LinkStyled to={href}>
    <CardStyled {...props}>{children}</CardStyled>
  </LinkStyled>
)

Card.Head = HeadStyled
Card.Title = TitleStyled
Card.Footer = FooterStyled

export { Card }

import { format } from 'date-fns'

import { Thumbnail } from './Thumbnail'

import { styled, apply } from 'lib/styled'

const Container = styled.li(
  apply(
    'relative flex flex-row items-center px-4 py-2 border-0 border-b-1 border-solid border-grey-lighter',
    {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    }
  ),
  ({ isInspected }) => apply(isInspected ? 'bg-blue-lightest' : 'bg-white')
)

const Details = styled.div(
  apply('flex-1 flex flex-column mb-1 max-w-16', { overflow: 'hidden' })
)

const Name = styled.div(apply('font-semibold text-lg text-near-black'))
const Description = styled.div(
  apply('font-light text-sm block', {
    color: '#C8C8C8',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  })
)

const UpdatedAt = styled.div(
  apply('font-light text-xs italic', {
    color: '#C8C8C8',
    '& > b': apply('font-semibold'),
  })
)

function ProductListItem({ product, ...props }) {
  const { updatedAt, name, description, productImage } = product

  return (
    <Container {...props}>
      <Thumbnail src={productImage} style={apply('mr-4')} />

      <Details>
        <Name>{name}</Name>
        <Description>{description}</Description>

        <UpdatedAt>
          Last updated: {format(new Date(updatedAt), 'dd MMM yyyy')}
        </UpdatedAt>
      </Details>
    </Container>
  )
}

export { ProductListItem }

import { Feature, Layer } from 'react-mapbox-gl'

function MarkerLayer({
  type = 'symbol',
  id = 'marker',
  layoutOption,
  layerImagesOption,
  markerPositions,
  visible,
}) {
  if (!visible) return null

  return (
    <Layer type={type} id={id} layout={layoutOption} images={layerImagesOption}>
      {markerPositions.map((coord, i) => (
        <Feature coordinates={coord} key={i} />
      ))}
    </Layer>
  )
}

export { MarkerLayer }

import { pickThenRename, isNotNil, prop, defaultTo } from '@seedcloud/ramda-extra'
import { normalize, schema } from 'normalizr'

import { adminApi } from 'lib/http-client'

const userDetailProps = ['firstName', 'lastName', 'email', 'phoneNumber', 'avatar']

const userEntity = new schema.Entity('users')

const UserService = {
  async list({ query = '', limit = 50, next }) {
    const config = {
      searchParams: {
        q: query,
        limit,
      },
    }
    if (isNotNil(next)) {
      config.searchParams.next = next
    }
    const { items, paging } = await adminApi.get('users', config).json()

    const normalized = normalize(items, [userEntity])
    const entities = defaultTo({}, prop('users', normalized.entities))
    const order = defaultTo([], normalized.result)

    return {
      entities,
      order,
      next: paging.next,
    }
  },
  async read(id) {
    return adminApi.get(`users/${id}`).json()
  },

  async update(id, updateProps) {
    const userData = pickThenRename(
      userDetailProps,
      { avatarSrc: 'avatar' },
      updateProps
    )

    const config = {
      json: userData,
    }

    return adminApi.patch(`users/${id}`, config).json()
  },
}

export { UserService }

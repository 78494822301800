import { useFormikContext } from 'formik'

import { baseDurationOptions, incrementDurationOptions } from './constants'

import imagePlaceHolder from 'assets/feathers/imgPlaceholder.svg'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { ImageUpload } from 'components/common/ImageUpload'
import { SelectField } from 'components/common/SelectField'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'
import { productModule } from 'modules/product'

const Container = styled.section()
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))
const Row = styled.div(
  apply('flex-1 flex flex-row my-2', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)
const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})
const ProductImage = styled.img(apply('w-full'))

function ProductDetailsForm({
  id,
  isNewProduct,
  isEditing,
  productImageSrc,
  ...props
}) {
  const { setFieldValue, submitCount } = useFormikContext()

  const serviceCategoryOptions = SERVICE_CATEGORIES.map(({ value, readableValue }) => ({
    id: value,
    value,
    label: readableValue,
  }))

  const onUploadProductImage = ([file]) => {
    if (isNewProduct) {
      productModule.setImageFile(null, file)
    } else {
      productModule.uploadImage(null, file, id)
    }
  }

  const onSelectChange = (name) => (selectedOption) => {
    setFieldValue(name, selectedOption?.value)
  }

  return (
    <Container {...props}>
      <Details>
        <Column>
          <ImageUpload
            imageSrc={productImageSrc}
            name="productImage"
            disabled={!isEditing}
            ImageComponent={ProductImage}
            ImagePlaceHolder={imagePlaceHolder}
            placeholderContainerProps={{ style: apply('rounded-none h-32') }}
            placeholderImageProps={{ style: apply('h-8 w-8') }}
            onUpload={onUploadProductImage}
            submitCount={submitCount}
            testId="product-image"
          />
          <Row>
            <FormInput
              name="name"
              type="text"
              disabled={!isEditing}
              label="Product Name"
              testId="product-name"
            />
            <SelectField
              name="serviceCategory"
              label="Service Category"
              type="select"
              onChange={onSelectChange('serviceCategory')}
              isDisabled={!isEditing}
              options={serviceCategoryOptions}
              placeholder="Select Product"
              isSearchable={false}
              testId="product-service-category"
            />
          </Row>
          <Row>
            <FormInput
              name="description"
              as="textarea"
              disabled={!isEditing}
              label="Description"
              testId="product-description"
            />
          </Row>
          <Row>
            <SelectField
              name="pricing.baseDuration"
              label="Standard Fly Time"
              type="select"
              isDisabled={!isEditing}
              onChange={onSelectChange('pricing.baseDuration')}
              options={baseDurationOptions}
              isSearchable={false}
              containerProps={{ style: apply('mr-3') }}
              testId="product-base-duration"
            />
            <Row style={apply('mt-0')}>
              <FormInput
                name="pricing.basePrice"
                type="number"
                min={1}
                disabled={!isEditing}
                label="Retail Price"
                testId="product-base-price"
              />
              <FormInput
                name="pricing.basePayoutRate"
                type="number"
                min={1}
                max={100}
                disabled={!isEditing}
                label="Payout Rate (%)"
                testId="product-base-payout-rate"
              />
            </Row>
          </Row>
          <Row>
            <SelectField
              name="pricing.incrementDuration"
              label="Extra Time (per package)"
              type="select"
              isDisabled={!isEditing}
              onChange={onSelectChange('pricing.incrementDuration')}
              options={incrementDurationOptions}
              isSearchable={false}
              containerProps={{ style: apply('mr-3') }}
              testId="product-increment-duration"
            />
            <Row style={apply('mt-0')}>
              <FormInput
                name="pricing.incrementPrice"
                type="number"
                min={1}
                disabled={!isEditing}
                label="Retail Price"
                testId="product-increment-price"
              />
              <FormInput
                name="pricing.incrementPayoutRate"
                type="number"
                min={1}
                max={100}
                disabled={!isEditing}
                label="Payout Rate (%)"
                testId="product-increment-payout-rate"
              />
            </Row>
          </Row>
        </Column>
      </Details>
    </Container>
  )
}

export { ProductDetailsForm }

import { createModule } from '@seedcloud/stateless'
import { toast } from 'react-toastify'

import { OrganizationService } from './service'

// eslint-disable-next-line import/no-cycle
import { getCompanyAndOrganizationId } from 'utils/getCompanyId'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  inspectedEntity: undefined,
})

const inspectOrganization = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { organizationId: id, companyId } = await getCompanyAndOrganizationId()

      module.setState({
        inspectedEntity: id,
      })

      const entity = await OrganizationService.read(id, companyId)

      module.setState({
        entities: { [id]: entity },
      })

      return entity
    },
    { errHandler: setError }
  )

const updateOrganization = (module, { setError }) =>
  withTryCatch(
    async (_, payload) => {
      const { organizationId: id, companyId } = await getCompanyAndOrganizationId()

      await OrganizationService.update(id, companyId, payload)

      const entity = await OrganizationService.read(id, companyId)

      toast.success('Company details has been updated!')

      module.setState({
        entities: { [id]: { ...entity } },
      })
    },
    { errHandler: setError }
  )

const organizationModule = createModule({
  name: 'organization',
  initialState: INITIAL_STATE,
  decorators: {
    inspectOrganization,
    updateOrganization,
  },
})

export { organizationModule }

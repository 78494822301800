import { NavigationBar } from 'components/common/withNavigation/NavigationBar'
import { styled, apply } from 'lib/styled'

const Container = styled.div(
  apply('h-full flex', {
    backgroundColor: '#F8F8F8',
  })
)
export const StaffErrorPage = () => (
  <div style={apply('h-full', { overflow: 'hidden' })}>
    <NavigationBar>
      <NavigationBar.Title>Pilot Portal</NavigationBar.Title>
      <NavigationBar.Logout />
    </NavigationBar>
    <Container>
      <div
        style={apply('flex flex-column items-center text-lg font-semibold', {
          margin: 'auto',
        })}
      >
        <p>It seems that you don&apos;t have permissions to access this application</p>
        <p>
          If you&apos;re not a pilot, please go to the{' '}
          <a href="http://admin.nearbysky.com">Admin Portal</a>
        </p>
      </div>
    </Container>
  </div>
)

import { withStyles } from '@material-ui/core/styles'
import TooltipMUI from '@material-ui/core/Tooltip'

const TooltipBase = withStyles(() => ({
  tooltip: {
    marginBottom: 8,
    maxWidth: '240px',
    borderRadius: '.5rem',
    background: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: ' 12px 16px',
    textAlign: 'center',
    fontSize: 13,
    fontFamily:
      "'Albert Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
  },
  arrow: {
    color: 'rgba(0, 0, 0, 0.8)',
  },
}))(TooltipMUI)

function Tooltip(props) {
  return <TooltipBase arrow {...props} />
}

export { Tooltip }

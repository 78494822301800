import { pick, path, omitBy, assoc } from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'

import { JobService } from './service'

import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
  inspectedEntity: undefined,
  selectedTab: 'published',
  filterQuery: undefined,
  statuses: [],
  sortBy: undefined,
  sortOrder: undefined,
  limit: 15,
  from: undefined,
  to: undefined,
  project: undefined,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const omitUnspecifiedDate = (dateObj) =>
  omitBy((date) => date === 'Unspecified', dateObj)

const setFilterParams = (module) => (_, params) => {
  const updateParams = pick(
    [
      'filterQuery',
      'statuses',
      'paging',
      'sortBy',
      'sortOrder',
      'limit',
      'from',
      'to',
      'project',
    ],
    params
  )
  module.setState(updateParams)
}

const setSelectedTab = (module) => (_, tab) => {
  module.setState({ selectedTab: tab })
}

const fetchEngagedJobs = (module, { setError }) =>
  withTryCatch(
    async (_, { status, turnPage = true, turnNext }) => {
      const { paging, limit } = module.getState()
      const next = calcNext(turnPage, turnNext, paging, limit)

      const {
        entities,
        order,
        next: newNext,
        sortBy: newSortBy,
        sortOrder: newSortOrder,
      } = await JobService.listEngaged({
        status,
        limit,
        next,
      })

      module.setState({
        entities,
        order,
        paging: {
          startRow: calcStartRow(newNext, limit, paging),
          next: newNext,
        },
        sortBy: newSortBy,
        sortOrder: newSortOrder,
      })
    },
    { errHandler: setError }
  )

const fetchJobs = (module, { setError }) =>
  withTryCatch(
    async (_, { turnPage = true, turnNext }) => {
      const {
        paging,
        filterQuery,
        statuses,
        sortBy,
        sortOrder,
        limit,
        from,
        to,
        project,
      } = module.getState()
      const next = calcNext(turnPage, turnNext, paging, limit)

      const {
        entities,
        order,
        next: newNext,
        sortBy: newSortBy,
        sortOrder: newSortOrder,
      } = await JobService.list({
        limit,
        query: filterQuery,
        statuses,
        next,
        sortBy,
        sortOrder,
        from,
        to,
        project,
      })

      module.setState({
        entities,
        order,
        filterQuery,
        statuses,
        paging: {
          startRow: calcStartRow(newNext, limit, paging),
          next: newNext,
        },
        sortBy: newSortBy,
        sortOrder: newSortOrder,
      })
    },
    { errHandler: setError }
  )

const setInspectedEntity = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      module.setState({
        inspectedEntity: id,
      })
    },
    { errHandler: setError }
  )

const inspectJob = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const newEntity = await JobService.read(id)

      // the default merge function is `mergeDeepRight`, which will not delete properties already existing on the left side. This means that if the updated `item` contains fields that have been unset, it won't be updated correctly in the state, hence the need to specify a custom merge function that replaces rather than merging the `old` item with the `new` one
      // Although we don't support unsetting fields atm, its still better to use server-side data as source of truth
      module.setState(newEntity, (state, newEntity) => ({
        ...state,
        inspectedEntity: id,
        entities: assoc(id, newEntity, state.entities),
      }))
    },
    { errHandler: setError }
  )

const inspectEngagedJob = (module, { setError }) =>
  withTryCatch(
    async (id) => {
      const newEntity = await JobService.readEngaged(id)

      // the default merge function is `mergeDeepRight`, which will not delete properties already existing on the left side. This means that if the updated `item` contains fields that have been unset, it won't be updated correctly in the state, hence the need to specify a custom merge function that replaces rather than merging the `old` item with the `new` one
      // Although we don't support unsetting fields atm, its still better to use server-side data as source of truth
      module.setState(newEntity, (state, newEntity) => ({
        ...state,
        inspectedEntity: id,
        entities: assoc(id, newEntity, state.entities),
      }))
    },
    { errHandler: setError }
  )

const filterJobs = (module) => (_, filters) => {
  module.setFilterParams(null, filters)

  module.fetchJobs(null, { turnPage: false })
}

const publishJob = (module, { setError }) =>
  withTryCatch(
    async (_, data) => {
      const { inspectedEntity: id } = module.getState()
      return JobService.publish({
        id,
        ...pick([
          'productId',
          'clientId',
          'pilotId',
          'address',
          'scheduledAt',
          'description',
          'instructions',
          'projectId',
          'radius',
        ])(data),
      })
    },
    { errHandler: setError }
  )

const updateJob = (module, { setError }) =>
  withTryCatch(
    async (id, { status, jobInfo, contactInfo }) => {
      const productId = path(['product', 'id'], jobInfo)
      const projectId = path(['project', 'id'], jobInfo)
      const clientId = path(['client', 'id'], contactInfo)
      const pilotId = path(['pilot', 'id'], contactInfo)

      const jobInfoPayload = pick(
        [
          'location',
          'radius',
          'description',
          'instructions',
          'orderedBy',
          'equipmentUsed',
          'equipmentFee',
          'calloutFee',
          'marketFee',
        ],
        jobInfo
      )

      const datePayload = omitUnspecifiedDate(
        pick(['finishedAt', 'scheduledAt', 'startedAt', 'engagedAt'], jobInfo)
      )

      const otherPayload = { productId, projectId, clientId, pilotId, status }

      const job = await JobService.update(id, {
        ...jobInfoPayload,
        ...datePayload,
        ...otherPayload,
      })

      module.inspectJob(id)

      module.setState({
        entities: {
          [id]: job,
        },
      })
    },
    { errHandler: setError }
  )

const engageJob = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { inspectedEntity: id } = module.getState()
      await JobService.engage(id)

      module.inspectEngagedJob(id)
    },
    { errHandler: setError }
  )

const finalizeJob = (module, { setError }) =>
  withTryCatch(
    async () => {
      const { inspectedEntity: id } = module.getState()
      await JobService.finalize(id)

      module.inspectJob(id)
    },
    { errHandler: setError }
  )

const sendUploadReminder = (_, { setError }) =>
  withTryCatch(
    async (id) => {
      await JobService.sendUploadReminder(id)
    },
    { errHandler: setError }
  )

const createJob = (module, { setError }) =>
  withTryCatch(
    async () => {
      const job = await JobService.create()
      module.setState({ inspectedEntity: job?.id })
      return job
    },
    { errHandler: setError }
  )

const reset = (module) => () => module.setState(INITIAL_STATE)

const jobModule = createModule({
  name: 'job',
  initialState: INITIAL_STATE,
  decorators: {
    setFilterParams,
    setSelectedTab,
    fetchJobs,
    fetchEngagedJobs,
    inspectJob,
    inspectEngagedJob,
    engageJob,
    publishJob,
    updateJob,
    filterJobs,
    setInspectedEntity,
    finalizeJob,
    sendUploadReminder,
    createJob,
    reset,
  },
})

export { jobModule }

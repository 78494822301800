import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { styled, apply } from 'lib/styled'

const Container = styled.span(apply('text-lg'))
const Icon = styled.i(apply('mr-2 text-blue-400'))

function ServiceCategory({ category, ...props }) {
  const { readableValue, iconClass } = SERVICE_CATEGORIES.find(
    ({ value }) => value === category
  )

  return (
    <Container {...props}>
      <Icon className={`fas fa-${iconClass}`} />
      {readableValue}
    </Container>
  )
}

export { ServiceCategory }

import { isNil, values, prop } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Validator from 'yup'

import { EquipmentForm } from './Fields/Equipment'
import { PilotAndAviationDetail } from './Fields/PilotAndAviationDetails'
import { ServiceArea } from './Fields/ServiceArea'
import { PilotDetailsLayout } from './PilotDetailsLayout'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { FormAction } from 'components/company/common'
import { LICENCE_TYPES } from 'constants/licenceTypes'
import { roleKey } from 'constants/roles'
import { SERVICE_CATEGORIES } from 'constants/serviceCategories'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import toaster from 'lib/toaster'
import {
  pilotModule,
  inspectedPilot$,
  isInspectingPilot$,
  isUpdatingIndividualPilot$,
} from 'modules/pilot'
import { mapboxResultSchema } from 'utils/hooks/preProcessResults'
import { transformEquipmentFields } from 'utils/transformArrayFields'

const { object, string, array, number, date, boolean } = Validator
const optionalString = (label) => string().optional().label(label)
const requiredString = (label) => string().required().label(label)

const validationSchema = object({
  licenceType: requiredString('Licence type').oneOf(values(LICENCE_TYPES)),
  licenceNumber: requiredString('Licence number'),
  licenceExpiredAt: date().min(new Date()).label('Licence expiry').required(),
  insuranceNumber: optionalString('Insurance number'),
  insuranceExpiredAt: date().min(new Date()).label('Insurance expiry').optional(),
  serviceCategories: array()
    .of(string().oneOf(SERVICE_CATEGORIES.map(prop('value'))))
    .min(1)
    .label('Service categories'),
  addressLocation: optionalString('Address Location'),
  address: mapboxResultSchema.required(),
  serviceRadius: number().required().label('Radius'),
  serviceAreas: '',
  termsAndConditionsAccepted: boolean().isTrue(),
  equipments: array()
    .of(
      object({
        name: optionalString('Equipment Name'),
        serialNumber: optionalString('Serial Number'),
      })
    )
    .optional(),
})

function useParamsOrRegisteredPilotID(user) {
  const pilotId = JSON.parse(user?.[roleKey]).pilot
  const { id } = useParams()
  if (!id && pilotId) {
    return pilotId
  }
  return id
}

const ConnectedPilotAviationDetails = connect(() => ({
  isInspectingPilot: isInspectingPilot$,
  inspectedPilot: inspectedPilot$,
  isUpdatingIndividualPilot: isUpdatingIndividualPilot$,
}))(PilotAviationDetails)

function PilotAviationDetails({
  isInspectingPilot,
  inspectedPilot,
  isUpdatingIndividualPilot,
}) {
  const { user } = useIdentity()
  const { id: paramsId } = useParams()
  const [editing, setEditing] = useState(false)

  const id = useParamsOrRegisteredPilotID(user)
  useEffect(() => {
    pilotModule.inspectPilot(id)
  }, [id])

  if (isNil(inspectedPilot)) return <PilotDetailsLayout disableTabs loading />

  const {
    userDetails,
    companyName,
    licenceType,
    licenceNumber,
    licenceExpiredAt,
    insuranceNumber,
    insuranceExpiredAt,
    serviceCategories,
    pilotCategory,
    serviceRadius,
    equipments,
  } = inspectedPilot

  const { firstName, lastName, phoneNumber, avatar } = userDetails

  const address = {
    geometry: inspectedPilot.addressLocation,
    // eslint-disable-next-line camelcase
    place_name: inspectedPilot.address,
  }

  const initialValues = {
    userDetails: {
      firstName,
      lastName,
      phoneNumber,
      email: user.email,
      avatar,
      pilotCategory: pilotCategory?.id,
      companyName,
    },
    licenceType,
    licenceNumber,
    licenceExpiredAt,
    insuranceNumber,
    insuranceExpiredAt,
    serviceCategories,
    addressLocation: '', // Transform this when placed on server
    address,
    serviceRadius,
    serviceAreas: [],
    termsAndConditionsAccepted: true, // TODO: Make page confirmation
    equipments: equipments?.length ? equipments : [{ name: '', serialNumber: '' }],
  }

  return (
    <FormLayoutProvider value={{ editing }}>
      <FormProvider
        id="pilotDetails"
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const filteredValues = transformEquipmentFields(values)
          const payload = { ...values, ...filteredValues }
          await pilotModule.updateIndividualPilot(id, payload)
          toaster.success('Update Success!')
          setEditing(false)
        }}
      >
        {({ values, resetForm }) => (
          <PilotDetailsLayout
            disableTabs={editing}
            loading={isInspectingPilot}
            footer={
              editing && (
                <FormAction
                  loading={isUpdatingIndividualPilot}
                  loadingText="Updating..."
                  okText="Save"
                  cancelText="Cancel"
                  onCancel={() => {
                    resetForm()
                    setEditing(false)
                  }}
                />
              )
            }
          >
            <PilotAndAviationDetail
              showButtonEdit={!paramsId}
              setEditing={setEditing}
              values={values}
            />
            <EquipmentForm
              values={values.equipments}
              name="equipments"
              title="Equipment Form"
            />
            <ServiceArea />
          </PilotDetailsLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

const PaddedContainer = styled.div(apply('flex-1 flex flex-row p-6'))

function PilotAviationDetailsWithContainer() {
  return (
    <PaddedContainer>
      <ConnectedPilotAviationDetails />
    </PaddedContainer>
  )
}

export {
  ConnectedPilotAviationDetails as PilotAviationDetails,
  PilotAviationDetailsWithContainer,
}

import { BaseBadge } from 'components/common/Badge'
import { PILOT_STATUS, PILOT_STATUS_DISPLAY } from 'constants/pilots'

export const variants = {
  [PILOT_STATUS.ACTIVE]: {
    backgroundColor: '#E0F0E8',
    color: '#005027',
  },
  [PILOT_STATUS.PENDING]: {
    backgroundColor: '#FAF7E7',
    color: '#AC9100',
  },
}

const PilotBadge = ({ isActive }) => {
  const status = isActive ? PILOT_STATUS.ACTIVE : PILOT_STATUS.PENDING
  const labelText = PILOT_STATUS_DISPLAY[status]
  return (
    <BaseBadge status={status} variants={variants}>
      {labelText}
    </BaseBadge>
  )
}

export { PilotBadge }

/* eslint-disable complexity */
import { HTTPError } from 'ky'

import toast from 'lib/toaster'
import { authModule } from 'modules/auth'

const formatApiError = ({ statusCode = '', error = '', message = '' } = {}) =>
  `${statusCode} ${error}: ${message}`

const formatJSError = (err) =>
  `Something went wrong. Please provide support with this error: ${err.toString()}`

const isDev = process.env.NODE_ENV === 'development'
const isHttpError = (err) => err instanceof HTTPError

/**
 *
 * @param {function} func async function to be wrapped
 * @param {object} opts allow a logger (such as Sentry) to be specified, toasting to be disabled, and an optional onError callback that receives the parsed error & the first argument to uniquely track the error (as per Stateless's convention)
 * @returns whatever the async function returns
 */
export const withTryCatch =
  (func, { logger = console, toastError = true, errHandler = () => {} } = {}) =>
  async (...args) => {
    try {
      return await func(...args)
    } catch (error) {
      if (isDev) {
        logger.error(error)
      }

      let parsedError
      let errMessage
      if (isHttpError(error)) {
        parsedError = await error.response.json()
        if (parsedError.statusCode === 403 && parsedError.message === 'BannedAccount') {
          authModule.setUserBanStatus(null, true)
        }
        errMessage = formatApiError(parsedError)
      } else {
        parsedError = error
        errMessage = formatJSError(parsedError)
      }

      if (toastError) toast.error(errMessage)

      return errHandler(args[0] ?? null, errMessage)
    }
  }

import { circle as drawCircle, lineString as drawLine } from '@turf/turf'
import { Layer, Source, Marker } from 'react-mapbox-gl'

import { ReactComponent as LocationMarker } from 'assets/location_maker.svg'

function CircledMarkerLayer({
  markerCoords,
  visible = true,
  radius,
  units = 'meters',
}) {
  if (!visible) return null

  const circle = drawCircle(markerCoords, radius, { units })
  const circumference = drawLine(...circle.geometry.coordinates)

  return (
    <>
      <Marker coordinates={markerCoords} anchor="center">
        <LocationMarker width={40} height={40} />
      </Marker>

      <Source
        id="circle-source"
        geoJsonSource={{
          type: 'geojson',
          data: circle,
        }}
      />
      <Layer
        id="inner-fill"
        type="fill"
        sourceId="circle-source"
        paint={{
          'fill-color': '#1C9DDA',
          'fill-opacity': 0.3,
          'fill-outline-color': 'yellow',
        }}
      />
      <Source
        id="circumference-source"
        geoJsonSource={{ type: 'geojson', data: circumference }}
      />
      <Layer
        id="circumference-layer"
        type="line"
        sourceId="circumference-source"
        paint={{
          'line-color': '#1C9DDA',
          'line-width': 2,
        }}
      />
    </>
  )
}

export { CircledMarkerLayer }

import { useContext } from 'react'

import { FormLayoutContext } from '../../../common/context/FormLayoutContext'

import { FormInput } from 'components/common/FormInput'
import { styled, apply } from 'lib/styled'

const BaseTextField = styled.div(apply('flex flex-column justify-center items-start'), {
  gap: '1em',
})

const TextLabel = styled.span({
  fontSize: '0.8125rem',
  color: '#555555',
  fontWeight: 400,
})

const TextValue = styled.p({
  fontSize: '1.0625rem',
  color: '#000000',
  fontWeight: 400,
  lineHeight: '20.4px',
  marginBottom: '14px',
  overflowWrap: 'break-word',
  wordBreak: 'break-all',
})

// eslint-disable-next-line complexity
const TextField = ({
  label,
  value,
  required,
  name,
  type = 'text',
  asInput = false,
  ...props
}) => {
  const { editing, disabled } = useContext(FormLayoutContext)

  return editing || asInput ? (
    <FormInput
      required={required}
      name={name}
      type={type}
      label={label}
      newStyle
      disabled={disabled}
      {...props}
    />
  ) : (
    <BaseTextField>
      <TextLabel>
        {label}
        {required && label && '*'}
      </TextLabel>
      <TextValue>{value || 'No Data'}</TextValue>
    </BaseTextField>
  )
}

export { TextField }

import { connect } from '@seedcloud/stateless'

import { SearchInput } from 'components/common/SearchInput'
import { pilotModule, filterQuery$ } from 'modules/pilot'

const ConnectedFilterInput = connect(() => ({
  filterQuery: filterQuery$,
}))(FilterInput)

const { filterPilots } = pilotModule

function FilterInput({ filterQuery }) {
  return (
    <SearchInput
      value={filterQuery}
      placeholder="Enter email, name or phone number"
      onChange={filterPilots}
    />
  )
}

export { ConnectedFilterInput as FilterInput }

/* eslint-disable complexity */

import { pickThenRename } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useFormikContext as useFormContext } from 'formik'
import { useLayoutEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { UploadPanel } from '../documents/UploadPanel'
import { JobDocuments } from '../JobDocuments'

import { CompanyModal } from './CompanyModal'

import { Button } from 'components/common/Button'
import { DateField } from 'components/common/DateField'
import { FormInput as FormInputBase } from 'components/common/FormInput'
import { LocationField } from 'components/common/LocationField'
import { PriceBlock } from 'components/common/PriceBlock'
import { SelectField } from 'components/common/SelectField'
import { TextAreaField } from 'components/common/TextAreaField'
import { Toggle as ToggleBase } from 'components/common/Toggle'
import { AreaOfInterest, ComplianceSection } from 'components/jobs/common'
import { DOCUMENT_TYPES } from 'constants/documents'
import { JOB_STATUSES } from 'constants/jobs'
import { apply, styled } from 'lib/styled'
import { documentModule } from 'modules/document'
import { companyModule, companyList$ } from 'modules/legacy-company'
import { pilotModule, pilotList$ } from 'modules/pilot'
import { pilotCategoryModule, pilotCategoriesList$ } from 'modules/pilotCategory'
import { productModule, productList$, filterQuery$ } from 'modules/product'

const Container = styled.div(apply('mb-12'))
const Header = styled.div(apply('flex flex-row py-6'))
const Title = styled.h2(apply('m-0 text-xl font-light'))
const Details = styled.div(apply('flex-1 flex flex-row'))
const Column = styled.div(apply('flex-1 flex flex-column'))

const Row = styled.div(
  apply('flex flex-row my-2 items-center', {
    '&:first-of-type': apply('mt-0'),
    '&:last-of-type': apply('mb-0'),
  })
)

const FormInput = styled(FormInputBase)({
  '&:first-of-type': apply('ml-0'),
  '&:last-of-type': apply('mr-0'),
})

const PricingToggleContainer = styled.div(apply('flex my-3'))
const PricingContainer = styled.div(apply(''))
const Label = styled.label(apply('mb-2 uppercase tracking-wide text-xs text-grey-dark'))
const Toggle = styled(ToggleBase)(apply('mr-2'))
const ToggleContainer = styled.div(apply('flex pr-8'))

const ConnectedJobInfo = connect(() => ({
  productList: productList$,
  companyList: companyList$,
  pilotList: pilotList$,
  pilotCategoriesList: pilotCategoriesList$,
  filterQuery: filterQuery$,
}))(JobInfo)

function JobInfo({
  productList,
  companyList,
  pilotList,
  pilotCategoriesList,
  jobReference,
  isEditing,
  jobStatus,
}) {
  const fileUploaderRef = useRef(null)
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)

  const {
    setFieldValue,
    values: {
      jobInfo: {
        radius,
        location,
        commercialSettings,
        publishType,
        pricing,
        publishedBy,
        company,
        reference,
        uploadCode,
      },
      documents: { uploadedByPilot },
    },
  } = useFormContext()

  const { billable, paid, dataUpload } = commercialSettings
  const { timeMaterial, quote } = pricing

  const coordinates = location?.geometry?.coordinates

  const productOptions = productList
    .filter((product) => product.isActive)
    .map(({ id, name: label }) => ({
      id,
      value: label,
      label,
    }))

  const companyOptions = companyList.map(({ id, companyName, email, phoneNumber }) => ({
    id,
    value: companyName,
    label: companyName,
    email,
    phoneNumber,
  }))

  const pilotOptions = pilotList.map(({ id, userDetails }) => ({
    id,
    value: userDetails.fullName,
    label: userDetails.fullName,
  }))

  const formatCurrency = (value, ...opts) =>
    new Intl.NumberFormat('au-AU', {
      style: 'currency',
      currency: 'AUD',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      ...opts,
    }).format(value)

  const pilotCategoryOptions = pilotCategoriesList.map(({ id, description, rate }) => ({
    id,
    value: id,
    label: `${description} ${formatCurrency(rate)} / hr`,
  }))

  const publishTypeOptions = [
    { id: 'internal', value: 'internal', label: 'Internal' },
    { id: 'market', value: 'market', label: 'Market' },
  ]

  const ratingsOptions = [
    { id: '1', value: '1', label: '1' },
    { id: '2', value: '2', label: '2' },
    { id: '3', value: '3', label: '3' },
    { id: '4', value: '4', label: '4' },
    { id: '5', value: '5', label: '5' },
  ]

  const { id } = useParams()
  useLayoutEffect(() => {
    productModule.fetchProducts(null)
    companyModule.fetchCompanies(null)
    pilotModule.fetchPilots(null, { includeCompanyPilots: true })
    pilotCategoryModule.fetchPilotCategories(null, { turnPage: false })

    // we use filterJobDocuments instead of fetchJobDocuments to be explicit about fetching ALL job document types.
    documentModule.filterJobDocuments(id, {
      documentTypes: [
        DOCUMENT_TYPES.JOB_TERMS,
        DOCUMENT_TYPES.JOB_JSEA,
        DOCUMENT_TYPES.JOB_CASA,
        DOCUMENT_TYPES.JOB_ASSET,
      ],
    })

    return () => documentModule.reset()
  }, [])

  const jobEngaged = [
    JOB_STATUSES.ENGAGED,
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobDelivered = [
    JOB_STATUSES.DELIVERED,
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobInReview = [
    JOB_STATUSES.IN_REVIEW,
    JOB_STATUSES.COMPLETED,
    JOB_STATUSES.DISPUTED,
  ].includes(jobStatus)

  const jobCompleted = [JOB_STATUSES.COMPLETED].includes(jobStatus)

  return (
    <Container>
      <Header>
        <Title>Job Info</Title>
      </Header>

      <Details>
        <Column>
          <Row>
            <FormInput
              disabled
              name="jobInfo.reference"
              type="text"
              label="Reference"
            />

            <DateField
              disabled={true}
              name="jobInfo.createdAt"
              type="text"
              label="Created At"
              autoOk
            />
          </Row>

          <Label>Commercial settings</Label>
          <Row>
            <ToggleContainer>
              <Toggle
                onChange={(e) =>
                  setFieldValue('jobInfo.commercialSettings.billable', e.target.checked)
                }
                checked={billable}
                disabled={!isEditing}
              />
              <Label>Billable</Label>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                onChange={(e) =>
                  setFieldValue('jobInfo.commercialSettings.paid', e.target.checked)
                }
                checked={paid}
                disabled={!isEditing}
              />
              <Label>Paid</Label>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                onChange={(e) =>
                  setFieldValue(
                    'jobInfo.commercialSettings.dataUpload',
                    e.target.checked
                  )
                }
                checked={dataUpload}
                disabled={!isEditing}
              />
              <Label>Data Upload</Label>
            </ToggleContainer>
          </Row>
          <Row>
            <SelectField
              name="jobInfo.product.name"
              label="Product Name"
              isDisabled={!isEditing}
              onChange={({ id, value: name }) => {
                setFieldValue('jobInfo.product', { id, name })
              }}
              options={productOptions}
              placeholder="Search and Select Product"
              onInputChange={productModule.filterProducts}
              containerProps={{ style: apply('mr-3') }}
            />

            <DateField
              disabled={!isEditing}
              name="jobInfo.scheduledAt"
              type="text"
              label="Scheduled At"
              autoOk
            />
          </Row>

          <Row>
            <LocationField
              name="jobInfo.location"
              label="Location"
              disabled={!isEditing}
              containerProps={{ style: apply('mr-3', { flexGrow: 6 }) }}
            />
            <FormInput
              name="jobInfo.radius"
              label="Radius (meters)"
              disabled={!isEditing}
            />
          </Row>

          <AreaOfInterest coordinates={coordinates} radius={radius} />

          {jobStatus !== JOB_STATUSES.NEW && (
            <>
              <TextAreaField
                disabled={!isEditing}
                name="jobInfo.description"
                label="description"
                containerStyle={apply('mt-4 mr-4 w-full')}
                minRows={6}
              />
              <TextAreaField
                disabled={!isEditing}
                name="jobInfo.instructions"
                label="Instructions (optional)"
                containerStyle={apply('mt-4 mr-4 w-full')}
                minRows={6}
              />

              {/* we force the component to remount since the form resets on submit, clearing out the docs inside formik state */}
              <ComplianceSection key={isEditing} disabled={!isEditing} />

              <Row>
                <SelectField
                  name="jobInfo.publishType"
                  label="Publish Type"
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.publishType', selectedOption.value)
                  }}
                  options={publishTypeOptions}
                  placeholder="Select publish type"
                  containerProps={{ style: apply('mr-3') }}
                  isDisabled={!isEditing}
                  testId="job-company"
                />
                {publishType === 'internal' && (
                  <SelectField
                    name="jobInfo.selectedPilot.name"
                    label="Recommend Pilot"
                    emptyOption={{
                      id: undefined,
                      value: undefined,
                    }}
                    onChange={({ id, value: name }) => {
                      setFieldValue('jobInfo.selectedPilot', { id, name })
                    }}
                    options={pilotOptions}
                    placeholder="Select Pilot"
                    isDisabled={!isEditing}
                  />
                )}
                {publishType === 'market' && (
                  <SelectField
                    name="jobInfo.selectedPilotCategory.name"
                    label="Pilot Category"
                    emptyOption={{
                      id: undefined,
                      value: undefined,
                    }}
                    onChange={({ id, value: name }) => {
                      setFieldValue('jobInfo.selectedPilotCategory', { id, name })
                    }}
                    options={pilotCategoryOptions}
                    placeholder="Select Pilot Category"
                    isDisabled={!isEditing}
                    testId="job-client-name"
                  />
                )}
              </Row>
              <Row>
                <PricingContainer>
                  <Label>Job Pricing</Label>
                  <PricingToggleContainer>
                    <ToggleContainer>
                      <Toggle
                        onChange={(e) =>
                          setFieldValue(
                            'jobInfo.pricing.timeMaterial',
                            e.target.checked
                          )
                        }
                        checked={timeMaterial}
                        disabled={!isEditing}
                      />
                      <Label>Time & Material</Label>
                    </ToggleContainer>
                    <ToggleContainer>
                      <Toggle
                        onChange={(e) =>
                          setFieldValue('jobInfo.pricing.quote', e.target.checked)
                        }
                        checked={quote}
                        disabled={!isEditing}
                      />
                      <Label>Quote</Label>
                    </ToggleContainer>
                  </PricingToggleContainer>
                </PricingContainer>
                <FormInput
                  disabled={!isEditing}
                  name="jobInfo.pricing.budget"
                  type="text"
                  label="Budget"
                />
              </Row>

              {publishedBy && (
                <Row>
                  <FormInput
                    disabled
                    name="jobInfo.publishedBy.fullName"
                    type="text"
                    label="Published By"
                  />
                  <DateField
                    disabled
                    name="jobInfo.publishedAt"
                    type="text"
                    label="Published At"
                    autoOk
                  />
                </Row>
              )}
            </>
          )}

          {jobEngaged && (
            <>
              <Header>
                <Title>Engaged By</Title>
              </Header>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.engagedBy.fullName"
                  type="text"
                  label="Engaged By"
                />
                <FormInput
                  disabled
                  name="jobInfo.engagedBy.email"
                  type="text"
                  label="Email"
                />
              </Row>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.engagedBy.phoneNumber"
                  type="text"
                  label="Phone Number"
                />
                <DateField
                  disabled
                  name="jobInfo.engagedAt"
                  type="text"
                  label="Engaged At"
                  autoOk
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.company.companyName"
                  label="Customer"
                  onChange={(selectedOption) => {
                    setFieldValue(
                      'jobInfo.company',
                      pickThenRename(['id', 'value', 'email', 'phoneNumber'], {
                        value: 'companyName',
                      })(selectedOption)
                    )
                  }}
                  options={companyOptions}
                  placeholder="Select customer"
                  isDisabled={!isEditing}
                  testId="job-company"
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.company.email"
                  label="Customer Email"
                  testId="job-company-email"
                  disabled
                />
                <FormInput
                  name="jobInfo.company.phoneNumber"
                  label="Customer Phone Number"
                  testId="job-company-phone-number"
                  disabled
                />
              </Row>
              <Row>
                <FormInput
                  name="jobInfo.company.type"
                  label="Company Type"
                  testId="job-company-type"
                  disabled
                />
              </Row>
              {company && (
                <Button
                  type="button"
                  style={apply('my-4')}
                  onClick={() => setIsCompanyModalOpen(true)}
                >
                  View Company
                </Button>
              )}
            </>
          )}

          {jobDelivered && (
            <>
              <Row>
                <FormInput
                  disabled
                  name="jobInfo.uploadCode"
                  type="text"
                  label="upload code"
                />
              </Row>
              <Header>
                <Title>Job Asssets</Title>
              </Header>
              <UploadPanel
                jobId={id}
                reference={reference}
                uploadCode={uploadCode}
                files={uploadedByPilot}
                ref={fileUploaderRef}
              />
              <JobDocuments
                type="pilot"
                downloadJobDocument={documentModule.downloadJobDocument}
                jobReference={jobReference}
              />
              <Header>
                <Title>Timesheet</Title>
              </Header>
              <Row>
                <Column>
                  <FormInput
                    name="jobInfo.equipmentUsed"
                    type="text"
                    label="Equipment Used"
                    disabled={jobCompleted}
                  />
                  <FormInput
                    containerProps={{ style: apply('ml-0') }}
                    name="jobInfo.equipmentFee"
                    type="text"
                    label="Equipment Fee"
                    disabled={jobCompleted}
                  />
                  <FormInput
                    containerProps={{ style: apply('ml-0') }}
                    name="jobInfo.calloutFee"
                    type="text"
                    label="Callout Fee"
                    disabled={jobCompleted}
                  />
                  <FormInput
                    containerProps={{ style: apply('ml-0 mr-3') }}
                    name="jobInfo.marketFee"
                    type="text"
                    label="Market Fee"
                    disabled={jobCompleted}
                  />
                </Column>
                <PriceBlock isEditing={!jobCompleted} style={apply('my-4')} />
              </Row>
            </>
          )}
          {jobInReview && (
            <>
              <Header>
                <Title>Staff Review</Title>
              </Header>
              <JobDocuments
                type="staff"
                downloadJobDocument={documentModule.downloadJobDocument}
                jobReference={jobReference}
              />
            </>
          )}

          {jobCompleted && (
            <>
              <Header>
                <Title>Rating & Feedback</Title>
              </Header>
              <Row>
                <SelectField
                  name="jobInfo.ratings.communication"
                  label="Pilot Communication"
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.ratings.communication', selectedOption.value)
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-communication"
                  isDisabled
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.ratings.mediaAndPhotoQuality"
                  label="Media & Photo Quality"
                  onChange={(selectedOption) => {
                    setFieldValue(
                      'jobInfo.ratings.mediaAndPhotoQuality',
                      selectedOption.value
                    )
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-mediaAndPhotoQuality"
                  isDisabled
                />
              </Row>
              <Row>
                <SelectField
                  name="jobInfo.ratings.overall"
                  label="Job Satisfaction"
                  onChange={(selectedOption) => {
                    setFieldValue('jobInfo.ratings.overall', selectedOption.value)
                  }}
                  options={ratingsOptions}
                  placeholder="Select a score"
                  containerProps={{ style: apply('mr-3') }}
                  testId="job-ratings-overall"
                  isDisabled
                />
              </Row>
              <Row>
                <TextAreaField
                  name="jobInfo.feedback"
                  label="Feedback"
                  containerStyle={apply('mt-4 mr-4 w-full')}
                  minRows={6}
                  disabled
                />
              </Row>
            </>
          )}
        </Column>
      </Details>

      <ReactTooltip />
      {company && (
        <CompanyModal
          isOpen={isCompanyModalOpen}
          setIsOpen={setIsCompanyModalOpen}
          companyId={company.id}
        />
      )}
    </Container>
  )
}

export { ConnectedJobInfo as JobInfo }

import { isNotNilOrEmpty } from '@seedcloud/ramda-extra'

import radiusValidator from './radiusValidator'

import { ReactComponent as ExclamationCircleIcon } from 'assets/exclamation-circle.svg'
import { CircledMarkerLayer, Map } from 'components/common/Map'
import { styled, apply } from 'lib/styled'

const MapPlaceholder = styled.div(
  apply('block w-full flex justify-center items-center bg-grey-100 rounded-md', {
    height: '75vh',
  })
)
export const AreaOfInterest = ({ coordinates, radius }) => {
  const parsedRadius = radiusValidator.isValidSync(radius)
    ? radiusValidator.validateSync(radius)
    : // since the circle drawing needs a number, just make it very small where users can't see it even at max zoom in case the radius is invalid
      0.1

  // eslint-disable-next-line complexity
  const getZoomLevel = (parsedRadius) => {
    switch (true) {
      case parsedRadius <= 150:
        return 17
      case parsedRadius <= 300:
        return 16
      case parsedRadius <= 500:
        return 15
      case parsedRadius <= 1000:
        return 14
      case parsedRadius <= 2400:
        return 13
      case parsedRadius <= 4500:
        return 12
      case parsedRadius <= 20000:
        return 10
      default:
        return 9
    }
  }

  return coordinates ? (
    <Map
      centerCoords={coordinates ?? [0, 0]}
      containerStyle={apply('block w-full', { height: '75vh' })}
      zoom={[getZoomLevel(parsedRadius)]}
      type="circled-marker"
    >
      <CircledMarkerLayer
        markerCoords={coordinates}
        radius={parsedRadius}
        visible={isNotNilOrEmpty(coordinates)}
      />
    </Map>
  ) : (
    <MapPlaceholder>
      <ExclamationCircleIcon {...apply('h-2 w-2', { stroke: '#334155' })} />
      <h5 style={apply('m-0 ml-1 mb-1')}>Please select a location</h5>
    </MapPlaceholder>
  )
}

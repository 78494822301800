import { Field } from 'formik'
import { useState } from 'react'

import { Button } from 'components/common/Button'
import { FileIcon, LinkIcon } from 'components/icons'
import { styled } from 'lib/styled'

const FileFieldContainer = styled.div(({ editing }) => ({
  width: '100%',
  fontSize: '1.0625rem',
  padding: '0 1rem',
  borderRadius: '.5em',
  color: '#000000',
  fontWeight: 400,
  lineHeight: '20.4px',
  background: editing ? 'transparent' : '#EEF1F5',
  border: editing ? '1px solid #CCCCCC' : 'none',
  display: 'flex',
  alignItems: 'center',
  gap: '1em',
  minHeight: '40px',
}))

const FileFieldContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
  position: 'relative',
  overflow: 'hidden',
})

const FileFieldExtra = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const ActionButton = styled(Button)({
  background: 'transparent',
  border: 'none',
  boxShadow: 'none',
  padding: 0,
  textTransform: 'none',
})

const FileFieldTitle = styled(Field)({
  all: 'unset',
  marginBottom: 0,
  fontSize: '0.9375rem',
})

const EmptyFileFieldTitle = styled.div({
  all: 'unset',
  marginBottom: 0,
  fontSize: '0.9375rem',
})

const FileURL = styled.p({
  cursor: 'pointer',
  fontSize: '0.6875rem',
  color: '#00326F',
  textDecoration: 'underline',
  lineHeight: '0,8125rem',
  marginBottom: 0,
})

const FileURLLink = styled.a({
  cursor: 'pointer',
  fontSize: '0.6875rem',
  color: '#00326F',
  textDecoration: 'underline',
  lineHeight: '0,8125rem',
  marginBottom: 0,
})

// eslint-disable-next-line complexity
const FileItem = ({ id = '123', name, url, editing, type = 'file', handleClick }) => {
  const [renaming, setRenaming] = useState(false)

  const handleRenaming = () => {
    if (!renaming) {
      document.getElementById(`${name}.name`).focus()
    }

    setRenaming((renaming) => !renaming)
  }

  return (
    <FileFieldContainer editing={editing}>
      {type === 'file' ? <FileIcon /> : <LinkIcon />}
      <FileFieldContent>
        <FileFieldTitle
          id={`${name}.name`}
          name={`${name}.name`}
          readOnly={!editing && !renaming}
        />
        {type === 'file' ? (
          <FileURL onClick={() => handleClick(id)}>{url}</FileURL>
        ) : (
          <FileURLLink target="_blank" href={url}>
            {url}
          </FileURLLink>
        )}
      </FileFieldContent>
      {editing ? (
        <FileFieldExtra>
          <ActionButton type="button" onClick={handleRenaming}>
            {renaming ? 'Save' : 'Rename'}
          </ActionButton>
        </FileFieldExtra>
      ) : null}
    </FileFieldContainer>
  )
}

const EmptyFileItem = ({ type }) => (
  <FileFieldContainer>
    <FileFieldContent>
      <EmptyFileFieldTitle>
        No {type === 'file' ? 'Documents' : 'Links'}
      </EmptyFileFieldTitle>
    </FileFieldContent>
  </FileFieldContainer>
)

export { FileItem, EmptyFileItem }

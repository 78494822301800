import { ThemeProvider } from '@emotion/react'
import { equals } from '@seedcloud/ramda-extra'
import { memo } from 'react'

import { Dashboards } from 'components/Dashboards'
import { styled, apply } from 'lib/styled'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/vendors.scss'
import 'styles/index.css'
import { ConfigureToast } from 'lib/toaster'

const Container = styled.div(
  apply('h-full flex flex-column', {
    backgroundColor: '#F8F8F8',
  })
)

const theme = {
  colors: {
    primary: '#009DE4',
  },
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Dashboards />
        <ConfigureToast />
      </Container>
    </ThemeProvider>
  )
}

export default memo(App, equals)

import { isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { Field, useField } from 'formik'

import { ErrorMessage } from './Form/ErrorMessage'
import { NewLabel, Label } from './Form/Label'
import { Tooltip } from './Tooltip'

import { InfoIcon } from 'components/icons'
import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 flex flex-column mx-3'), ({ newStyle }) => ({
  gap: newStyle ? '.5em' : undefined,
  marginLeft: newStyle ? 0 : undefined,
  marginRight: newStyle ? 0 : undefined,
}))

const Input = styled(Field)(
  apply('bg-grey-100 text-lg text-black border-0 rounded-md px-3 py-2', {
    // NOTE: The :read-only pseudo-selector in CSS
    //       will match both `readonly` and `disabled` elements.
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
    },
  })
)

const NewInput = styled(Field)(
  apply('border text-black rounded-lg w-full', {
    border: '1px solid #CCCCCC',
    padding: '.5rem 1rem',
    fontSize: '0.9375rem',
    height: '40px',
    '&:read-only': {
      color: 'rgba(0, 0, 0, 0.38)',
      cursor: 'default',
    },
  })
)

const TextTooltip = styled.span({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '.5em',
})

// eslint-disable-next-line complexity
function FormInput({
  id,
  name,
  label,
  className,
  containerProps,
  testId,
  newStyle,
  required,
  tooltip,
  ...props
}) {
  // eslint-disable-next-line no-unused-vars
  const [_, { touched, error }] = useField({ name, id, ...props })

  const InputComp = newStyle ? NewInput : Input
  const LabelComp = newStyle ? NewLabel : Label

  return (
    <Container className={className} {...containerProps} newStyle={newStyle}>
      {isNotNilOrEmpty(label) && (
        <LabelComp htmlFor={name}>
          {label} {required && '*'}
          {tooltip ? (
            <Tooltip title={tooltip} placement="top">
              <TextTooltip>
                <InfoIcon />
              </TextTooltip>
            </Tooltip>
          ) : null}
        </LabelComp>
      )}
      <InputComp name={name} {...props} data-test-id={testId} id={id ?? name} />

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  )
}

export { FormInput }

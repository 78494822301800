export const LICENCE_TYPES = {
  REOC: 'licence_type_reoc',
  REPL: 'licence_type_repl',
  SUB_2_KG: 'licence_type_sub_2_kg',
  FIXED_WING: 'licence_type_fixed_wing',
  OTHER: 'licence_type_other',
}

export const LICENCE_TYPE_DISPLAY_NAMES = {
  [LICENCE_TYPES.REOC]: 'ReOC',
  [LICENCE_TYPES.REPL]: 'RePL',
  [LICENCE_TYPES.SUB_2_KG]: 'Sub-2 Kg',
  [LICENCE_TYPES.FIXED_WING]: 'Fixed Wing',
  [LICENCE_TYPES.OTHER]: 'Other',
}

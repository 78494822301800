import { prop } from '@seedcloud/ramda-extra'
import Select from 'react-select'

import { styled, apply } from 'lib/styled'

const Container = styled.div(apply('flex-1 mr-2'), prop('containerStyle'))

const styles = {
  control: (_, state) => {
    const basicStyle = apply(
      'flex flex-row border-0 rounded-lg bg-grey-lighter text-lg'
    )
    const disabledStyle = state.isDisabled
      ? apply('bg-grey-lightest', {
          pointerEvents: 'none',
          cursor: 'not-allowed',
        })
      : {}

    return { ...basicStyle, ...disabledStyle }
  },

  option: (provided, { isSelected }) => ({
    ...provided,
    ...apply('bg-grey-lightest', {
      '&:hover': apply('bg-grey-light'),
    }),
    ...apply(isSelected ? 'text-blue-light' : 'text-black'),
  }),

  dropdownIndicator: () => apply('p-0 px-2 relative', { top: 1 }),

  valueContainer: (provided) => ({
    ...provided,
    ...apply('px-3 py-2'),
  }),

  indicatorSeparator: () => apply('hidden'),

  singleValue: (provided) => ({
    ...provided,
    ...apply('text-black'),
  }),

  placeholder: (provided) => ({
    ...provided,
    ...apply('text-grey'),
  }),
}

function StatusFilterSelectField({
  id,
  name,
  label,
  type = 'select',
  selected,
  onChange = () => {},
  options = [],
  containerStyle,
  testId = 'pilot-status-filter',
  ...props
}) {
  return (
    <Container containerStyle={containerStyle} data-test-id={`${testId}-container`}>
      <Select
        value={options.find(({ value }) => value === selected)}
        type={type}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        styles={styles}
        isSearchable={false}
        classNamePrefix={testId}
        instanceId={testId}
        {...props}
      />
    </Container>
  )
}

export { StatusFilterSelectField }

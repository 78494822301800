import { locationMarkerSvg } from 'assets/locationMarker'

const getSvgMarkerOptions = (
  svg = locationMarkerSvg,
  markerName = 'locationMarker'
) => {
  const layoutOption = { 'icon-image': markerName }

  const image = new Image()
  image.src = `data:image/svg+xml;charset=utf-8;base64,${btoa(svg)}`
  const layerImagesOption = [markerName, image]

  return { layerImagesOption, layoutOption }
}

export { getSvgMarkerOptions }

import { useState } from 'react'

function useFileModal() {
  const [modalData, setModalData] = useState({
    isOpened: false,
    fileName: '',
    fileId: '',
  })

  return [modalData, setModalData]
}

export { useFileModal }

import { prop } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

// eslint-disable-next-line import/no-cycle
import { authModule } from './module'

const { state$ } = authModule

const isBanned$ = select(state$, prop('isBanned'))
const user$ = select(state$, prop('user'))

export { isBanned$, user$ }

import { connect } from '@seedcloud/stateless'
import { Formik as FormProvider } from 'formik'
import { useState, useLayoutEffect } from 'react'
import * as Validator from 'yup'

import { Details } from './Fields/Details'
import { Document } from './Fields/Document'
import { Equipment } from './Fields/Equipment'
import { Insurance } from './Fields/Insurance'
import { License } from './Fields/License'
import { Links } from './Fields/Links'

import { FormLayoutProvider } from 'components/common/context/FormLayoutContext'
import { CompanyLayout, FieldGroup, Row, FormAction } from 'components/company/common'
import {
  aviationDetail$,
  companyDetail$,
  companyModule,
  invitationStatus$,
  isInspectingCompany$,
  isSubmitting$,
  supplierType$,
} from 'modules/company'
import { inspectedOrganization$, isInspectingOrganization$ } from 'modules/organization'
import { transformArrayFields } from 'utils/transformArrayFields'
import { transformDateFields } from 'utils/transormDateFields'

const { object, string, array, date } = Validator

const requiredString = (label) => string().required().label(label)
const optionalString = (label) => string().optional().label(label)

const validationSchema = object({
  country: requiredString('Company Country'),
  companyArn: requiredString('Company ARN').matches(
    /^\d+$/,
    'ARN should only contain number'
  ),
  licenses: array().of(
    object().shape({
      licenseType: requiredString('License type'),
      licenseNumber: requiredString('License Number'),
      licenseIssuedAt: date().label('Licence issue').optional().nullable(),
      licenseExpiredAt: date()
        .min(new Date())
        .label('Licence expiry')
        .optional()
        .nullable(),
    })
  ),
  insuranceProvider: requiredString('Insurance Provider'),
  insuranceNumber: requiredString('Insurance Number'),
  insuranceIssuedAt: date().label('Insurance issue date').optional().nullable(),
  insuranceExpiredAt: date()
    .min(new Date())
    .label('Insurance expiry date')
    .optional()
    .nullable(),
  equipments: array()
    .of(
      object().shape({
        category: optionalString('Equipment Category'),
        make: optionalString('Equipment make'),
        model: optionalString('Equipment model'),
      })
    )
    .optional(),
  links: array()
    .of(
      object().shape({
        name: optionalString('Links name'),
        url: optionalString('Links url'),
      })
    )
    .optional(),
  documents: array()
    .of(
      object().shape({
        name: optionalString('Documents name'),
        id: optionalString('Documents id'),
        url: optionalString('Documents url'),
      })
    )
    .optional(),
})

const AviationDetail = ({
  aviationDetail = {},
  isInspectingCompany,
  isSubmitting,
  type,
  status,
  isInspectingOrganization,
  organization = {},
}) => {
  const [editing, setEditing] = useState(false)

  useLayoutEffect(() => {
    companyModule.inspectCompany()
  }, [])

  const {
    country,
    companyArn,
    documents,
    links,
    licenses,
    insuranceNumber,
    insuranceProvider,
    insuranceIssuedAt,
    insuranceExpiredAt,
    equipments,
  } = aviationDetail

  const initialValues = {
    country,
    companyArn,
    licenses,
    insuranceNumber,
    insuranceProvider,
    insuranceIssuedAt,
    insuranceExpiredAt,
    equipments,
    documents,
    links,
  }

  const handleSubmit = async (values, { resetForm }) => {
    let payload = transformArrayFields(values)
    payload = transformDateFields(
      [
        'insuranceIssuedAt',
        'insuranceExpiredAt',
        'licenseIssuedAt',
        'licenseExpiredAt',
      ],
      payload
    )
    await companyModule.updateCompany(null, payload)
    setEditing(false)
    resetForm()
  }

  return (
    <FormLayoutProvider value={{ editing, setEditing }}>
      <FormProvider
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ resetForm, values }) => (
          <CompanyLayout
            loading={isInspectingCompany || isInspectingOrganization}
            type={type}
            status={status}
            company={organization.name ?? ''}
            footer={
              editing && (
                <FormAction
                  loading={isSubmitting}
                  loadingText="Updating..."
                  okText="Save"
                  onCancel={() => {
                    setEditing(false)
                    resetForm()
                  }}
                />
              )
            }
          >
            <Details
              editing={editing}
              setEditing={setEditing}
              title="Aviation Details"
              values={{ country, companyArn }}
            />
            <License name="licenses" title="Licence" values={values.licenses} />
            <Insurance
              title="Insurance"
              values={{
                insuranceNumber,
                insuranceProvider,
                insuranceIssuedAt,
                insuranceExpiredAt,
              }}
            />
            <Equipment
              name="equipments"
              title="Equipments"
              values={values.equipments}
            />
            <FieldGroup title="Documents & Links">
              <Row>
                <Document values={values.documents} />
                <Links values={values.links} />
              </Row>
            </FieldGroup>
          </CompanyLayout>
        )}
      </FormProvider>
    </FormLayoutProvider>
  )
}

const ConnectedAviationDetail = connect(() => ({
  companyDetails: companyDetail$,
  aviationDetail: aviationDetail$,
  isInspectingCompany: isInspectingCompany$,
  isSubmitting: isSubmitting$,
  status: invitationStatus$,
  type: supplierType$,
  organization: inspectedOrganization$,
  isInspectingOrganization: isInspectingOrganization$,
}))(AviationDetail)

export { ConnectedAviationDetail as AviationDetail }

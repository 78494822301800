import { useContext } from 'react'

import { Heading } from '../Heading'

import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import { styled, apply } from 'lib/styled'

const FieldGroupContainer = styled.div(({ noMargin }) => ({
  marginBottom: '1.5em',
  '& > div:not(:first-of-type)': {
    marginBottom: noMargin ? 0 : undefined,
  },
}))

const FieldContainer = styled.div(
  apply('flex flex-wrap items-start'),
  ({ editing }) => ({
    gap: !editing ? '1.5em' : '0.5em 1.5em',
    marginBottom: editing ? '1.5em' : '.5em',
    '& > div': {
      flex: `1`,
    },
  })
)

const FieldGroup = ({ extra, title, children, noMargin, ...props }) => (
  <FieldGroupContainer noMargin={noMargin} {...props}>
    <Heading extra={extra}>{title}</Heading>
    {children}
  </FieldGroupContainer>
)

const Row = ({ children }) => {
  const { editing } = useContext(FormLayoutContext)

  return <FieldContainer editing={editing}>{children}</FieldContainer>
}

export { FieldGroup, Row }

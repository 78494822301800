import { isNotNilOrEmpty } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import { useState } from 'react'

import { InvitePilot } from './InvitePilot'
import { PilotDetails } from './PilotDetails'
import { PilotList } from './PilotList'

import { inspectedPilot$ } from 'modules/legacy-company'

const ConnectedPilotsManager = connect(() => ({
  inspectedPilot: inspectedPilot$,
}))(PilotsManager)

function PilotsManager({ inspectedPilot }) {
  const [isInvitingPilot, setIsInvitingPilot] = useState(false)

  if (isInvitingPilot) {
    return <InvitePilot setIsInvitingPilot={setIsInvitingPilot} />
  }

  return (
    <div>
      {isNotNilOrEmpty(inspectedPilot) ? (
        <PilotDetails />
      ) : (
        <PilotList setIsInvitingPilot={setIsInvitingPilot} />
      )}
    </div>
  )
}

export { ConnectedPilotsManager as PilotsManager }

import { createContextualCan } from '@casl/react'
import { connect } from '@seedcloud/stateless'
import { useState, createContext, useContext, useEffect } from 'react'
import { useLocation, matchPath } from 'react-router-dom'

import { defineAbilityFor } from './defineAbilityFor'

import { PilotOnboardingPage } from 'components/common/onboarding/PilotOnboardingPage'
import { Spinner } from 'components/common/Spinner'
import { StaffErrorPage } from 'components/common/StaffErrorPage'
import { UnauthorizedErrorPage } from 'components/common/UnauthorizedErrorPage'
import { RESOURCES } from 'constants/resources'
import { ROLES } from 'constants/roles'
import { useIdentity } from 'lib/solta-id-react'
import { styled, apply } from 'lib/styled'
import { isBanned$ } from 'modules/auth'

const { PILOT_APPLICATION, PILOT_DETAILS, PRODUCT, JOB, FILE, STAFF, COMPANY } =
  RESOURCES

const SpinnerContainer = styled.div(
  apply('h-full flex flex-column items-center justify-center')
)

export const PermissionsContext = createContext()
export const usePermissions = () => useContext(PermissionsContext)

// eslint-disable-next-line complexity
const PermissionsProvider = ({ isBanned, children }) => {
  const { loading, isAuthenticated, loginWithRedirect, role } = useIdentity()
  const [isOnboarded, setIsOnboarded] = useState(false)
  const { pathname } = useLocation()

  const isClaimPage = matchPath(pathname, '/claim/:token')

  useEffect(() => {
    if (!isAuthenticated && !loading && !isClaimPage) {
      loginWithRedirect()
    }
  }, [loading, isAuthenticated, loginWithRedirect])

  if ((loading || !isAuthenticated) && !isClaimPage) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (isOnboarded && !isClaimPage)
    return (
      <PermissionsContext.Provider
        value={{ permissions: defineAbilityFor(role), role }}
      >
        {children}
      </PermissionsContext.Provider>
    )

  if (role === ROLES.ONBOARDING_PILOT && !isClaimPage)
    return <PilotOnboardingPage setIsOnboarded={setIsOnboarded} />

  if (isBanned) return <UnauthorizedErrorPage />

  if (
    ![
      ROLES.PILOT,
      ROLES.COMMERCIAL,
      ROLES.BILLING,
      ROLES.HERO_PILOT,
      ROLES.KEY_CONTACT,
    ].includes(role) &&
    !isClaimPage
  )
    return <StaffErrorPage />

  // ATTENTION: we need to pass the result of defineAbilityFor into value as is,
  // so that the Can binding using createContextualCan below works.
  // See https://github.com/stalniy/casl/blob/master/packages/casl-react/README.md
  return (
    <PermissionsContext.Provider value={{ permissions: defineAbilityFor(role), role }}>
      {children}
    </PermissionsContext.Provider>
  )
}

const Can = createContextualCan(PermissionsContext.Consumer)
const CanFactory =
  (action, resource) =>
  // eslint-disable-next-line react/display-name
  ({ children, ...props }) =>
    (
      <Can do={action} on={resource} {...props}>
        {children}
      </Can>
    )

const ConnectedProvider = connect(() => ({
  isBanned: isBanned$,
}))(PermissionsProvider)

// These wrappers hide/show UI component based on whether the user can perform the action
const CanCreateProduct = CanFactory('create', PRODUCT)
const CanUpdateProduct = CanFactory('update', PRODUCT)
const CanUpdatePilotApplication = CanFactory('update', PILOT_APPLICATION)
const CanUpdatePilot = CanFactory('update', PILOT_DETAILS)
const CanReadPilot = CanFactory('read', PILOT_DETAILS)
const CanCreateJob = CanFactory('create', JOB)
const CanReadFile = CanFactory('read', FILE)
const CanDeleteFile = CanFactory('delete', FILE)
const CanUpdateFile = CanFactory('update', FILE)
const CanCreateFile = CanFactory('create', FILE)
const CanCreateStaff = CanFactory('create', STAFF)
const CanCreateCompany = CanFactory('create', COMPANY)

export {
  ConnectedProvider as PermissionsProvider,
  Can,
  CanCreateProduct,
  CanUpdateProduct,
  CanUpdatePilotApplication,
  CanUpdatePilot,
  CanCreateJob,
  CanReadFile,
  CanUpdateFile,
  CanDeleteFile,
  CanCreateFile,
  CanCreateStaff,
  CanCreateCompany,
  CanReadPilot,
}

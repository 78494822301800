import { evolve, identity, ifElse, isArray, map } from '@seedcloud/ramda-extra'

import { formatDate } from './formatDate'

export const transformDateFields = (fields, payload) => {
  const transformation = Object.fromEntries(
    fields.map((field) => [field, (x) => (x ? formatDate(x, 'yyyy-MM-dd') : undefined)])
  )
  return evolve(
    transformation,
    map(ifElse(isArray, map(evolve(transformation)), identity), payload)
  )
}

import { pluck } from '@seedcloud/ramda-extra'
import * as Validator from 'yup'

import {
  baseDurationOptions,
  incrementDurationOptions,
} from './ProductDetails/constants'

import { SERVICE_CATEGORIES } from 'constants/serviceCategories'

const { object, string, number, mixed } = Validator
const numberRequired = number().required()
const mixedRequired = mixed().required()
const stringRequired = string().required()

export const productPricingSchema = object({
  basePrice: numberRequired.min(1).label('Retail price'),
  baseDuration: mixedRequired
    .oneOf(pluck('value', baseDurationOptions))
    .label('Fly time'),
  basePayoutRate: numberRequired.min(1).max(100).label('Payout rate'),
  incrementPrice: numberRequired.min(1).label('Retail price'),
  incrementDuration: mixedRequired
    .oneOf(pluck('value', incrementDurationOptions))
    .label('Extra time'),
  incrementPayoutRate: numberRequired.min(1).max(100).label('Payout rate'),
})

export const validationSchema = object({
  name: stringRequired.label('Name'),
  serviceCategory: mixedRequired
    .oneOf(pluck('value', SERVICE_CATEGORIES))
    .label('Service category'),
  description: stringRequired.label('Description'),
  pricing: productPricingSchema,
})

import { isEmpty, isNil } from '@seedcloud/ramda-extra'

// https://en.wikipedia.org/wiki/Shapefile#Overview
export const shapefileExtensions = [
  'shp',
  'shx',
  'dbf',
  'prj',
  'sbn',
  'sbx',
  'fbn',
  'fbx',
  'ain',
  'aih',
  'ixs',
  'mxs',
  'atx',
  'cpg',
  'qix',
]

export const SHAPEFILE_CONTENT_TYPE = 'x-gis/x-shapefile'

export const parseContentType = (source = {}, detectedType = '') => {
  if (isNil(source.name)) return ''

  const ext = source.name.split('.').splice(-1)[0]
  const contentType = shapefileExtensions.includes(ext) ? SHAPEFILE_CONTENT_TYPE : ''

  return isEmpty(contentType) ? detectedType : contentType
}

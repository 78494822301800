/* eslint-disable camelcase */
import { prop, path, pickThenRename, filter } from '@seedcloud/ramda-extra'

import { DOCUMENT_TYPES } from 'constants/documents'
import { safelyParseDate } from 'utils/safelyParseDate'

const buildMapboxGeoJson = (coordinates, address) => ({
  geometry: {
    coordinates,
    type: 'Point',
  },
  place_name: address,
})

const formatCurrency = (value, ...opts) =>
  new Intl.NumberFormat('au-AU', {
    style: 'currency',
    currency: 'AUD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...opts,
  }).format(value)

const getJobInfo = ({
  reference,
  createdAt,
  scheduledAt,
  template,
  commercialSettings,
  company,
  product,
  description = 'No description',
  instructions = 'No instructions',
  engagedAt,
  startedAt,
  finishedAt,
  uploadCode,
  engagedBy,
  internalProjectId,
  publishType,
  selectedPilot,
  selectedPilotCategory,
  publishedBy,
  publishedAt,
  pricing,
  acceptTermsConditions,
  equipmentUsed,
  equipmentFee,
  calloutFee,
  marketFee,
  ratings,
  feedback,
  address,
  radius,
  project,
  location: jobLocation,
}) => ({
  reference,
  createdAt: safelyParseDate(createdAt),
  scheduledAt: safelyParseDate(scheduledAt),
  startedAt: safelyParseDate(startedAt),
  engagedAt: safelyParseDate(engagedAt),
  finishedAt: safelyParseDate(finishedAt),
  template,
  commercialSettings,
  description,
  instructions,
  uploadCode,
  engagedBy,
  internalProjectId,
  publishType,
  selectedPilot: {
    id: prop('id', selectedPilot),
    name: path(['userDetails', 'fullName'], selectedPilot),
  },
  selectedPilotCategory: {
    id: prop('id', selectedPilotCategory),
    name: `${prop('description', selectedPilotCategory)} ${formatCurrency(
      prop('rate', selectedPilotCategory)
    )} / hr`,
  },
  pricing,
  publishedBy,
  publishedAt: safelyParseDate(publishedAt),
  company,
  acceptTermsConditions,
  equipmentUsed,
  equipmentFee,
  calloutFee,
  marketFee,
  ratings,
  feedback,
  product,
  project,
  radius,
  location: buildMapboxGeoJson(jobLocation?.coordinates, address),
})

const getJobDocuments = (documents) => {
  const pilotUploadedDocuments = filter(
    (doc) => doc.uploadedByModel === 'Pilot',
    documents
  )
  const organizationUploadedDocuments = filter(
    (doc) =>
      doc.uploadedByModel !== 'Pilot' && doc.documentType === DOCUMENT_TYPES.JOB_ASSET,
    documents
  )
  return {
    uploadedByPilot: pilotUploadedDocuments,
    uploadedByOrganization: organizationUploadedDocuments,
  }
}

const getContactInfo = ({ orderedBy }) => {
  const client = pickThenRename(
    ['id', 'fullName', 'phoneNumber', 'email'],
    {
      fullName: 'name',
      phoneNumber: 'mobile',
    },
    orderedBy ?? {}
  )

  return { client }
}

export default function (inspectedJob, jobDocumentList) {
  const { orderedBy, ...inspectedJobData } = inspectedJob

  const jobInfo = getJobInfo(inspectedJobData)
  const documents = getJobDocuments(jobDocumentList)
  const contactInfo = getContactInfo({ orderedBy })

  return { jobInfo, documents, contactInfo }
}

import moment from 'moment'
import { useMemo } from 'react'

const toHumanReadableDate = (date) => moment(date).format('DD/MM/YY hh:mm A')

export const Rows = (jobList) =>
  useMemo(() =>
    jobList.map((job) => ({
      id: job?.id,
      reference: job?.reference,
      product: job?.product?.name,
      orderedBy: job?.orderedBy?.fullName,
      clientMobile: job?.orderedBy?.phoneNumber,
      description: job?.description,
      pilotName: job?.engagedBy?.fullName,
      pilotMobile: job?.engagedBy?.phoneNumber,
      location: job?.address,
      uploadCode: job?.uploadCode,
      createdAt: toHumanReadableDate(job?.createdAt),
      scheduledAt: toHumanReadableDate(job?.scheduledAt),
      engagedAt: toHumanReadableDate(job?.engagedAt),
      startedAt: toHumanReadableDate(job?.startedAt),
      finishedAt: toHumanReadableDate(job?.finishedAt),
      cancelledAt: toHumanReadableDate(job?.cancelledAt),
    }))
  )

/* eslint-disable import/no-cycle */
import {
  isNilOrEmpty,
  path,
  filter,
  append,
  mergeDeepRight,
  pick,
} from '@seedcloud/ramda-extra'
import { createModule } from '@seedcloud/stateless'

import { jobModule } from '../job'
import { UploadService } from '../upload'

import { DocumentService } from './service'

import { DOCUMENT_TYPES } from 'constants/documents'
import { calcNext } from 'utils/calcNext'
import { calcStartRow } from 'utils/calcStartRow'
import { withTryCatch } from 'utils/withTryCatch'

const INITIAL_STATE = Object.freeze({
  entities: {},
  order: [],
  inspectedEntity: undefined,
  filterQuery: undefined,
  documentTypes: [],
  sortBy: undefined,
  sortOrder: undefined,
  limit: 15,
  paging: {
    startRow: undefined,
    next: undefined,
  },
})

const fetchJobDocuments = (module, { setError }) =>
  withTryCatch(
    async (_, jobId, { turnPage = false, turnNext } = {}) => {
      const { paging, filterQuery, sortBy, sortOrder, limit, documentTypes } =
        module.getState()
      const next = calcNext(turnPage, turnNext, paging, limit)

      const {
        entities,
        order,
        next: newNext,
      } = await DocumentService.fetchJobDocuments(jobId, {
        q: filterQuery,
        next,
        sortBy,
        sortOrder,
        documentTypes,
      })

      module.setState({
        entities,
        order,
        paging: {
          startRow: calcStartRow(newNext, limit, paging),
          next: newNext,
        },
      })
    },
    { errHandler: setError }
  )

const filterJobDocuments = (module) => (jobId, params) => {
  module.setFilterParams(null, params)

  module.fetchJobDocuments(null, jobId, { turnPage: false })
}

const downloadJobDocument = (_, { setError }) =>
  withTryCatch(
    async (id) => {
      const { entities: jobEntities, inspectedEntity: inspectedJobEntity } =
        jobModule.getState()
      const inspectedJob = path([inspectedJobEntity], jobEntities)

      return DocumentService.downloadJobDocument(id, inspectedJob.id)
    },
    { errHandler: setError }
  )

const uploadJobDocument = (module, { setError }) =>
  withTryCatch(
    async (_, { file, documentType }) => {
      const { inspectedEntity: jobId } = jobModule.getState()

      const { entity: newDocument } = await UploadService.uploadFile(
        file,
        `organization-jobs/${jobId}/upload-documents/`,
        { fileName: file.name, fileSize: file.size, documentType },
        ({ entity }) => {
          DocumentService.deleteJobDocument(entity.id, entity.jobId)
        }
      )

      module.setState((prevState) => ({
        entities: mergeDeepRight(prevState.entities, {
          [newDocument.id]: newDocument,
        }),
        order: append(newDocument.id, prevState.order),
      }))

      return newDocument
    },
    { errHandler: setError }
  )

const confirmJobDocumentUpload = (module, { setError }) =>
  withTryCatch(
    async (
      id,
      { req, reference, defaultHeaders, documentId, fileSize, onUploaded, onError } = {}
    ) => {
      await UploadService.confirmUpload(
        req,
        reference,
        defaultHeaders,
        documentId,
        fileSize,
        onUploaded,
        onError
      )
      module.filterJobDocuments(id, {
        documentTypes: [
          DOCUMENT_TYPES.JOB_TERMS,
          DOCUMENT_TYPES.JOB_JSEA,
          DOCUMENT_TYPES.JOB_CASA,
          DOCUMENT_TYPES.JOB_ASSET,
        ],
      })
    },
    { errHandler: setError }
  )

const deleteJobDocument = (module, { setError }) =>
  withTryCatch(
    async (id, { onDone } = {}) => {
      if (isNilOrEmpty(id)) return
      const { entities, order } = module.getState()

      const { entities: jobEntities, inspectedEntity: inspectedJobEntity } =
        jobModule.getState()
      const inspectedJob = path([inspectedJobEntity], jobEntities)

      await DocumentService.deleteJobDocument(id, inspectedJob.id)

      delete entities[id]
      const updatedOrder = filter((v) => v !== id, order)

      if (onDone) onDone()

      module.setState({
        entities,
        order: updatedOrder,
      })
    },
    { errHandler: setError }
  )

const renameJobDocument = (module, { setError }) =>
  withTryCatch(
    async (id, newFileName) => {
      const { inspectedEntity: inspectedJobId } = jobModule.getState()

      const { document } = await DocumentService.renameJobDocument(
        id,
        inspectedJobId,
        newFileName
      )

      module.setState((prevState) => ({
        entities: mergeDeepRight(prevState.entities, { [document.id]: document }),
      }))
    },
    { errHandler: setError }
  )

const setFilterParams = (module) => (_, params) => {
  const updateParams = pick(
    ['filterQuery', 'paging', 'sortBy', 'sortOrder', 'limit', 'documentTypes'],
    params
  )
  module.setState(updateParams)
}

const reset = (module) => () => module.setState(INITIAL_STATE)

const documentModule = createModule({
  name: 'document',
  initialState: INITIAL_STATE,
  decorators: {
    setFilterParams,
    fetchJobDocuments,
    filterJobDocuments,
    confirmJobDocumentUpload,
    downloadJobDocument,
    uploadJobDocument,
    deleteJobDocument,
    renameJobDocument,
    reset,
  },
})

export { documentModule }

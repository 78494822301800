import { useContext } from 'react'

import { Button } from 'components/common/Button'
import { FormLayoutContext } from 'components/common/context/FormLayoutContext'
import {
  FieldGroup,
  Row,
  TextField,
  SelectField,
  DateField,
} from 'components/company/common'
import { LICENCE_TYPE_DISPLAY_NAMES } from 'constants/licenceTypes'
import { formatDate } from 'utils/formatDate'

// eslint-disable-next-line complexity
function PilotAndAviationDetail({ values, setEditing, showButtonEdit }) {
  const { editing } = useContext(FormLayoutContext)
  const licenceTypes = Object.entries(LICENCE_TYPE_DISPLAY_NAMES).map(
    ([value, label]) => ({
      label,
      value,
    })
  )

  return (
    <FieldGroup
      title={'Pilot Aviation Details'}
      extra={
        !editing && showButtonEdit ? (
          <Button onClick={() => setEditing(true)}>Edit</Button>
        ) : null
      }
    >
      <Row>
        <SelectField
          label="Licence Type"
          name="licenceType"
          value={
            editing
              ? values.licenceType
              : LICENCE_TYPE_DISPLAY_NAMES[values.licenceType]
          }
          options={licenceTypes}
          required
        />
      </Row>

      <Row>
        <TextField
          label="Licence Number"
          value={values.licenceNumber}
          name="licenceNumber"
          type="text"
          required
        />
        <DateField
          label="Licence Expiry"
          name="licenceExpiredAt"
          value={values.licenceExpiredAt ? formatDate(values.licenceExpiredAt) : ''}
          autoOk
          required
        />
      </Row>
      <Row>
        <TextField
          label="Insurance Number"
          value={values.insuranceNumber}
          name="insuranceNumber"
          type="text"
        />
        <DateField
          label="Insurance Expiry"
          name="insuranceExpiredAt"
          value={
            values.insuranceExpiredAt ? formatDate(values.insuranceExpiredAt) : null
          }
        />
      </Row>
    </FieldGroup>
  )
}

export { PilotAndAviationDetail }

import { clone, noop } from '@seedcloud/ramda-extra'
import Select from 'react-select'

import { styled, apply } from 'lib/styled'

const Root = styled.div(apply('flex-1 flex flex-column'), {
  gap: '.5em',
})

const styles = (selectStyles = {}) => ({
  control: (_, { isDisabled }) =>
    apply('flex flex-row rounded-lg', {
      ...selectStyles.control,
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      pointerEvents: 'initial',
      border: '1px solid #CCCCCC',
    }),

  menu: (provided) => ({
    ...provided,
    ...apply('rounded-lg'),
  }),

  menuList: (provided) => ({
    ...provided,
    ...apply('py-0'),
  }),

  option: (provided, { isSelected }) => ({
    ...provided,
    ...apply('text-black rounded-lg'),
    backgroundColor: isSelected ? '#EEF1F5' : 'white',
    '&:hover': {
      backgroundColor: '#EEF1F5',
    },
  }),

  dropdownIndicator: () => apply('p-0 px-2 relative'),

  valueContainer: (provided) => ({
    ...provided,
    ...{
      padding: '.5rem 1rem',
      fontSize: '0.9375rem',
    },
  }),

  indicatorSeparator: () => apply('hidden'),

  indicatorsContainer: (provided, { isDisabled }) => {
    const styles = clone(provided)

    if (isDisabled) {
      styles.color = 'rgba(0, 0, 0, 0.38)'
    }

    return styles
  },

  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    ...apply(
      'text-black',
      isDisabled
        ? { color: 'rgba(0, 0, 0, 0.38)' }
        : { fontSize: '0.9375rem', marginLeft: '0', marginRight: '0' }
    ),
  }),

  input: (provided) => ({
    ...provided,
    margin: 0,
    paddingTop: 0,
    paddingBottom: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    ...apply('text-grey'),
  }),
})

// eslint-disable-next-line complexity
function CurrencySelectField({
  id,
  name,
  label,
  type = 'select',
  selected,
  onChange = noop,
  options,
  isDisabled,
  containerProps,
  selectStyles,
  testId,
  ...props
}) {
  return (
    <Root {...containerProps} isDisabled={isDisabled}>
      <Select
        value={options.find(({ value }) => value === selected)}
        type={type}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        styles={styles(selectStyles)}
        instanceId={testId}
        isSearchable={false}
        {...props}
      />
    </Root>
  )
}

export { CurrencySelectField }

import { map, prop, path, pathEq, pathOr } from '@seedcloud/ramda-extra'
import { select } from '@seedcloud/stateless'

import { staffModule } from './module'

const { state$, effectState$ } = staffModule

const staffEntities$ = select(state$, prop('entities'))
const order$ = select(state$, prop('order'))
const inspectedEntity$ = select(state$, prop('inspectedEntity'))
const filterQuery$ = select(state$, prop('filterQuery'))
const filterRole$ = select(state$, prop('filterRole'))
const canResendInviteAt$ = select(state$, prop('canResendInviteAt'))

const staffList$ = select(order$, staffEntities$, (order, entities) =>
  map((id) => path([id], entities), order)
)

const inspectedStaff$ = select(inspectedEntity$, staffEntities$, (id, entities) =>
  prop(id, entities)
)

const fetchStaffStatus$ = select(effectState$, (effectState) =>
  pathOr('pending', ['fetchStaff', 'status'], effectState)
)

const isLoadingStaff$ = select(fetchStaffStatus$, (status) => status === 'pending')

const inspectStaffStatus$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    pathOr('pending', ['inspectStaff', inspectedEntity, 'status'], effectState)
)

const isInspectingStaff$ = select(inspectStaffStatus$, (status) => status === 'pending')

const isUploadingImage$ = select(
  effectState$,
  pathEq(['uploadImage', 'status'], 'pending')
)

const isCreatingStaff$ = select(
  effectState$,
  pathEq(['createStaff', 'status'], 'pending')
)

const isResendingInvite$ = select(
  inspectedEntity$,
  effectState$,
  (inspectedEntity, effectState) =>
    path(['resendInvite', inspectedEntity, 'status'], effectState) === 'pending'
)

export {
  inspectedEntity$,
  staffList$,
  isLoadingStaff$,
  filterQuery$,
  filterRole$,
  isCreatingStaff$,
  inspectedStaff$,
  isInspectingStaff$,
  isUploadingImage$,
  canResendInviteAt$,
  isResendingInvite$,
}

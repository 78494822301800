import CONSTANTS from '@seedcloud/constants'
import { noop, path, values } from '@seedcloud/ramda-extra'
import { connect } from '@seedcloud/stateless'
import FS from 'filesize'
import { useField } from 'formik'
import moment from 'moment'
import { useCallback } from 'react'

import { ErrorMessage } from 'components/common/ErrorMessage'
import { Spinner } from 'components/common/Spinner'
import { SendUploadReminder } from 'components/jobs/common/SendUploadReminder'
import { JOB_STATUSES } from 'constants/jobs'
import { styled, apply } from 'lib/styled'
import { sendUploadReminderStatus$, inspectedJob$ } from 'modules/job'
import { useFileUpload, createErrorMessage } from 'utils/hooks'

const Panel = styled.div(apply('relative flex flex-column'))

const TopBar = styled.div(apply('flex items-center justify-between mb-2'))

const Label = styled.label(
  apply('uppercase tracking-wide text-xs text-grey-dark flex-1')
)

const EntityText = styled.span(apply('font-semibold'))

const btnStyle = apply('text-xl mr-2 text-grey-darker text-red-light', {
  cursor: 'pointer',
  transition: 'opacity 150ms ease-in-out',
  '&:hover': {
    opacity: 0.4,
  },
})

const DeleteBtn = styled.i(btnStyle, ({ disabled }) => ({
  pointerEvents: disabled && 'none',
  color: disabled ? 'gray !important' : '',
}))

const DownloadBtn = styled.i(btnStyle, ({ disabled }) =>
  apply('ml-2', {
    pointerEvents: disabled && 'none',
    color: disabled ? 'gray !important' : '',
  })
)
const UploadBtn = styled.i(btnStyle, ({ disabled }) =>
  apply('ml-2', {
    pointerEvents: disabled && 'none',
    color: disabled ? 'gray !important' : '',
  })
)

const DataContainer = styled.div(
  apply('relative h-16 border-solid border-0 rounded-md bg-grey-100 py-2', {
    overflowY: 'auto',
  })
)

const Text = styled.span(apply('text-base text-blue-light py-1 px-4'))

const Item = styled.div(
  apply('flex items-center justify-between', {
    cursor: 'pointer',
    '&:hover': apply('bg-grey-lightest'),
  }),
  ({ selected }) =>
    selected &&
    apply('bg-white', {
      '&:hover': apply('bg-white'),
    })
)

const SpinnerContainer = styled.div(apply('mr-4'))

const PanelSpinnerContainer = styled.div(apply('absolute'), {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const Overlay = styled.div(
  apply('absolute bg-white', {
    opacity: 0.5,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  })
)

const variantMapper = {
  download: {
    icon: 'fas fa-cloud-download-alt',
    style: apply('text-teal'),
    allowUpload: false,
  },
  upload: {
    icon: 'fas fa-cloud-upload-alt',
    style: apply('text-blue-light'),
    allowUpload: true,
  },
}

const ConnectedFilePanel = connect(() => ({
  sendUploadReminderStatus: sendUploadReminderStatus$,
  inspectedJob: inspectedJob$,
}))(FilePanel)

// eslint-disable-next-line complexity
function FilePanel({
  entity = 'GHD',
  type = 'download',
  name,
  id,
  containerStyle,
  documentType,
  operatingFile,
  multiOperatingDocumentType,
  onDownload = noop,
  onMultiDownload = noop,
  onUpload = noop,
  onDelete = noop,
  disabled,
  onRejected = noop,
  acceptFileType = `${values(CONSTANTS.FILE_MIME_TYPES)}`,
  isLoading = false,
  itemPrefix,
  sendUploadReminderStatus,
  inspectedJob,
  canDelete = true,
  canUpload = true,
  canDownload = true,
  ...props
}) {
  const variant = variantMapper[type]

  const [{ value: documents }, { error }] = useField({
    name,
    id,
    ...props,
  })

  const { getRootProps, getInputProps } = useFileUpload({
    accept: acceptFileType,
    noDrag: true,
    noKeyboard: true,
    onDropRejected: useCallback((fileRejections) =>
      onRejected(name, createErrorMessage(fileRejections))
    ),
    disabled,
    onDropAccepted: useCallback((files) => onUpload(files, name)),
  })

  return (
    <Panel style={containerStyle}>
      <TopBar>
        <div style={apply('flex')}>
          <Label>
            Files uploaded by <EntityText>{entity}</EntityText>
          </Label>
          {entity === 'Pilot' && inspectedJob?.status === JOB_STATUSES.DELIVERED && (
            <SendUploadReminder
              style={apply('ml-1')}
              status={sendUploadReminderStatus}
              jobId={inspectedJob.id}
            />
          )}
        </div>

        <div>
          {variant.allowUpload && canUpload && (
            <UploadBtn
              className={variantMapper.upload.icon}
              style={variantMapper.upload.style}
              disabled={isLoading}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
            </UploadBtn>
          )}
          {canDownload && (
            <DownloadBtn
              className={variantMapper.download.icon}
              style={variantMapper.download.style}
              onClick={() => onMultiDownload(documents, documentType)}
            />
          )}
        </div>
      </TopBar>

      <DataContainer isLoading={isLoading}>
        {documents.map(({ id, fileName, fileSize = 0, uploadedBy, uploadedAt }) => (
          <Item key={id}>
            <Text>
              {itemPrefix} - {fileName}
            </Text>
            <Text>
              By{' '}
              {path(['userDetails', 'fullName'], uploadedBy) ||
                path(['fullName'], uploadedBy)}
            </Text>
            <Text>{moment(uploadedAt).format('DD/MM/YY hh:mm A')}</Text>
            <Text>{FS(fileSize)}</Text>

            {operatingFile === id ? (
              <SpinnerContainer>
                <Spinner size={20} thickness={5} />
              </SpinnerContainer>
            ) : (
              <div>
                {canDownload && (
                  <DownloadBtn
                    className={variantMapper.download.icon}
                    style={variantMapper.download.style}
                    onClick={() => onDownload({ id, fileName }, name)}
                  />
                )}
                {canDelete && (
                  <DeleteBtn
                    className="fas fa-trash-alt"
                    onClick={() => onDelete({ id, fileName })}
                  />
                )}
              </div>
            )}
          </Item>
        ))}
        <PanelSpinnerContainer hidden={multiOperatingDocumentType !== documentType}>
          <Spinner size={64} thickness={7} />
        </PanelSpinnerContainer>
        <Overlay hidden={multiOperatingDocumentType !== documentType} />
      </DataContainer>
      <ErrorMessage error={error} visible={true} style={apply('text-sm')} />
    </Panel>
  )
}

export { ConnectedFilePanel as FilePanel }

import { styled, apply } from 'lib/styled'

const HeadingWrapper = styled.div(apply('flex justify-between mb-8'), {
  '& > h1': {
    fontSize: '1.3125rem',
    fontWeight: 800,
    color: '#1F1F1F',
    lineHeight: '1.5625rem',
  },
})

const Heading = ({ children, extra }) => (
  <HeadingWrapper>
    <h1>{children}</h1>
    {extra}
  </HeadingWrapper>
)

export { Heading }
